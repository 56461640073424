<template>
  <div class="wg-card-model">
    <figure>
      <div class="wrp-card-model">
        <div class="wg-card-model-indicators">
          <span v-if="user.is_online" class="wrp-indicator-online">
            <WGIndicatorOnline />
          </span>
          <span v-if="user.is_webcam" class="wrp-indicator-camera">
            <WGIndicatorCamera />
          </span>
        </div>
        <router-link
          :to="profilePage.createProfileLink(user)"
          class="wg-card-model-wrp-img"
        >
          <img v-if="imgSrc" class="wg-card-model-img" :src="imgSrc" :alt="user.alt" loading="lazy" width="189"/>
        </router-link>
      </div>
      <figcaption>
        <div class="wg-card-model-info">
          <p class="wg-card-model-name">{{ user.user_name }}</p>
          <p class="wg-card-model-years"><span>{{ user.user_age }} years</span> | <span>ID: {{ user.user_id }}</span></p>
        </div>

        <div v-if="isShowAction" class="wg-card-model-actions">
          <WGCardActions/>
        </div>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import WGIndicatorOnline from '@/components/UI/WGIndicators/WGIndicatorOnline.vue'
import WGIndicatorCamera from '@/components/UI/WGIndicators/WGIndicatorCamera.vue'
import WGCardActions from '@/components/UI/WGCards/WGCardActions.vue'
import { apiConfig } from '@/config'
import { computed, inject, ref } from 'vue'

export default {
  name: 'WGCardModel',
  computed: {
    apiConfig () {
      return apiConfig
    }
  },
  components: {
    WGCardActions,
    WGIndicatorCamera,
    WGIndicatorOnline
  },
  props: {
    user: {
      type: [Object, Array],
      default: null
    },
    urlStorage: {
      type: String,
      default: null
    },
    isShowAction: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const imgSrc = ref(computed(() => {
      return props.urlStorage ? props.urlStorage + props.user.user_photo : null
    }))
    const { profilePage } = inject('$projectLinks')

    return {
      imgSrc,
      profilePage
    }
  }
}
</script>
