export const LIST_ICON_TYPE = [
  'wg-ffi-close',
  'wg-ffi-webcam-slash-fill',
  'wg-ffi-webcam-checked-fill',
  'wg-ffi-apple-fill',
  'wg-ffi-instagram',
  'wg-ffi-linkedin-fill',
  'wg-ffi-twitter-fill',
  'wg-ffi-vk-fill',
  'wg-ffi-facebook-fill',
  'wg-ffi-google-fill',
  'wg-ffi-trash',
  'wg-ffi-info-circle',
  'wg-ffi-arrow-right',
  'wg-ffi-arrow-left',
  'wg-ffi-arrow-down',
  'wg-ffi-arrow-top',
  'wg-ffi-close-square',
  'wg-ffi-gift',
  'wg-ffi-eye-slash',
  'wg-ffi-chart-fill',
  'wg-ffi-customer-care',
  'wg-ffi-diamond-ring',
  'wg-ffi-diamond-ring-fill',
  'wg-ffi-dollar-coins',
  'wg-ffi-eye',
  'wg-ffi-filter-remove',
  'wg-ffi-filter-search-fill',
  'wg-ffi-filter-tick-fill',
  'wg-ffi-gallery',
  'wg-ffi-gift-fill',
  'wg-ffi-girl',
  'wg-ffi-global-fill',
  'wg-ffi-handshake',
  'wg-ffi-heart',
  'wg-ffi-heart-fill',
  'wg-ffi-like-fill',
  'wg-ffi-location-fill',
  'wg-ffi-lock-fill',
  'wg-ffi-lovely-fill',
  'wg-ffi-messages-dotted-fill',
  'wg-ffi-messages',
  'wg-ffi-messages-fill',
  'wg-ffi-mobile-fill',
  'wg-ffi-notes-blank-fill',
  'wg-ffi-notes-fill',
  'wg-ffi-note-text-fill',
  'wg-ffi-profile-fill',
  'wg-ffi-question',
  'wg-ffi-sms-fill',
  'wg-ffi-star-fill',
  'wg-ffi-tech-support',
  'wg-ffi-ticket',
  'wg-ffi-ticket-fill',
  'wg-ffi-video-play-fill',
  'wg-ffi-webcam-fill'
]
