<template>
  <div class="wg-reset-password-by-email">
    <h4 class="wg-reset-password-by-email_title">
      {{ $t(title) }}
    </h4>
    <p class="wg-reset-password-by-email-subtitle">
      {{ $t(description) }}
    </p>
    <slot></slot>
    <WGBoxLines class="text-normal-small text-nepal-400">
        {{ $t('formAuth.resetPassword.rememberTitle') }}
    </WGBoxLines>
    <div class="wg-reset-password-by-email_footer">
      <img :src="authIcon" alt="">
      <span class="wg-reset-password-by-email_footer-text">
        {{ $t('formAuth.resetPassword.remember') }}
      </span>
      <WGLink @click="openLogIn" variant="primary">
        {{ $t('global.logIn') }}!
      </WGLink>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import WGBoxLines from '@/components/UI/WGBoxLines/WGBoxLines.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import authIcon from '@/assets/images/icons/auth-form-lock.svg'

export default {
  name: 'ResetPasswordByEmail',
  props: {
    title: String,
    description: String
  },
  components: {
    WGLink,
    WGBoxLines
  },
  setup () {
    const store = useStore()

    const userEMail = ref('')

    const openLogIn = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }

    return {
      userEMail,
      authIcon,
      openLogIn
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/victoria/components/modal/reset-password-by-email.scss';
</style>
