<template>
  <footer class="wg-footer">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'WGFooter'
}
</script>
