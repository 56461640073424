import { createStore } from 'vuex'
import initialize from '@/store/module/initialize'
import locale from '@/store/module/locale'
import girls from '@/store/module/girls'
import geo from '@/store/module/countries'
import auth from '@/store/module/auth'
import modals from '@/store/module/modals'
import pageText from '@/store/module/pageText'
import toast from '@/store/module/toast'
import profile from '@/store/module/profile'
export default createStore({
  modules: {
    initialize,
    girls,
    profile,
    locale,
    auth,
    modals,
    pageText,
    geo,
    toast
  }
})
