const linksForFAQ = [
  { id: 2, componentName: 'WGBuyCredits', title: 'I can not buy credits. What should I do?' },
  { id: 3, componentName: 'WGAuthTrouble', title: 'I can not log in to the website. What should I do?' },
  { id: 4, componentName: 'WGForgotPassword', title: 'I forgot my password. What should I do?' },
  { id: 5, componentName: 'WGChangePassword', title: 'How to change my password?' },
  { id: 6, componentName: 'WGSignOut', title: 'How to log out from my profile?' },
  { id: 7, componentName: 'WGGetGirlData', title: 'How to get the contact information of a lady?' },
  { id: 8, componentName: 'WGChatCharged', title: 'How chats are charged?' },
  { id: 9, componentName: 'WGMessagesHistory', title: 'How can I see my messages history?' },
  { id: 10, componentName: 'WGDeleteMessages', title: 'How can I delete my messages?' },
  { id: 11, componentName: 'WGDisableSoundNotification', title: 'How to disable sound notifications?' },
  { id: 12, componentName: 'WGInterestMatchingSystem', title: 'How does the interest matching system work?' }
]

export default linksForFAQ
