<template>
  <div class="profile-registration">
    <WGContainer class="profile-registration-container">
      <div class="profile-registration_wrapper">
        <div class="profile-registration_wrapper-seo">
          <WGLogo
            class="profile-registration_wrapper-seo_image"
            :fullLogoSrc="logo"
          />
          <h1 class="profile-registration_wrapper-seo_headline">
            Victoriyaclub Sign Up — Explore International Dating
            <br /> Scene Safely
          </h1>
          <div v-if="isShowMobileSignUp" class="profile-registration_wrapper-sign-up">
            <WGTitle class-name="profile-registration_wrapper-sign-up_title" title="Create your account" />
            <WGSignUpForm />
          </div>
          <h2 class="profile-registration_wrapper-seo_title">International Dating Services</h2>
          <p class="profile-registration_wrapper-seo_description">
            Nowadays, online international dating is a comfortable and exciting way to find a compatible partner without
            going out. According to the relevant
            <a
              class="profile-registration_wrapper-seo_description_link"
              target="_blank"
              href="https://www.statista.com/topics/7443/online-dating/#topicOverview"
            >
              statistics
            </a>,
            the number of potential online daters on dating sites grew to 323.9 million last year. And this number is
            predicted to grow in the following years, which means more and more people use the services of international dating agencies.
          </p>
          <br />
          <p class="profile-registration_wrapper-seo_description">
            What are the advantages of dedicated platforms and is it possible to find your soulmate online? Scroll down
            to get a deeper insight into the thrilling opportunities you will discover once you sign up for a reputable
            international dating site.
          </p>
        </div>
        <div v-if="!isShowMobileSignUp" class="profile-registration_wrapper-sign-up">
          <WGTitle class-name="profile-registration_wrapper-sign-up_title" title="Create your account" />
          <WGSignUpForm />
        </div>
      </div>
    </WGContainer>
    <div class="profile-registration_footer">
      <img :src="footerGirl" alt="girl with photos">
    </div>
    <div class="profile-registration-arc"></div>
    <div class="profile-registration-ellipse"></div>
  </div>
</template>

<script>
import WGContainer from '@/components/UI/WGContainers/WGContainer'
import { useRoute } from 'vue-router'
import WGLogo from '@/components/UI/WGLogos/WGLogo'
import WGSignUpForm from '@/components/auth/WGSignUpForm'
import WGTitle from '@/components/UI/WGTypography/WGTitle'
import { computed, onMounted } from 'vue'
import { useBreakpointStore } from '@/controllers/components/breakpointMixin'

export default {
  name: 'PageRegistrationForMan',
  components: {
    WGTitle,
    WGSignUpForm,
    WGLogo,
    WGContainer
  },
  setup () {
    const route = useRoute()
    const logo = require('@/assets/images/logo-full.svg')
    const footerGirl = require('@/assets/images/footer-girl.webp')
    const { currentBreakpoint, getCurrentBreakpoint } = useBreakpointStore()

    document.title = 'Registration on Victoriyaclub Dating Site'
    const newDescription = 'Create VictoriyaClub login and get instant access to profiles of mail order brides. Enjoy ✅ single online chat  ✅ cheap virtual dating  ✅24/7 support.'
    const metaDescriptionTag = document.querySelector('meta[name="description"]')

    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', newDescription)
    } else {
      const newMetaTag = document.createElement('meta')
      newMetaTag.setAttribute('name', 'description')
      newMetaTag.setAttribute('content', newDescription)
      document.head.appendChild(newMetaTag)
    }

    onMounted(() => {
      getCurrentBreakpoint()
    })

    const isShowMobileSignUp = computed(() => {
      return ['XS', 'XXS', 'SM'].includes(currentBreakpoint.value)
    })

    return {
      route,
      logo,
      isShowMobileSignUp,
      footerGirl
    }
  }
}
</script>

<style lang="scss">
</style>
