import { API } from './API'

export class PageText {
  Api;
  endpoint = '/page_text';
  servicesEndpoint = '/payment_settings/short'
  constructor () {
    this.Api = new API()
  }

  getModule (module) {
    return this.Api.getM(`${this.endpoint}?module=${module}`)
  }

  getFaqPages (unAuth = false) {
    let endpoint = ''
    if (!unAuth) endpoint = '/un_auth'
    return this.Api.getM(`${endpoint}/faq/all`)
  }

  getServices () {
    return this.Api.getM(this.servicesEndpoint)
  }

  getPage (id) {
    return this.Api.getM(`${this.endpoint}/${id}`)
  }
}
