<template>
  <div>
    <p>You can always turn off sound notifications if it disctracts you. There are two options to do this:</p>
    <br>
    <p>1. In the top right corner of the chat window set "Sound in chat" to OFF.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/SetsoundnotificationstoOFF_pc.png" alt="">
      <img src="@/assets/images/faq/SetsoundnotificationstoOFF_mobile.png" alt="">
    </div>
    <br>
    <p>2. Mute sound in the bottom right corner on the main page (please see screenshots).</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/Mute_Sound_notification_PC.jpeg" alt="">
      <img src="@/assets/images/faq/Mute_Sound_notification_mobile.jpeg" alt="">
    </div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss">

</style>
