<template>
  <nav class="wg-bread-crumbs" :class="{'wg-bread-crumbs--promotion': isActivePromotion && getPromotionByPage($route.name).length}">
    <ul class="wg-bread-crumbs-list">
      <li class="wg-bread-crumbs-item" v-for="(item, index) in breadCrumbsList" :key="index">
        <WGLink v-if="item.url" :linkHref="item.url" variant="main-secondary" class="fw-bold">
          {{ item.isNotI18n ? item.label : $t(item.label) }}
        </WGLink>
        <span v-else class="wg-bread-crumbs-item-this-page">
          {{ item.isNotI18n ? item.label : $t(item.label) }}
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import getPromotionByPage from '@/configurations/promotions-config/victoriyaMainPromotion.js'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'

export default {
  name: 'WGBreadCrumbs',
  components: {
    WGLink
  },
  props: {
    breadCrumbsList: {
      type: Array,
      required: true
    }
  },
  setup () {
    const store = useStore()

    const isActivePromotion = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.active
    })

    return {
      isActivePromotion,
      getPromotionByPage
    }
  }
}
</script>
