export const STORIES_LIST = [
  {
    imgSrc: require('@/assets/images/sliderRealStories/image-1.jpg'),
    iconSrc: require('@/assets/images/icons/hearts.svg'),
    alt: 'Sweet_Svetlana',
    user_name: 'Sweet_Svetlana',
    user_years: '25 years old',
    user_quote: "My name is Anna and I'm 26 years old. I will never forget our first meeting. When he arrived, it was like a dream. Dinner for two, champagne, live music for the two of us and dancing till the morning… It was so well organized, that I just have no words) I fell in love with him immediately and now I am going to visit my beloved."
  },
  {
    imgSrc: require('@/assets/images/sliderRealStories/image-2.jpg'),
    iconSrc: require('@/assets/images/icons/hearts.svg'),
    alt: 'Trembling',
    user_name: 'Trembling',
    user_years: '22 years old',
    user_quote: 'My first experience in dating with foreign guy was very unusual and very romantic. My first romantic dinner took place in front of web camera (my fiancé came up with this idea). It was so unforgettable and so ingeniously that made me give him my heart. I am so happy and have no words that could describe my feelings. Thank you for our love!'
  }
]

export const ADVANTAGES_LIST = [
  {
    id: 0,
    title: 'advantages.block1.mainTitle',
    description: 'advantages.block1.mainText',
    icon: require('@/assets/images/icons/advantages-woman.svg')
  },
  {
    id: 1,
    title: 'advantages.block2.mainTitle',
    description: 'advantages.block2.mainText',
    icon: require('@/assets/images/icons/advantages-fam.svg')
  },
  {
    id: 2,
    title: 'advantages.block3.mainTitle',
    description: 'advantages.block3.mainText',
    yearFound: 2008,
    icon: require('@/assets/images/icons/advantages-calendar.svg')
  },
  {
    id: 3,
    title: 'advantages.block4.mainTitle',
    description: 'advantages.block4.mainText',
    icon: require('@/assets/images/icons/advantages-network.svg')
  }
]

export const TESTIMONIALS_LIST = [
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide1.name',
    user_name: 'testimonials.slide1.name',
    user_years: 'testimonials.slide1.age',
    user_quote: 'testimonials.slide1.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide2.name',
    user_name: 'testimonials.slide2.name',
    user_years: 'testimonials.slide2.age',
    user_quote: 'testimonials.slide2.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide3.name',
    user_name: 'testimonials.slide3.name',
    user_years: 'testimonials.slide3.age',
    user_quote: 'testimonials.slide3.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide4.name',
    user_name: 'testimonials.slide4.name',
    user_years: 'testimonials.slide4.age',
    user_quote: 'testimonials.slide4.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide5.name',
    user_name: 'testimonials.slide5.name',
    user_years: 'testimonials.slide5.age',
    user_quote: 'testimonials.slide5.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide6.name',
    user_name: 'testimonials.slide6.name',
    user_years: 'testimonials.slide6.age',
    user_quote: 'testimonials.slide6.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide7.name',
    user_name: 'testimonials.slide7.name',
    user_years: 'testimonials.slide7.age',
    user_quote: 'testimonials.slide7.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide8.name',
    user_name: 'testimonials.slide8.name',
    user_years: 'testimonials.slide8.age',
    user_quote: 'testimonials.slide8.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide9.name',
    user_name: 'testimonials.slide9.name',
    user_years: 'testimonials.slide9.age',
    user_quote: 'testimonials.slide9.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/quote.svg'),
    alt: 'testimonials.slide10.name',
    user_name: 'testimonials.slide10.name',
    user_years: 'testimonials.slide10.age',
    user_quote: 'testimonials.slide10.quote'
  }
]

export const SUCCESS_STORIES_LIST = [
  {
    iconSrc: require('@/assets/images/icons/hearts-double-green.svg'),
    imgSrc: require('@/assets/images/sliderSuccessStories/image-2.webp'),
    imgCaptionSrc: require('@/assets/images/icons/my-love-story.svg'),
    alt: 'successStories.slide1.names',
    user_name: 'successStories.slide1.names',
    user_years: '',
    user_quote: 'successStories.slide1.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/hearts-double-green.svg'),
    imgSrc: require('@/assets/images/sliderSuccessStories/image-5.webp'),
    imgCaptionSrc: require('@/assets/images/icons/my-love-story.svg'),
    alt: 'successStories.slide2.names',
    user_name: 'successStories.slide2.names',
    user_years: '',
    user_quote: 'successStories.slide2.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/hearts-double-green.svg'),
    imgSrc: require('@/assets/images/sliderSuccessStories/image-4.webp'),
    imgCaptionSrc: require('@/assets/images/icons/my-love-story.svg'),
    alt: 'successStories.slide3.names',
    user_name: 'successStories.slide3.names',
    user_years: '',
    user_quote: 'successStories.slide3.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/hearts-double-green.svg'),
    imgSrc: require('@/assets/images/sliderSuccessStories/image-3.webp'),
    imgCaptionSrc: require('@/assets/images/icons/my-love-story.svg'),
    alt: 'successStories.slide4.names',
    user_name: 'successStories.slide4.names',
    user_years: '',
    user_quote: 'successStories.slide4.quote'
  },
  {
    iconSrc: require('@/assets/images/icons/hearts-double-green.svg'),
    imgSrc: require('@/assets/images/sliderSuccessStories/image-1.webp'),
    imgCaptionSrc: require('@/assets/images/icons/my-love-story.svg'),
    alt: 'successStories.slide5.names',
    user_name: 'successStories.slide5.names',
    user_years: '',
    user_quote: 'successStories.slide5.quote'
  }
]
