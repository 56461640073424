<template>
  <div class="wg-banner" :style="`background-image: url('${background}')`">
    <div class="wg-banner_content-start" :style="`background:${backgroundColor}`">
      <div class="wg-banner_content-start_headline">
        <h2 class="wg-banner_content-start_headline-item">
          {{ $t(shadowText) }}
        </h2>
      </div>
      <div v-if="$slots.contentStart" class="wg-banner_content-start_describe">
        <slot name="contentStart"></slot>
      </div>
      <div v-else class="wg-banner_content-start_describe">
        {{ $t(textContent) }}
      </div>
    </div>
    <div class="wg-banner_content-end" :style="`background:${backgroundColor}`">
    </div>
    <div class="wg-banner_content-start_headline-shadow">
      {{ $t(shadowText) }}
    </div>
    <slot name="contentEnd"></slot>
  </div>
</template>

<script>
export default {
  name: 'WGBanner',
  props: {
    shadowText: {
      required: true,
      type: String
    },
    textContent: {
      type: String,
      default: ''
    },
    backgroundColor: String,
    background: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/victoria/UI/baner-main-page.scss";
</style>
