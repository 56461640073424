<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

export default defineComponent({
  name: 'WGModalBG',
  components: { WGIcon },
  props: {
    isShowButtonClose: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const isOpenModal = ref(computed(() => store.getters.isOpenModal))
    const onClose = () => {
      emit('closeModal', store.getters.getCurrentModal)
      store.commit('removeModal')
    }

    return {
      isOpenModal,
      onClose
    }
  }
})
</script>

<template>
  <div
    v-if="isOpenModal"
    class="wg-modal-bg"
    @click="onClose"
  >
    <button
      v-if="isShowButtonClose"
      class="button-modal-close"
      @click="onClose"
    >
      <WGIcon icon="wg-ffi-close-square"/>
    </button>
  </div>
</template>
