<template>
  <div class="wg-video">
    <video :controls="isControls" :autoplay="autoplay">
      <source :src="src" :type="type">
    </video>
  </div>
</template>

<script>
export default {
  name: 'WGVideo',
  props: {
    isControls: Boolean,
    autoplay: Boolean,
    src: String,
    type: {
      type: String,
      default: 'video/mp4'
    }
  }
}
</script>

<style scoped>

</style>
