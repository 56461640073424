<template>
  <figure class="wg-card-blockquote" :class="className">

    <div
      class="wg-card-blockquote-img-box"
      :class="{'is-caption': $props.user.imgCaptionSrc}"
      v-if="$props.user.imgSrc"
    >
      <img :src="$props.user.imgSrc" :alt="user.alt" class="wg-card-blockquote-img" />
      <img :src="$props.user.imgCaptionSrc" :alt="user.alt" class="wg-card-blockquote-img-caption" v-if="$props.user.imgCaptionSrc" />
    </div>

    <figcaption class="wg-card-blockquote-quote-box">

      <div class="wg-card-blockquote-head-box">

        <div class="wg-card-blockquote-head" v-if="$props.user.user_name || $props.user.user_years">

          <p class="wg-card-blockquote-name">{{ $t($props.user.user_name) }}</p>

          <p class="wg-card-blockquote-years" v-if="$props.user.user_years">
            <span>{{ $t($props.user.user_years) }}</span>
          </p>

          <div class="wg-card-blockquote-icon" v-if="$props.user.iconSrc || $slots.slotIcon">
            <slot name="slotIcon">
              <img :src="$props.user.iconSrc" alt="icon">
            </slot>
          </div>

        </div>

        <div class="wg-card-blockquote-head-actions" v-if="$slots.slotHeadActions">
          <slot name="slotHeadActions"></slot>
        </div>

      </div>

      <blockquote class="wg-card-blockquote-text" v-if="$slots.slotQuote || $props.user.user_quote">
        <slot name="slotQuote">
          {{ $t($props.user.user_quote, { website: currentProjectLink }) }}
        </slot>
      </blockquote>

    </figcaption>

  </figure>
</template>

<script>
import { computed, inject } from 'vue'

export default {
  name: 'WGCardBlockquote',
  props: {
    user: {
      type: [Object, Array],
      default: null
    },
    isShowAction: {
      type: Boolean,
      default: false
    },
    isQuoteRevers: {
      type: Boolean,
      default: false
    },
    isLargeImg: {
      type: Boolean,
      default: false
    }
  },
  setup (props, slots) {
    const { currentProjectLink } = inject('$projectLinks')
    const className = computed(() => {
      return {
        'is-icon': props.user.iconSrc || slots.slotIcon,
        'is-not-images': !props.user.imgSrc,
        'is-large-img': props.isLargeImg,
        'is-reverse': props.isQuoteRevers
      }
    })
    return {
      className,
      currentProjectLink
    }
  }
}
</script>
