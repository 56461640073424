<template>
  <WGModal class="victoria-modal-auth">

    <aside class="victoria-modal-auth-aside">
      <WGCardPreview :card-image="thanksModal" class="wg-card-preview-1" />
      <WGCardPreview :card-image="thanksModal" class="wg-card-preview-2" />
      <WGCardPreview :card-image="thanksModal" class="wg-card-preview-3" />
    </aside>
    <div class="wg-thanks-modal">
      <h4 class="wg-thanks-modal_title">
        {{ $t('formAuth.thanks.title') }}
      </h4>
      <p class="wg-thanks-modal-subtitle">
        {{ $t('formAuth.thanks.description') }}
      </p>
      <WGButton @click="closeModal" variant="success">
        {{ $t('formAuth.thanks.close') }}
      </WGButton>
      <WGBoxLines class="text-normal-small text-nepal-400">
        {{ $t('formAuth.thanks.or') }}
      </WGBoxLines>
      <div class="wg-reset-password-by-email_footer">
        <img :src="authIcon" alt="">
        <WGLink @click="openLogIn" variant="primary">
          {{ $t('formAuth.thanks.login') }}!
        </WGLink>
      </div>
    </div>
  </WGModal>
</template>

<script>
import { useStore } from 'vuex'
import WGModal from '@/components/WGModal/WGModal.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGBoxLines from '@/components/UI/WGBoxLines/WGBoxLines.vue'
import WGCardPreview from '@/components/UI/WGAsideBanners/WGCardPreview.vue'
import thanksModal from '@/assets/images/cardPreview/thanksModal.webp'
import authIcon from '@/assets/images/icons/try-to-signin.svg'
export default {
  name: 'ThanksModal',
  components: {
    WGLink,
    WGBoxLines,
    WGButton,
    WGCardPreview,
    WGModal
  },
  setup () {
    const store = useStore()

    const closeModal = () => {
      store.commit('removeAllModal')
    }

    const openLogIn = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }

    return {
      thanksModal,
      authIcon,
      openLogIn,
      closeModal
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/victoria/components/modal/finish-changing.scss';
</style>
