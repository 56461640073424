
import WGContainers from '@/components/UI/WGContainers/WGContainer'

import '@/assets/styles/victoria/main.scss'
import './container.css'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGContainer',
  component: WGContainers,
  tags: ['autodocs'],
  argTypes: {
    expand: {
      control: {
        type: 'select'
      },
      options: [
        'none',
        'left',
        'right'
      ]
    }
  }
}

export const Docs = {
  args: {
    expand: 'none',
    default: 'Lorem Ipsum - це текст-"риба", що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною "рибою" аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. "Риба" не тільки успішно пережила пять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною.'
  }
}
