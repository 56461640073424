<template>
  <span class="wg-indicator-online" :class="{'wg-indicator-online--offline': isOffline}">
    {{ title }}
  </span>
</template>

<script>
export default {
  name: 'WGIndicatorOnline',
  props: {
    title: {
      type: String,
      default: 'Online'
    },
    isOffline: {
      type: Boolean,
      default: false
    }
  }
}
</script>
