import { GirlsList } from '@/core/api/api-GirlsList'
import { ref } from 'vue'

export default {
  name: 'girls-list',
  props: {
    mode: {
      type: String,
      default: 'all'
    },
    settings: {},
    params: {},
    limit: {
      type: Number,
      default: 3
    }
  },
  setup (props) {
    const api = new GirlsList()
    const girlsList = ref([])
    const options = ref({})

    const getList = async () => {
      const formData = [props.mode]
      if (props.mode === 'all') {
        for (const param in props.params) {
          formData.push(param + '=' + props.params[param])
        }
        formData.push('limit=' + props.limit)
      }
      const data = formData.join('&')

      await api.getGirlsList(data, true).then(resp => {
        girlsList.value = resp.list
        options.value = resp.options
      })
    }

    getList()
    return {
      girlsList,
      options,
      getList
    }
  }

}
