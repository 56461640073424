import { OurBlog } from '@/core/api/api-OurBlog'

export default {
  state: {
    ourBlog: null
  },
  mutations: {
    setListOurBlog (state, data) {
      state.ourBlog = data
    }
  },
  actions: {
    apiGetOurBlog ({ commit }) {
      const api = new OurBlog()
      return api.getOurBlog(true).then(response => {
        commit('setListOurBlog', response)
      })
    }
  }
}
