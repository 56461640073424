const victoriaMainLinks = {
  mainMenuLinks: (isMini) => ([
    {
      linkHref: '/search',
      icon: isMini ? '' : 'wg-ffi-girl',
      title: 'mainMenu.items.singleGirls.title',
      descriptionAccent: 'mainMenu.items.singleGirls.description',
      description: 'empty'
    },
    {
      linkHref: '/our-dating-site/our-services/',
      icon: isMini ? '' : 'wg-ffi-dollar-coins',
      title: 'mainMenu.items.services.title',
      description: isMini ? 'empty' : 'mainMenu.items.services.description'
    },
    {
      linkHref: '/our-dating-site/assistance',
      icon: isMini ? '' : 'wg-ffi-handshake',
      title: 'mainMenu.items.assistance.title',
      description: isMini ? 'empty' : 'mainMenu.items.assistance.description'
    },
    {
      linkHref: '/about-us/contact-us/',
      icon: isMini ? '' : 'wg-ffi-customer-care',
      title: 'mainMenu.items.tickets.title',
      description: isMini ? 'empty' : 'mainMenu.items.tickets.description'
    }
  ]),
  mainMenuMobileLinks: [
    {
      linkHref: '',
      title: 'mainMenu.items.joinNow.title',
      descriptionAccent: 'mainMenu.items.joinNow.description',
      description: 'empty',
      icon: '',
      onClick: (store, emit) => {
        store.commit('addModal', 'VictoriaModalAuth')
        emit('close')
      }
    },
    {
      linkHref: '/search',
      title: 'mainMenu.items.findGirls.title',
      descriptionAccent: 'mainMenu.items.singleGirls.description',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/our-dating-site/our-services/',
      icon: '',
      title: 'mainMenu.items.services.title',
      description: 'empty'
    },
    {
      linkHref: '/our-dating-site/assistance',
      title: 'mainMenu.items.assistance.title',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/about-us/contact-us/',
      title: 'mainMenu.items.contacts.title',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/faq',
      title: 'mainMenu.items.faq.title',
      description: 'empty',
      icon: ''
    }
  ],
  searchLink: '/search/online/1',
  currentProjectLink: 'VictoriyaClub.com',
  footerLinksStartDating: [
    {
      linkHref: '/search/countries/slavic-brides',
      linkTitle: 'footer.footerMenu.menuList3.listItem1'
    },
    {
      linkHref: '/search/countries/european-brides',
      linkTitle: 'footer.footerMenu.menuList3.listItem2'
    },
    {
      linkHref: '/search/countries/latina-brides',
      linkTitle: 'footer.footerMenu.menuList3.listItem3'
    },
    {
      linkHref: '/search/countries/asian-brides',
      linkTitle: 'footer.footerMenu.menuList3.listItem4'
    },
    {
      // linkHref: '/search/age_min/30/age_max/39',
      linkHref: '/search/dating-over-30',
      linkTitle: 'footer.footerMenu.menuList3.listItem5'
    },
    {
      // linkHref: '/search/age_min/40/age_max/49',
      linkHref: '/search/dating-over-40',
      linkTitle: 'footer.footerMenu.menuList3.listItem6'
    },
    {
      // linkHref: '/search/age_min/50/age_max/59',
      linkHref: '/search/dating-over-50',
      linkTitle: 'footer.footerMenu.menuList3.listItem7'
    },
    {
      // linkHref: '/search/age_min/60/age_max/69',
      linkHref: '/search/senior-dating',
      linkTitle: 'footer.footerMenu.menuList3.listItem9'
    }
  ],
  footerPayLogos: [
    {
      titleLogo: 'visa',
      linkLogo: require('@/assets/images/payLogos/visa.svg')
    },
    {
      titleLogo: 'mastercard',
      linkLogo: require('@/assets/images/payLogos/mastercard.svg')
    },
    {
      titleLogo: 'americanexpress',
      linkLogo: require('@/assets/images/payLogos/americanexpress.svg')
    },
    {
      titleLogo: 'maestro',
      linkLogo: require('@/assets/images/payLogos/maestro.svg')
    },
    {
      titleLogo: 'bitcoin',
      linkLogo: require('@/assets/images/payLogos/bitcoin.svg')
    },
    {
      titleLogo: 'dinnerclub',
      linkLogo: require('@/assets/images/payLogos/dinnerclub.svg')
    }
  ],
  footerLinksAboutUs: [
    {
      linkHref: '/about-us/who-we-are/',
      linkTitle: 'footer.footerMenu.menuList2.listItem1'
    },
    {
      linkHref: '/about-us/anti-scam-policy/',
      linkTitle: 'footer.footerMenu.menuList2.listItem2'
    },
    {
      linkHref: '/about-us/recognize-a-scammer/',
      linkTitle: 'footer.footerMenu.menuList2.listItem3'
    },
    {
      linkHref: '/about-us/contact-us/',
      linkTitle: 'footer.footerMenu.menuList2.listItem4'
    },
    {
      linkHref: '/about-online-dating/',
      linkTitle: 'footer.footerMenu.menuList2.listItem5'
    }
  ],
  footerLinksOurDatingSite: [
    {
      linkHref: '/our-dating-site/license-agreement',
      linkTitle: 'footer.footerMenu.menuList1.listItem1'
    },
    {
      linkHref: '/our-dating-site/cancellation-policy',
      linkTitle: 'footer.footerMenu.menuList1.listItem2'
    },
    {
      linkHref: '/our-dating-site/terms-and-conditions',
      linkTitle: 'footer.footerMenu.menuList1.listItem3'
    },
    {
      linkHref: '/our-dating-site/acceptable-use-policy/',
      linkTitle: 'footer.footerMenu.menuList1.listItem9'
    },
    {
      linkHref: '/our-dating-site/our-services/',
      linkTitle: 'footer.footerMenu.menuList1.listItem4'
    },
    {
      linkHref: '/our-dating-site/assistance',
      linkTitle: 'footer.footerMenu.menuList1.listItem5'
    },
    {
      linkHref: '/faq/',
      linkTitle: 'footer.footerMenu.menuList1.listItem6'
    },
    {
      linkHref: '/our-dating-site/privacy-policy-new/',
      linkTitle: 'footer.footerMenu.menuList1.listItem7'
    },
    {
      linkHref: '/our-dating-site/cookie-policy/',
      linkTitle: 'footer.footerMenu.menuList1.listItem8'
    },
    {
      linkHref: '/our-dating-site/complaint-and-content-removal-policy--procedures/',
      linkTitle: 'footer.footerMenu.menuList1.listItem10'
    },
    {
      linkHref: '/our-dating-site/consumer-age-verification-policy/',
      linkTitle: 'footer.footerMenu.menuList1.listItem11'
    },
    {
      linkHref: '/our-dating-site/content-management-policy--procedures/',
      linkTitle: 'footer.footerMenu.menuList1.listItem12'
    }
  ],
  profilePage: {
    createProfileLink (user) {
      if (user.country_name !== 'Ukraine') {
        const nationality = user.nationality ?? user.country_name
        return `/all-brides/${nationality.toLowerCase()}-brides/meet-bride-online-${user.user_name.toLowerCase()}-id-${user.user_id}`
      } else {
        return user.url
      }
    },
    breadCrumbs (profile, id) {
      if (profile.value && profile.value.active) {
        return [
          { label: 'Home', url: '/' },
          { label: 'All brides', url: '/search' },
          { label: `${profile.value.nationality ?? profile.value.country.title_en} brides` },
          { label: `${profile.value.name}_${id}` }
        ]
      }

      return [
        { label: 'Home', url: '/' },
        { label: 'All brides', url: '/search' },
        { label: `${profile.value.name}_${id}` }
      ]
    }
  }
}

export default victoriaMainLinks
