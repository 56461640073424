import { ApiProfile } from '@/core/api/api-Profile'

export default {
  state: {
    profile: {}
  },
  getters: {
    getProfileFields (state) {
      return state.profile
    }
  },
  mutations: {
    setProfileData (state, data) {
      state.profile = data
    }
  },
  actions: {
    getGirlInfo ({ commit, getters, dispatch }, id) {
      const api = new ApiProfile()
      return api.getProfileData(true, id).then(response => {
        commit('setProfileData', response)
      })
    }
  }
}
