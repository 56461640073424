<template>
  <div class="wg-title-description-section">
    <p class="wg-title-description-section_title">
      {{ $t(title) }}
    </p>
    <div class="wg-title-description-section_container">
      <div
        class="wg-title-description-section_description"
        v-html="breakLines(isFullTextVisible ? description : description.substring(0, stringCutter))"
      />
      <span v-show="description.length > stringCutter" class="wg-title-description-section_visible-button" @click="isFullTextVisible = !isFullTextVisible">
        {{ $t(textForShowingButton) }}...
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WGTitleDescriptionSection',
  props: {
    title: String,
    description: String
  },
  data () {
    return {
      isFullTextVisible: false,
      stringCutter: 700
    }
  },
  computed: {
    textForShowingButton () {
      return !this.isFullTextVisible ? 'global.showMore' : 'global.showLess'
    }
  },
  methods: {
    breakLines (text) {
      return text.replace(/\n/g, '<br/><br/>')
    }
  }
}
</script>

<style scoped>

</style>
