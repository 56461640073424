<template>
  <div class="wg-preloader">
    <slot>
      <img src="@/assets/images/icons/logo-animate.svg" alt="logo">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'WGPreloader'
}
</script>
