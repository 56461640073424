<template>
  <div class="wg-description-fields">
    <WGList
      :list="elements"
    >
      <template #item="{item, index}">
        <div
          class="wg-description-fields_list-item"
          :class="{'wg-description-fields_list-item--full': index % 2}"
        >
          <div>
            <slot name="key" :item="item"></slot>
          </div>
          <div class="wg-description-fields_list-item_value">
            <slot name="item" :item="item"></slot>
          </div>
        </div>
      </template>
    </WGList>
  </div>
</template>

<script>
import WGList from '@/components/UI/WGLists/WGList'
export default {
  name: 'WGDescriptionFields',
  components: { WGList },
  props: {
    elements: Array
  }
}
</script>

<style scoped>

</style>
