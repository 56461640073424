import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import '@/assets/styles/common/abstracts/initialize.scss'

// TODO: create function js-to-scss D.D.

let componentName = null
let styles = null
let routerPath = null
let storePath = null
let enLocaleMessages = null
let ruLocaleMessages = null
let projectLinks = null

switch (process.env.VUE_APP_VERSION) {
  case 'victoria':
    componentName = 'AppVictoria.vue'
    styles = 'assets/styles/victoria/main.scss'
    storePath = 'store/victoria.js'
    routerPath = 'router/victoria.js'
    projectLinks = 'configurations/router-links-config/victoriaLinks.js'
    enLocaleMessages = require('@/locales/victoriaEn.json')
    ruLocaleMessages = require('@/locales/victoriaRu.json')
    break
  case 'loveinchat':
    componentName = 'AppLoveInChat.vue'
    styles = 'assets/styles/loveinchat/main.scss'
    storePath = 'store/loveinchat.js'
    routerPath = 'router/love-in-chat.js'
    projectLinks = 'configurations/router-links-config/loveinchat.js.js'
    enLocaleMessages = require('@/locales/loveinchatEn.json')
    ruLocaleMessages = require('@/locales/loveinchatRu.json')
    break
  case 'victoriamain':
    componentName = 'AppVictoriaMain.vue'
    styles = 'assets/styles/victoria/main.scss'
    storePath = 'store/victoriamain.js'
    routerPath = 'router/victoriamain.js'
    projectLinks = 'configurations/router-links-config/victoriaMainLinks.js'
    enLocaleMessages = require('@/locales/victoriaEn.json')
    ruLocaleMessages = require('@/locales/victoriaRu.json')
    break
}

require(`@/${styles}`)
const store = require(`@/${storePath}`).default
const router = require(`@/${routerPath}`).default
const links = require(`@/${projectLinks}`).default

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem('nwLocale') || 'en',
  fallbackLocale: localStorage.getItem('nwLocale') || 'en',
  messages: { en: enLocaleMessages, ru: ruLocaleMessages }
})

const App = require(`./${componentName}`).default

createApp(App)
  .provide('$projectLinks', links)
  .use(i18n)
  .use(store)
  .use(router)
  .mount('#app')
