export default {
  state: {
    modalList: [],
    existingModals: {
      MessageNChat: 'MessageNChat',
      VictoriaModalAuth: 'VictoriaModalAuth',
      TermsUserAgreement: 'TermsUserAgreement',
      TermsAndConditions: 'TermsAndConditions',
      VictoriaModalResetPassword: 'VictoriaModalResetPassword',
      VictoriaModalChangePassword: 'VictoriaModalChangePassword',
      VictoriaModalResetFinished: 'VictoriaModalResetFinished',
      PrivacyPolicy: 'PrivacyPolicy'
    }
  },
  getters: {
    isOpenModal (state) {
      return !!state.modalList.length
    },
    getModalList (state) {
      return state.modalList
    },
    getCurrentModal (state) {
      if (state.modalList.length) {
        return state.modalList[state.modalList.length - 1]
      }

      return null
    }
  },
  mutations: {
    addModal (state, modalName) {
      if (state.existingModals[modalName]) {
        state.modalList.push(modalName)
      }
    },
    removeModal (state) {
      if (state.modalList.length) {
        state.modalList.pop()
      }
    },
    removeAllModal (state) {
      state.modalList = []
    }
  }
}
