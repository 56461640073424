<template>
  <button
    type="button"
    class="wg-button-burger"
    :class="{ active: $props.isActive }"
  >

    <span class="wg-button-burger-lines">
      <span class="wg-button-burger-lines-inner">
        <span class="wg-button-burger-line"></span>
        <span class="wg-button-burger-line"></span>
        <span class="wg-button-burger-line"></span>
      </span>
    </span>

    <span v-if="!$props.isMini">
      <slot></slot>
    </span>

  </button>
</template>

<script>

export default {
  name: 'WGButtonBurger',
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
