
import WGGrid from '@/components/UI/WGGrids/WGGrid.vue'
import '@/assets/styles/victoria/main.scss'
import './grid.css'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGGrid',
  component: WGGrid,
  tags: ['autodocs'],
  argTypes: {
    columns: {
      control: 'select',
      options: [1, 2, 3, 4, 5, 6]
    },
    gap: {
      control: 'select',
      options: [16, 24]
    }
  }
}

export const Docs = {
  args: {
    dataList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    columns: 3,
    gap: 24
  }
}

export const SlotDefault = {
  args: {
    columns: 1,
    gap: 24,
    default: 'lorem Ips but not soapy but not soapy and not soapy and not soapy and not soapy and not soapy and not soapy and not soapy and not soapy'
  }
}
