<template>
  <WGContainer>
    <h1>AppLoveInChat</h1>
  </WGContainer>
</template>

<script>

import WGContainer from '@/components/UI/WGContainers/WGContainer'

export default {
  name: 'AppLoveInChat',
  components: {
    WGContainer
  }
}
</script>
