<template>

  <div class="wg-card-preview">

    <figure>

      <WGLogo :fullLogoSrc="fullLogoSrc"/>

      <img
        v-if="$props.cardImage"
        class="wg-card-preview-img"
        :src="$props.cardImage"
        alt="card-preview"
      />
      <img
        v-else
        class="wg-card-preview-img"
        src="@/assets/images/cardPreview/card-preview.png"
        alt="card-preview"
      />

      <figcaption class="wg-card-preview-text-wrp">

        <div class="wg-main-stroke-sup">the</div>

        <div class="wg-main-cursive-subtext">
          <img class="wg-love-story-hearts-icon" src="@/assets/images/cardPreview/hearts.svg" alt="" />
          Biggest
        </div>

        <p class="wg-main-text">dating platform</p>

        <div class="wg-main-arrow">
          <img src="@/assets/images/cardPreview/arrow.svg" alt="" />
        </div>

      </figcaption>

    </figure>

  </div>

</template>

<script>
import { ref } from 'vue'
import WGLogo from '@/components/UI/WGLogos/WGLogo.vue'

export default {
  name: 'WGCardPreview',
  components: {
    WGLogo
  },
  props: {
    cardImage: String
  },
  setup () {
    const fullLogoSrc = ref(require('@/assets/images/logo-white.svg'))

    return {
      fullLogoSrc
    }
  }
}
</script>
