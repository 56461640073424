import { API } from '@/core/api/API'

export class ApiProfile {
  Api;
  endpoint = 'user/profile/'

  constructor () {
    this.Api = new API()
  }

  getProfileData (unAuth = false, profileId) {
    let endpoint = ''
    if (unAuth) endpoint = '/un_auth'
    const url = `${endpoint}/${this.endpoint}${profileId}`
    return this.Api.getM(url)
  }
}
