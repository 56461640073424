import { GirlsList } from '@/core/api/api-GirlsList'

export default {
  state: {
    girls: {}
  },
  getters: {
    getGirlsList: (state) => (mode) => {
      return state.girls[mode] || null
    }
  },
  mutations: {
    setListToGirls (state, { mode, data }) {
      state.girls[mode] = data
    }
  },
  actions: {
    apiGetGirlsList ({ commit, getters, dispatch }, { mode = 'all', params = {}, perPage, searchParams, limit = 12, page = 1 }) {
      const api = new GirlsList()
      const formData = [mode]
      if (mode === 'all') {
        for (const param in params) {
          formData.push(param + '=' + params[param])
        }
        formData.push('limit=' + limit)
        formData.push('page=' + page)
        if (perPage) {
          formData.push('per_page=' + perPage)
        }
      }
      const data = formData.join('&')
      return api.getGirlsList(data, true, searchParams).then(resp => {
        commit('setListToGirls', { mode: mode, data: resp })
      })
    }
  }
}
