import { GeoList } from '@/core/api/api-Countries'

export default {
  state: {
    countries: [],
    cities: []
  },
  getters: {
    getCountriesList (state, rootState) {
      return state.countries.map(country => {
        return {
          id: country.country_id,
          label: `${country[`title_${rootState.getLocale}`]} (${country.girls_count})`,
          name: country.title_en
        }
      })
    },
    getCitiesList (state, rootState) {
      return state.cities.map(city => {
        return {
          id: city.city_id,
          country_id: city.country_id,
          label: `${city[`title_${rootState.getLocale}`]} (${city.girls_count})`,
          name: city.title_en
        }
      })
    }
  },
  mutations: {
    setCountries (state, countries) {
      state.countries = countries
    },
    setCities (state, cities) {
      const uniqueNewCities = cities.filter(city => !state.cities.some(existingCity => existingCity.city_id === city.city_id))
      state.cities.push(...uniqueNewCities)
      state.cities = state.cities.filter((city, index, self) => self.findIndex(c => c.city_id === city.city_id) === index)
    },
    clearCities (state) {
      state.cities = []
    }
  },
  actions: {
    getCountries ({ commit }) {
      const api = new GeoList()
      return api.apiGetCountriesList(true).then(resp => {
        commit('setCountries', resp)
      })
    },
    async getCitiesByCountryId ({ commit, getters, dispatch }, id) {
      const api = new GeoList()
      return await api.apiGetCitiesList(true, id).then(resp => {
        commit('setCities', resp)
      })
    }
  }
}
