<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageStub'
})
</script>

<template>
  <div>
    ЦЕ ПРОСТО СТОРІНКА ЗАГЛУШКА
  </div>
</template>

<style scoped>

</style>
