import { ref } from 'vue'

import '@/assets/styles/victoria/main.scss'

import WGRadio from '@/components/UI/WGForms/WGRadio'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGRadio',
  component: WGRadio,
  tags: ['autodocs']
}

const Template = (args) => ({
  // Components used in your story `template` are defined in the `components` object
  components: { WGRadio },
  // The story's `args` need to be mapped into the template through the `setup()` method
  setup () {
    const listData = ref([
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      }
    ])

    const selectedValues = ref('')

    return { args, listData, selectedValues }
  },
  // And then the `args` are bound to your component with `v-bind="args"`
  template: '<WGRadio v-bind="args" v-for="(item, index) in listData" :key="item" :labelFor="item.labelFor + index" :label="item.label + index" :value="item.value + index" :name="item.name" v-model="selectedValues"></WGRadio>{{ selectedValues }}'
})

export const RadioWithArray = Template.bind({})

export const Docs = {
  args: {
    label: 'label',
    labelFor: 'labelFor',
    name: 'name',
    value: 'value'
  }
}
