<template>
  <span class="wg-indicator-camera">
    <WGIcon icon="wg-ffi-webcam-fill" />
  </span>
</template>

<script>

import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

export default {
  name: 'WGIndicatorCamera',
  components: {
    WGIcon
  }
}
</script>
