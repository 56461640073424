<template>

  <div class="wg-login-form-wrap">

    <WGLabel>
      {{ $t('global.forms.labelEmail') }}
    </WGLabel>

    <WGFieldEmail v-model="userEMail" v-model:invalidText="invalidUserEmail" :placeholder="$t('global.forms.placeholderEmail')"/>

    <WGLabel>
      {{ $t('global.forms.labelPassword') }}
    </WGLabel>

    <WGField
      v-model="userPassword"
      type="password"
      name="pass"
      placeholder="****************"
      iconLeft="wg-ffi-lock-fill"
    />

    <WGButtons class="text-capitalize" full @click.prevent="login" :disabled="invalidData">{{ $t('global.logIn') }}</WGButtons>

    <WGCheckbox
      labelFor="checkboxSavePassword"
      v-model="isSavePassword"
    >
      <label class="wg-login-form_remember-me" for="checkboxSavePassword">
        {{ $t('formAuth.checkbox') }}
      </label>
      <WGLink
        variant="primary"
        @click="$store.commit('addModal', 'VictoriaModalResetPassword')"
        isFontSizeInherit
      >
        {{ $t('formAuth.checkboxCaption') }}
      </WGLink>
    </WGCheckbox>

  </div>

</template>

<script>
import AuthFormCtrl from '@/controllers/components/AuthFormCtrl'
export default AuthFormCtrl
</script>
