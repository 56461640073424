import infoPages from '@/router/info-pages'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/victoria.js'
import canonicalLinks from '@/controllers/SEO/canonicalLinks'

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: () => import(/* webpackChunkName: "PageHome" */'@/components/pages/PageHome.vue')
  },
  {
    path: '/registration-girl',
    name: 'PageRegistrationForWoman',
    component: () => import(/* webpackChunkName: "PageRegistrationForWoman" */'@/components/pages/PageRegistrationForWoman.vue')
  },
  {
    path: '/our-dating-site/our-services',
    name: 'PageService',
    component: () => import(/* webpackChunkName: "PageService" */'@/components/pages/PageService.vue')
  },
  {
    path: '/search/:pathMatch(.*)*',
    name: 'SearchGirls',
    component: () => import(/* webpackChunkName: "SearchGirls" */ '@/components/pages/SearchGirls.vue')
  },
  {
    path: '/:name-ID-:id-:age-years-old',
    name: 'PageProfile',
    component: () => import(/* webpackChunkName: "PageProfile" */ '@/components/pages/PageProfile.vue'),
    props: true
  }
  // {
  //   path: '/faq',
  //   name: 'PageFAQ',
  //   component: () => import(/* webpackChunkName: "PageFAQ" */ '@/components/pages/PageFAQ.vue'),
  //   props: true,
  //   children: [
  //     {
  //       path: '',
  //       name: 'WGSupportCenter',
  //       component: () => import('@/components/UI/WGFAQ/WGSupportCenter.vue')
  //     },
  //     ...linksForFAQ.map((link) => ({
  //       path: link.title.replace(/\s+/g, '-').replace(/[?,.]/g, '').toLowerCase(),
  //       name: link.componentName,
  //       component: () => import(`@/components/UI/WGFAQ/${link.componentName}.vue`),
  //       props: { link }
  //     }))
  //   ]
  // }
]

infoPages.forEach(page => {
  routes.push({
    path: `${page.path}`,
    name: `InformationPage${page.name}`,
    props: {
      pageId: page.pageId,
      seo: page.seo
    },
    component: () => import(/* webpackChunkName: "InformationPage" */ '@/components/pages/PageInformation.vue')
  })
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('removeAllModal')
  if (!to.name) {
    window.location.assign(to.fullPath)
    return
  }
  next()
})

router.afterEach(() => {
  canonicalLinks()
})

export default router
