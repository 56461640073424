
import WGField from '@/components/UI/WGForms/WGField'

import { LIST_ICON_TYPE } from './data/listIconType'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGField',
  component: WGField,
  tags: ['autodocs'],
  argTypes: {
    type: {
      control: {
        type: 'select'
      },
      options: ['text', 'email', 'phone', 'password', 'date', 'number']
    },
    autoComplete: {
      control: {
        type: 'select'
      },
      options: ['on', 'off']
    },
    ariaAutocomplete: {
      control: {
        type: 'select'
      },
      options: ['none', 'inline', 'list', 'both']
    },
    iconLeft: {
      control: {
        type: 'select'
      },
      options: LIST_ICON_TYPE
    },
    iconRight: {
      control: {
        type: 'select'
      },
      options: LIST_ICON_TYPE
    }
  }
}

export const Docs = {
  args: {
    placeholder: 'placeholder',
    invalidText: 'error text'
  }
}
