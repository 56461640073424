<template>
  <div class="wg-registration-for-woman-wrap">

    <div class="wg-registration-for-woman-row">

      <div class="wg-registration-for-woman-col">

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelName') }}
        </WGLabel>

        <WGFieldStandard v-model="userName" v-model:invalidText="invalidUserName" :placeholder="$t('global.forms.placeholderName')" iconLeft="wg-ffi-profile-fill"></WGFieldStandard>

        <WGLabel>
          {{ $t('global.forms.labelEmail') }}
        </WGLabel>

        <WGFieldEmail v-model="userEmail" v-model:invalidText="invalidUserEmail" :placeholder="$t('global.forms.placeholderEmail')"/>

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelPhone') }}
        </WGLabel>

        <WGFieldPhone v-model="userPhone" v-model:invalidText="invalidUserPhone" :placeholder="$t('global.forms.placeholderPhone')" iconLeft="wg-ffi-mobile-fill"></WGFieldPhone>

      </div>

      <div class="wg-registration-for-woman-col">

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelCountry') }}
        </WGLabel>

        <WGFieldCountry v-model="userCountry" v-model:invalidText="invalidUserCountry" :placeholder="$t('global.forms.placeholderCountry')" iconLeft="wg-ffi-global-fill"></WGFieldCountry>

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelCity') }}
        </WGLabel>

        <WGFieldCity v-model="userCity" v-model:invalidText="invalidUserCity" :placeholder="$t('global.forms.placeholderCity')" iconLeft="wg-ffi-location-fill"></WGFieldCity>

      </div>

    </div>

    <div class="wg-registration-for-woman-row">

      <div class="wg-registration-for-woman-col">

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelDateBirth') }}
        </WGLabel>

        <WGDatePicker :maxDate="maxDateBirth" :placeholder="$t('global.forms.placeholderDateBirth')" v-model="date"/>

      </div>

      <div class="wg-registration-for-woman-col">

        <WGLabel class="text-capitalize">
          {{ $t('global.forms.labelKnowledgeEnglish') }}
        </WGLabel>

        <WGSelect
          v-model="userLevelLanguage"
          :placeholder="$t('global.forms.placeholderKnowledgeEnglish')"
          icon="wg-ffi-chart-fill"
          :options="[
            'Beginner',
            'Pre-intermediate',
            'Intermediate',
            'Upper-Intermediate',
            'Advanced',
            'Mastery'
          ]"></WGSelect>
      </div>
    </div>

    <div class="wg-registration-for-woman-row">

      <div class="wg-registration-for-woman-col">

        <WGLabel>Facebook</WGLabel>

        <WGField type="text" placeholder="Facebook" iconLeft="wg-ffi-facebook-fill"></WGField>

        <WGLabel>Google</WGLabel>

        <WGField type="text" placeholder="Google" iconLeft="wg-ffi-google-fill"></WGField>

        <WGLabel>Instagram</WGLabel>

        <WGField type="text" placeholder="Instagram" iconLeft="wg-ffi-instagram"></WGField>

      </div>

      <div class="wg-registration-for-woman-col">

        <WGLabel>Twitter</WGLabel>

        <WGField type="text" placeholder="Twitter" iconLeft="wg-ffi-twitter-fill"></WGField>

        <WGLabel>Linkedin</WGLabel>

        <WGField type="text" placeholder="Linkedin" iconLeft="wg-ffi-linkedin-fill"></WGField>

      </div>

    </div>

    <WGButtons
      full
      @click="clickRegisterGirl"
      :disabled="invalidData"
    >{{ $t('PageRegistrationWoman.mainButton') }}</WGButtons>

    <WGCheckbox v-model="userAgreeLicense" labelFor="checkboxAcceptLicense">
      {{ $t('global.agree') }}
      <WGLink
        type="button"
        variant="primary"
        isFontSizeInherit
        @click="openModal('TermsUserAgreement')"
      >
        {{ $t('global.licenseAgreement') }}
      </WGLink>
    </WGCheckbox>

  </div>
</template>
<script>

import WGLabel from '@/components/UI/WGForms/WGLabel.vue'
import WGField from '@/components/UI/WGForms/WGField.vue'
import WGSelect from '@/components/UI/WGSelect/WGSelect.vue'
import WGButtons from '@/components/UI/WGButtons/WGButton.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import WGCheckbox from '@/components/UI/WGForms/WGCheckbox.vue'
import WGDatePicker from '@/components/UI/WGDatePicker/WGDatePicker.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import WGFieldEmail from '@/components/UI/WGForms/WGFieldEmail.vue'
import WGFieldStandard from '@/components/UI/WGForms/WGFieldStandard.vue'
import WGFieldPhone from '@/components/UI/WGForms/WGFieldPhone.vue'
import WGFieldCountry from '@/components/UI/WGForms/WGFieldCountry.vue'
import WGFieldCity from '@/components/UI/WGForms/WGFieldCity.vue'

export default {
  name: 'WGRegistrationForWomanForm',
  components: {
    WGFieldCity,
    WGFieldCountry,
    WGFieldPhone,
    WGFieldStandard,
    WGFieldEmail,
    WGDatePicker,
    WGCheckbox,
    WGLink,
    WGButtons,
    WGSelect,
    WGField,
    WGLabel
  },
  setup () {
    const store = useStore()
    const userName = ref('')
    const userEmail = ref('')
    const userPhone = ref('')
    const userCountry = ref('')
    const userCity = ref('')
    const userLevelLanguage = ref('')
    const userAgreeLicense = ref(false)
    const date = ref('')
    const invalidUserName = ref('')
    const invalidUserEmail = ref('')
    const invalidUserPhone = ref('')
    const invalidUserCountry = ref('')
    const invalidUserCity = ref('')

    const currentDate = new Date()
    const maxDateBirth = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())

    const invalidData = ref(computed(() => !(userName.value.length >= 2 && userEmail.value && !invalidUserName.value && !invalidUserEmail.value && !invalidUserPhone.value && userPhone.value.length >= 2 && !invalidUserPhone.value && userCountry.value.length >= 2 && !invalidUserCountry.value && userCity.value.length >= 2 && !invalidUserCity.value && userLevelLanguage.value && userAgreeLicense.value && date.value)))
    const clickRegisterGirl = () => {
      store.dispatch('apiRegistrationGirl', {
        name: userName.value,
        email: userEmail.value
      })
    }
    const openModal = (modalName) => {
      store.commit('addModal', modalName)
      switch (modalName) {
        case 'TermsUserAgreement':
          store.dispatch('apiGetPageText', 'license-agreement')
          break
        case 'TermsAndConditions':
          store.dispatch('apiGetPageText', 'terms-and-conditions')
          break
        case 'PrivacyPolicy':
          store.dispatch('apiGetPageText', 'privacy-policy')
          break
      }
    }
    return {
      date,
      maxDateBirth,
      userName,
      userEmail,
      userPhone,
      userCountry,
      userCity,
      userLevelLanguage,
      userAgreeLicense,
      invalidUserName,
      invalidUserEmail,
      invalidUserPhone,
      invalidUserCountry,
      invalidUserCity,
      invalidData,
      clickRegisterGirl,
      openModal
    }
  }
}
</script>
