<template>
  <WGFooter>
    <WGFooterMenuLists :isAccordion="isBreakpoint"/>
    <WGFooterSecondaryContent/>
    <WFFooterCopyright/>
  </WGFooter>
</template>
<script>
import WGFooter from '@/components/UI/WGFooter/WGFooter'

// eslint-disable-next-line no-unused-vars
import { XS, SM, MD, LG } from '@/components/UI/breakpoints.js'
import WGFooterMenuLists from '@/components/Victoria/UI/WGFooter/WGFooterMenuLists.vue'
import WGFooterSecondaryContent from '@/components/Victoria/UI/WGFooter/WGFooterSecondaryContent.vue'
import WFFooterCopyright from '@/components/Victoria/UI/WGFooter/WGFooterCopyright.vue'

import { onMounted, ref } from 'vue'

export default {
  name: 'WGFooterVictoria',
  components: {
    WFFooterCopyright,
    WGFooterSecondaryContent,
    WGFooterMenuLists,
    WGFooter
  },
  setup () {
    const isBreakpoint = ref(false)

    const onChangeBreakpoints = () => {
      const breakpointXS = window.matchMedia('(max-width:' + XS + 'px)')

      isBreakpoint.value = breakpointXS.matches

      breakpointXS.onchange = (e) => {
        isBreakpoint.value = e.matches
      }
    }

    onMounted(() => {
      onChangeBreakpoints()
    })

    return {
      isBreakpoint
    }
  }
}
</script>
