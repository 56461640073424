<template>
  <span class="wg-tag text-uppercase" :class="className">
    <slot></slot>
  </span>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'WGTag',
  props: {
    variant: {
      type: String,
      required: false,
      default: 'success',
      validator: (prop) =>
        ['success', 'warning'].includes(
          prop
        )
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['wg-tag-' + props.variant]: props.variant
      }
    })
    return {
      className
    }
  }
}
</script>
