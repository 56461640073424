<template>
  <div>
    <p>
      Our chats timer works as follows:
      <br>
      1. If you initiate a conversation with a lady and send her a message, 1 credit is deducted for the 1st minute of the chat. If within this 1st minute the lady does not reply to your message in this chat, then the chat is automatically paused and no more credits are deducted.
      <br>
      2. If a chat’s started, there was a conversation, and later there was no activity during 3 minutes, the chat is automatically paused and no more credits are deducted for this chat. Currently there is no option to vary the inactivity timer clock to customer preference but we will consider this option for our future features development.
      <br>
      Also you always have an option to stop any chat you want at any moment just by pressing 'Finish Chat' button.
    </p>
    <div class="wg-finish-chat-faq_images">
      <img src="@/assets/images/faq/finished_chat.png" alt="finish chat">
      <img src="@/assets/images/faq/finished_chat(mobile).png" alt="finish chat mobile">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.wg-finish-chat-faq {
  &_images {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    img {
      max-height: 400px;
    }
  }
}
</style>
