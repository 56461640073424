<template>
  <div class="wg-cookie-alert" v-if="isShowCookieAlert">
    <div class="wg-cookie-alert_icon-wrapper">
      <img :src="cookieIcon" alt="cookie">
    </div>
    <div class="wg-cookie-alert_text-wrapper">
      <p class="wg-main-text" v-html="$t('cookiePolicyModal.title', { link: compareLink() })"></p>
    </div>
    <div class="wg-cookie-alert_button-wrapper">
      <WGButton
        size="md"
        outline
        @click="acceptCookie()"
      >
        {{ $t('cookiePolicyModal.rejectAll') }}
      </WGButton>
      <WGButton
        size="md"
        variant="success"
        @click="acceptCookie()"
      >
        {{ $t('cookiePolicyModal.acceptAll') }}
      </WGButton>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import cookieIcon from '@/assets/images/cookie.svg'
import { useI18n } from 'vue-i18n'
import WGButton from '@/components/UI/WGButtons/WGButton'

export default defineComponent({
  name: 'WGCookieAlert',
  components: { WGButton },
  setup () {
    const isShowCookieAlert = ref(true)
    const { t } = useI18n()
    onMounted(() => {
      const isExist = localStorage.getItem('isAcceptedCks')
      if (isExist) {
        acceptCookie()
      }
    })
    const compareLink = () => {
      return `<a class="wg-cookie-alert_text-wrapper-link" style="color: #007aff" href="/our-dating-site/cookie-policy/">${t('footer.footerMenu.menuList1.listItem8')}</a>`
    }

    const acceptCookie = () => {
      isShowCookieAlert.value = false
      localStorage.setItem('isAcceptedCks', 'true')
    }

    return {
      cookieIcon,
      isShowCookieAlert,
      acceptCookie,
      compareLink
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/UI/cookie-alert.scss';
</style>
