<template>
  <div class="wg-access-overlay-image" :style="`background-image: url(${photoBackground})`">
    <img class="wg-access-overlay-image_picture" :src="media" alt="access-denied">
    <div class="wg-access-overlay-image_wrapper">
      <p class="wg-access-overlay-image_title">{{ title }}</p>
      <h5 class="wg-access-overlay-image_description">{{ description }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WGAccessOverlayImage',
  props: {
    media: String,
    title: String,
    description: String,
    photoBackground: String
  }
}
</script>

<style scoped>

</style>
