<template>
  <div class="wg-change-password-faq">
    <p>This video guide shows how to change my password on VictoriyaClub:</p>
    <br />
    <iframe style="width: 100%" height="315" src="https://www.youtube.com/embed/_1ZG02lKsYQ" frameborder="0" allowfullscreen></iframe>
    <p>1. Click on your profile tab in the bottom bar menu.</p>
    <div class="wg-change-password-faq_images">
      <img src="@/assets/images/faq/change_my_password_step_1.png" alt="">
      <img src="@/assets/images/faq/change_my_password_step_1(mobile).png" alt="">
    </div>
    <p>2. Click on “Change password”.</p>
    <div class="wg-change-password-faq_images">
      <img src="@/assets/images/faq/change_my_password_step_2.png" alt="">
      <img src="@/assets/images/faq/change_my_password_step_2(mobile).png" alt="">
    </div>
    3. In the window appeared:
    <ul class="wg-change-password-faq_list">
      <li class="wg-change-password-faq_list-item-dot">
        Input your current password
      </li>
      <li class="wg-change-password-faq_list-item-dot">
        Input your new password
      </li>
      <li class="wg-change-password-faq_list-item-dot">
        Confirm your new password
      </li>
    </ul>
    <p>Hide/unhide your password symbols if necessary.</p>
    <p>Press  “Save” button.</p>
    <p><i>Note: Only Latin symbols are supported.</i></p>
    <div class="wg-change-password-faq_images">
      <img src="@/assets/images/faq/change_my_password_step_3.png" alt="">
      <img src="@/assets/images/faq/change_my_password_step_3(mobile).png" alt="">
    </div>
    <p>4. If everything was correct the following window will appear. Press “OK” button or just close the window.</p>
    <div class="wg-change-password-faq_images">
      <img src="@/assets/images/faq/change_my_password_step_4.png" alt="">
      <img src="@/assets/images/faq/change_my_password_step_4(mobile).png" alt="">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.wg-change-password-faq {
  &_images {
    display: flex;
    gap: 15px;
    img {
      max-height: 400px;
    }
  }
  &_list {
    padding-left: 35px;
    &-item {
      list-style: number;
      &-dot {
        list-style: disc;
      }
    }
  }
}
</style>
