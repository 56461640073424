import { API } from './API'

export class UserApi {
  Api;
  endpoint = '/user';

  constructor () {
    this.Api = new API()
  }

  getMedia () {
    const url = `${this.endpoint}/media/library`
    return this.Api.getM(url)
  }

  getShortProfile () {
    const url = `${this.endpoint}/profile/short`
    return this.Api.getM(url)
  }
}
