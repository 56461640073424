<template>
  <div class="wg-buy-credits">
    <div class="wg-buy-credits_header">
      <p><b>Important note:</b> If your payment was declined, do not try to excessively make a purchase (for example getting declined and trying again multiple times). This would reduce instances of being blocked for velocity reasons.</p>
    </div>
    <h4 class="title-4 wg-buy-credits_headline">I. If your payment was declined, first of all, please make the following steps:</h4>
    <ol class="wg-buy-credits_list">
      <li class="wg-buy-credits_list-item">Check your balance and make sure you have enough funds in your bank account.</li>
      <li class="wg-buy-credits_list-item">Check your Internet banking limit for your card or account (daily and monthly).</li>
      <li class="wg-buy-credits_list-item">Сontact your bank to ensure that payment can be processed through the payment provider you have chosen (SecurionPay, Segpay or Multicards).</li>
    </ol>
    <p>Also make sure that:</p>
    <ul class="wg-buy-credits_list">
      <li class="wg-buy-credits_list-item-dot">You have input your full first and last names (no initial letters)</li>
      <li class="wg-buy-credits_list-item-dot">Your first and last names are input starting from capital letters</li>
      <li class="wg-buy-credits_list-item-dot">There are no misprints in data provided</li>
      <li class="wg-buy-credits_list-item-dot">You have input your valid email address</li>
    </ul>
    <p>If everything is alright, try to make a payment again.</p>
    <p>II. In case it is declined again make the following steps:</p>
    <div class="wg-buy-credits_list">
      1. Contact the payment service provider you have used:
        <ul class="wg-buy-credits_list">
          <li class="wg-buy-credits_list-item-dot">
            <a href="https://cs.segpay.com/">SegPay Consumer Portal</a>
          </li>
          <li class="wg-buy-credits_list-item-dot">
            Find your order on <a href="https://www.multicards.com/en/findorder/index.html">Multicards</a> (if the order was made)
          </li>
          <li class="wg-buy-credits_list-item-dot">
            SecurionPay does not provide support to paying users. Please contact us <a href="mailto:support@VictoriyaClub.com">support@VictoriyaClub.com</a> in case of difficulties occured.
          </li>
        </ul>
      2. Try to use another payment provider (SecurionPay, SegPay/SegPay Direct Debit or Multicards).
      <br>
      3. Try to use another credit/debit card (for SecurionPay, SegPay or Multicards) or bank account (for SegPay Direct Debit).
      <br>
      4. Make a screenshot of the error occurred during payment and contact your bank to clarify a possible reason.
      <br>
      <br>
    </div>
      If the problem still left after the above steps, contact us <a href="mailto:support@VictoriyaClub.com">support@VictoriyaClub.com</a> providing the following information:
      <ol class="wg-buy-credits_list">
        <li class="wg-buy-credits_list-item">Your ID on VictoriyaClub (if you send a ticket by email).</li>
        <li class="wg-buy-credits_list-item">Payment system you have used (and information if you have tried another one).</li>
        <li class="wg-buy-credits_list-item">Date and time of the transaction.</li>
        <li class="wg-buy-credits_list-item">Screenshot of the error if possible.</li>
      </ol>
      <p>After receiving such information from your side we will check the data of the transaction available for our side and inform you about future actions.</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.wg-buy-credits {
  &_header {
    display: flex;
    align-items: center;
  }
  &_title {
    font-weight: 700;
    font-size: 16px;
  }
  &_list {
    padding-left: 35px;
    &-item {
      list-style: number;
      &-dot {
        list-style: disc;
      }
    }
  }
  &_headline {
    margin: 20px 0;
  }
}
</style>
