<template>
  <div>
    <p class="wg-interest-faq_text">We introduce improved functionality on the site that allows you to choose your goals. This functionality became an evolution of the previous functionality for selecting goals on the site. Now your goals will not only be displayed as icons in your profile:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests1.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">and on the user's card:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests2.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">It will also be used to calculate the coincidence of interests with girls as a percentage, which is indicated on the girl’s card:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests3.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">on the girl's avatar on her page:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests4.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">and on the contact badge and the girl's avatar on the chat page:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests5.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">
      When you enter the search page, the goals filter will be set by default
      to your chosen interests in order to find girls whose goals currently matches yours:
    </p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests6.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">
      When you first log in after we launch the new functionality, you will be asked to select your goals in a pop-up window.
      In this case, interests that correspond to the goals you previously selected on the site will be selected by default:
    </p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests7.png" alt="Interests">
    </div>
    <p class="wg-interest-faq_text">You can change your goals at any time on your profile tab:</p>
    <div class="wg-interest-faq_image">
      <img src="@/assets/images/faq/Interests8.png" alt="Interests">
    </div>
    <h4 class="title-4 wg-interest-faq_title">Warranty Disclaimer:</h4>
    <p class="wg-interest-faq_text"><i>
      Our service provides information on matching interests based on the data submitted.
      We do not offer direct recommendations or guarantees of success in forming pairs.
      The possibility of creating a pair with any percentage of interest match, whether 100% or 0%, solely depends on the users' decision.
      Our goal is to provide useful data for informed choices, but the final decision always remains with the user
    </i></p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>
