import { API } from './API'

export class DistribTemplates {
  Api;
  endpoint = '/user/distrib_templates';

  constructor () {
    this.Api = new API()
  }

  getAll (status) {
    let url = `${this.endpoint}`
    if (status) {
      url += `?type=${status}`
    }

    return this.Api.getM(url)
  }

  save (item) {
    if (item.id) {
      return this.Api.put(`${this.endpoint}/${item.id}`, item)
    }
    return this.Api.post(`${this.endpoint}`, item)
  }

  reopen (id) {
    return this.Api.getM(`${this.endpoint}/${id}/reopen`)
  }

  proceedAll (proceed) {
    return this.Api.post(`${this.endpoint}/proceed`, { proceed })
  }

  proceed (id, proceed) {
    return this.Api.post(`${this.endpoint}/${id}/proceed`, { proceed })
  }
}
