<template>
  <WGHeader :class="{ 'is-mini': isBreakpoint }">

    <template #default>
      <WGHeaderContent :promotionLogo="$props.logo" :isMini="isBreakpoint"/>
    </template>

  </WGHeader>
</template>

<script>

import { onMounted, ref } from 'vue'

import WGHeader from '@/components/UI/WGHeader/WGHeader'
import WGHeaderContent from '@/components/Victoria/UI/WGHeader/WGHeaderContent'

// eslint-disable-next-line no-unused-vars
import { XS, SM, MD, LG } from '@/components/UI/breakpoints.js'

export default {
  name: 'WGHeaderVictoria',
  components: {
    WGHeader,
    WGHeaderContent
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Object,
      default: null
    }
  },
  setup () {
    const isBreakpoint = ref(false)

    const onChangeBreakpoints = () => {
      const breakpointMD = window.matchMedia('(max-width:' + (MD + 1) + 'px)')

      isBreakpoint.value = breakpointMD.matches

      breakpointMD.onchange = (e) => {
        isBreakpoint.value = e.matches
      }
    }

    onMounted(() => {
      onChangeBreakpoints()
    })

    return {
      isBreakpoint
    }
  }
}
</script>
