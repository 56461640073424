
import WGSliderMain from '@/components/WGSliders/WGSliderMain'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGSliderMain',
  component: WGSliderMain,
  tags: ['autodocs']
}

export const Docs = {

}
