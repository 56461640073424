<template>
  <component v-bind:is="tagName" v-if="list && list.length" class="wg-list" :class="className">
    <component v-bind:is="childTagName"
      class="wg-list-item"
      v-for="(item, index) in list"
      :key="index"
    >
      <slot
        name="item"
        :item="item"
        :index="index"
      >
        {{ item }}
      </slot>
    </component>
  </component>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'WGList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    orientations: {
      type: String,
      required: false,
      default: 'vertical',
      validator: (prop) =>
        ['vertical', 'horizontal'].includes(prop)
    },
    variant: {
      type: String,
      required: false,
      default: 'none',
      validator: (prop) =>
        ['none', 'ordered-zero'].includes(prop)
    },
    tagName: {
      type: String,
      default: 'ul',
      validator: (prop) =>
        ['ul', 'div'].includes(prop)
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['is-' + props.orientations]: props.orientations,
        ['wg-list-' + props.variant]: props.variant !== 'none'
      }
    })
    const childTagName = computed(() => props.tagName === 'ul' ? 'li' : props.tagName)
    return {
      className,
      childTagName
    }
  }
}
</script>
