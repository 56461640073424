<template>
  <div class="wp-button-template-stop-war">
    {{ $t('footer.buttonCustom') }}
    <img
      class="wg-button-custom-img"
      src="@/assets/images/ukrainianFlag/heart.svg"
      alt="ukrainian-heart"
    >
  </div>
</template>

<script>

export default {
  name: 'WGButtonTemplateStopWar',
  components: {
  },
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>
