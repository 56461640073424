<template>
  <div class="wg-copyright">
    <p>
      <slot name="prependSlot"></slot>
      {{ $props.textPrepend }}
      {{ $props.dateFrom }}
      -
      {{ $props.fullYear }}
      {{ $props.textAppend }}
      <slot name="appendSlot"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'WGCopyright',
  props: {
    fullYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    dateFrom: {
      type: Number,
      default: null
    },
    textPrepend: {
      type: String,
      default: ''
    },
    textAppend: {
      type: String,
      default: ''
    }
  }
}
</script>
