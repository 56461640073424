
      var exported = {};

      import API from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../node_modules/css-loader/dist/cjs.js!./header.css";
      
      if (content && content.locals) {
              exported.locals = content.locals;
            }
            

var refs = 0;
var update;
var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

exported.use = function(insertOptions) {
  options.options = insertOptions || {};

  if (!(refs++)) {
    update = API(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



export * from "!!../../node_modules/css-loader/dist/cjs.js!./header.css";
       export default exported;
