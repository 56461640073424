<template>
  <div>
    <WGContainer is-clear-offsets>
      <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
    </WGContainer>
    <WGContainer class="bg-white wg-page-information-container" isClearOffsets>
      <br v-show="!['assistance', 'who-we-are'].includes(getPageText?.uri_en)" />
      <WGHtmlRenderer
        v-if="page"
        :title="title"
        :page="page"
        :isShowTitle="!['assistance', 'who-we-are'].includes(getPageText?.uri_en)"
      >
      </WGHtmlRenderer>
      <WGPreloader v-else/>
      <br />
    </WGContainer>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import WGPreloader from '@/components/UI/WGPreloader/WGPreloader.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs.vue'
import WGHtmlRenderer from '@/components/UI/WGHtmlRenderer/WGHtmlRenderer.vue'

export default {
  name: 'PageInformation',
  displayName: 'PageInformation',
  components: {
    WGPreloader,
    WGHtmlRenderer,
    WGBreadCrumbs,
    WGContainer
  },
  props: {
    pageId: Number,
    seo: {}
  },
  setup (props) {
    const { currentProjectLink } = inject('$projectLinks')
    let metaDescriptionTag = document.querySelector('meta[name="description"]')
    if (!metaDescriptionTag) {
      metaDescriptionTag = document.createElement('meta')
    }
    if (props.seo) {
      document.title = props.seo.title.replaceAll('VictoriyaClub.com', currentProjectLink)
      let metaDescriptionTag = document.querySelector('meta[name="description"]')
      if (!metaDescriptionTag) {
        metaDescriptionTag = document.createElement('meta')
        metaDescriptionTag.setAttribute('name', 'description')
        metaDescriptionTag.setAttribute('content', props.seo.md)
      } else {
        metaDescriptionTag.setAttribute('content', props.seo.md)
      }
    }
    const store = useStore()

    const getPageText = ref(null)
    const locale = ref({ locale: '' })

    const page = ref(computed(() => {
      return getPageText.value?.['text_' + locale.value.locale] || getPageText.value?.text_en || ''
    }))
    const title = ref(computed(() => {
      return getPageText.value?.['name_' + locale.value.locale] || getPageText.value?.name_en || ''
    }))
    const breadCrumbsList = ref(computed(() => [
      { label: 'breadCrumbs.home', url: '/' },
      { label: title.value, isNotI18n: true }
    ]))

    const handleRouteChange = async () => {
      await store.dispatch('apiGetPageTextById', props.pageId)
      locale.value = store.state.locale
      getPageText.value = store.getters.getPageText
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    watch(() => props.pageId, () => {
      handleRouteChange()
    })

    onMounted(() => {
      handleRouteChange()
    })

    return {
      breadCrumbsList,
      page,
      getPageText,
      title,
      locale
    }
  }
}
</script>

<style lang="scss">
.wg-page-information-container {
  padding: 0 5px;
}
a.wwa__btn {
  display: block;
  width: 141px;
  margin: 0 auto;
  font: bold 14px/1 'PT Sans', sans-serif;
  font: bold 16px/1 'PT Sans', sans-serif;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0px;
  color: white;
  padding: 8px 0 8px 0;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #2B5FB8;
  background: -webkit-gradient(linear, left bottom, left top, from(#1951B5), to(#3968BB));
  background: linear-gradient(360deg, #1951B5 0%, #3968BB 100%);
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  position: relative;
}

a.wwa__btn:hover, a.wwa__btn:focus {
  color: white !important;
  text-decoration: none !important;
  border: 1px solid #3868bf;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #3767be), color-stop(0, #4e83e4));
  background: linear-gradient(90deg, #3767be 0, #4e83e4);
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.21);
}

a.wwa__btn.wwa__btn-medium {
  width: 197px;
}

a.wwa__btn.wwa__btn_turquoise {
  border: 1px solid #4BAC9C;
  background: -webkit-gradient(linear, left top, left bottom, from(#5BC8A0), to(#4BAD9C));
  background: linear-gradient(180deg, #5BC8A0 0%, #4BAD9C 100%);
}

a.wwa__btn.wwa__btn_turquoise:hover {
  background: #00af9b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #00af9b), color-stop(0, #10cc9d));
  background: linear-gradient(180deg, #00af9b 0, #10cc9d);
}

.break-after::after {
  content: '\A';
  white-space: pre;
}

.wwa__title {
  font: bold 18px/1 'PT Sans', sans-serif;
  font: bold 20px/1 'PT Sans', sans-serif;
  text-align: center;
  color: #1E488D;
}

#wwa {
  display: block;
  width: calc(100% + 24px);
  margin-left: -12px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  margin-bottom: -20px;
  margin-top: -20px;
}

#wwafirst {
  width: 100%;
  padding: 73px 0 82px 0;
  background: url(@/assets/images/whoWeAre/first__bg.jpg) no-repeat center center/cover;
}

#wwafirst .wwafirst__title {
  font: bold 30px/1.05 'PT Sans', sans-serif;
  font: bold 35px/1.05 'PT Sans', sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: white;
  margin-bottom: 15px;
}

#wwafirst .wwafirst__text1 {
  font: bold 18px/1 'PT Sans', sans-serif;
  font: bold 20px/1 'PT Sans', sans-serif;
  text-align: center;
  color: white;
  margin-bottom: 28px;
}

#wwaeggs {
  padding: 21px 21px 47px;
}

#wwaeggs .wwaeggs__wrap::after,
#wwaeggs .wwaeggs__wrap2::after {
  /* content: '\A'; */
  /* white-space: pre;  */
}

#wwaeggs .wwaeggs__list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

#wwaeggs .wwaeggs__list li {
  display: block;
  width: 33%;
  min-height: 192px;
  padding: 35px 20px 32px 30px;
  background: #FFFFFF;
  border-radius: 3px;
  margin-bottom: calc(1% / 2);
  box-sizing: border-box;
}

#wwaeggs .wwaeggs__list li.wwaeggs__first {
  padding: 35px 30px 32px;
  background-color: #1E488D;
}

#wwaeggs .wwaeggs__list li.wwaeggs__first .wwaeggs__title {
  display: block;
  /* font: bold 18px/1 'PT Sans', sans-serif; */
  text-align: center;
  color: white;
  margin-bottom: 29px;
}

#wwaeggs .wwaeggs__list li.wwaeggs__first .wwaeggs__description {
  text-align: center;
  font: 16px/22px 'PT Sans', sans-serif;
  color: white;
}

#wwaeggs .wwaeggs__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: bold 16px/1 'PT Sans', sans-serif;
  font: bold 20px/1 'PT Sans', sans-serif;
  color: #1E488D;
  margin-bottom: 14px;
}

#wwaeggs .wwaeggs__title svg {
  margin-right: 20px;
}

#wwaeggs .wwaeggs__description {
  font: 13px/12px 'PT Sans', sans-serif;
  font: 16px/22px 'PT Sans', sans-serif;
  color: #8EA3C6;
}

#wwasteps {
  width: 100%;
  padding: 0 21px 58px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#wwasteps .wwasteps__title {
  padding: 86px 0 147px 0;
  font: bold 24px/23px 'PT Sans', sans-serif;
  font: bold 32px/30px 'PT Sans', sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: white;
  background: url(@/assets/images/whoWeAre/steps__bg.jpg) no-repeat center center/cover;
}

#wwasteps .wwasteps__list {
  width: 67%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: -102px;
}

#wwasteps .wwasteps__list li[data-step] {
  display: block;
  padding: 0;
  width: 184px;
  min-height: 174px;
  background: white;
  border-radius: 3px;
  list-style-type: none;
  position: relative;
}
#wwasteps .wwasteps__list li {
  display: none;
}

#wwasteps .wwasteps__list li::before {
  content: attr(data-step);
  display: block;
  position: absolute;
  top: 50px;
  left: -webkit-calc(50% - 13.5px);
  left: calc(50% - 13.5px);
  font: bold 48px/1 'PT Sans', sans-serif;
  color: #1E488D;
}

#wwasteps .wwasteps__list li span {
  display: block;
  padding-top: 130px;
  padding-bottom: 11px;
  font: 14px/1 'PT Sans', sans-serif;
  font: 16px/1 'PT Sans', sans-serif;
  text-align: center;
  color: #1E488D;
}

#wwasteps .wwasteps__list li svg:not(.wwasteps_arrow) {
  position: absolute;
  top: 10px;
  right: -13px;
}

#wwasteps .wwasteps__list svg.wwasteps_arrow {
  display: none;
  margin-top: 130px;
  position: relative;
  left: 2px;
}

#wwareviews {
  overflow: hidden;
  display: none;
}

#wwareviews .wwareviews__slider {
  width: 110%;
  margin-left: -5%;
  padding: 30px 0 32px;
  position: relative;
  z-index: 1;
}

#wwareviews .wwareviews__slider .owl-stage {
  padding-top: 20px;
}

#wwareviews .wwareviews__slider .owl-nav {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 40%;
  position: absolute;
  left: 30%;
  top: 70%;
}

#wwareviews .wwareviews__slider .owl-nav .owl-prev img {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#wwareviews .wwareviews__slider .owl-nav img:hover {
  opacity: .5;
}

#wwareviews .wwareviews__slider .owl-dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100px;
  display: none;
}

#wwareviews .wwareviews__slider .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background: #C4C4C4;
}

#wwareviews .wwareviews__slider .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

#wwareviews .wwareviews__slider .owl-dots .owl-dot.active {
  background: #1E488D;
}

#wwareviews .wwareviews__item {
  opacity: 0.5;
  position: relative;
}

#wwareviews .wwareviews__item::before {
  content: '';
  display: block;
  width: 100%;
  height: 30px;
}

#wwareviews .wwareviews__item::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#wwareviews .owl-item.center .wwareviews__item::after {
  display: none;
}

#wwareviews .wwareviews__item .wwareviews__video {
  min-height: 192px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 34px;
  position: relative;
  top: 2px;
}

#wwareviews .wwareviews__item .wwareviews__video svg {
  display: none;
}

#wwareviews .wwareviews__item .wwareviews__description {
  visibility: hidden;
  background: white;
  border-radius: 3px;
  padding: 21px 5px 23px;
}

#wwareviews .wwareviews__item .wwareviews__description h4 {
  font: bold 16px/15px 'PT Sans', sans-serif;
  font: bold 18px/17px 'PT Sans', sans-serif;
  text-align: center;
  color: #1E488D;
  margin-bottom: 9px;
}

#wwareviews .wwareviews__item .wwareviews__description p {
  font: 14px/13px 'PT Sans', sans-serif;
  font: 15px/14px 'PT Sans', sans-serif;
  text-align: center;
  color: #1E488D;
}

#wwareviews .owl-item.center {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
  position: relative;
  z-index: 22;
}

#wwareviews .owl-item.center .wwareviews__item {
  opacity: 1;
}

#wwareviews .owl-item.center .wwareviews__item::before {
  content: '';
  display: block;
  width: 100%;
  height: 0px;
}

#wwareviews .owl-item.center .wwareviews__video {
  min-height: 220px;
  padding: 0;
  top: 0;
  height: 100%;
  margin: 0;
  margin-bottom: 6px;
  -webkit-transition: all .25s;
  transition: all .25s;
}

#wwareviews .owl-item.center .wwareviews__video:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  -webkit-transition: all .25s;
  transition: all .25s;
  z-index: 1;
  transition: all .25s;
}

#wwareviews .owl-item.center .wwareviews__video:hover {
  -webkit-transition: all .25s;
  transition: all .25s;
  cursor: pointer;
}

#wwareviews .owl-item.center .wwareviews__video:hover:before {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(30, 72, 141, 0.57)), to(rgba(30, 72, 141, 0.57)));
  background: linear-gradient(0deg, rgba(30, 72, 141, 0.57), rgba(30, 72, 141, 0.57));
  -webkit-transition: all .25s;
  transition: all .25s;
}

#wwareviews .owl-item.center .wwareviews__video svg {
  display: block;
  position: relative;
  z-index: 2;
}

#wwareviews .owl-item.center .wwareviews__description {
  visibility: visible;
}

#wwareviews .owl-item.center .wwareviews__description h4 {
  font: bold calc(16px/1.08)/calc(15px/1.08) "PT Sans", sans-serif;
}

#wwareviews .owl-item.center .wwareviews__description p {
  font: calc(14px/1.08)/16px "PT Sans", sans-serif;
}

#wwacare {
  padding: 0 20px 20px;
}

#wwacare .wwa__title {
  margin-bottom: 28px;
}

#wwacare .wwa__title .break-after::after {
  display: none;
}

#wwacare .wwacare__tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

#wwacare .wwacare__img {
  width: 50%;
}

#wwacare .wwacate__right {
  width: 50%;
}

#wwacare .wwacate__right .wwacare__item {
  width: -webkit-calc(100% - 9px);
  width: calc(100% - 9px);
  padding: 29px 33px 26px;
  border-radius: 3px;
  background: white;
  margin-bottom: 3px;
  margin-left: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

#wwacare .wwacate__right .wwacare__item:nth-child(2) {
  padding: 28px 33px 20px;
}

#wwacare .wwacate__right .wwacare__item:nth-child(3) {
  padding: 23px 33px 20px;
  margin-bottom: 0;
}

#wwacare .wwacate__right .wwacare__subtitle {
  font: bold 16px/1 'PT Sans', sans-serif;
  font: bold 18px/1 'PT Sans', sans-serif;
  color: #1E488D;
  margin-bottom: 9px;
}

#wwacare .wwacate__right .wwacare__text1 {
  font: 14px/13px 'PT Sans', sans-serif;
  font: 16px/15px 'PT Sans', sans-serif;
  color: #1E488D;
}

#wwacare .wwacate__right svg {
  position: absolute;
  top: 17px;
  right: 18px;
}

#wwapoint {
  padding: 0 21px 60px;
}

#wwapoint .wwapoint__accent {
  width: 100%;
  padding: 26px 42px;
  background: #27AF9B;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font: bold 18px/17px 'PT Sans', sans-serif;
  text-align: center;
  color: #0A5E51;
  margin-bottom: 28px;
}

#wwapoint .wwapoint__accent.wwapoint__accent_white {
  background: white;
  /* font: bold 16px/15px 'PT Sans', sans-serif; */
  text-align: center;
  color: #1E488D;
  margin-bottom: 21px;
}

#wwapoint .wwa__title {
  text-align: left;
  margin-bottom: 21px;
}

#wwapoint .wwapoint__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

#wwapoint .wwapoint__list li {
  width: -webkit-calc(50% - 2px);
  width: calc(50% - 2px);
  list-style-type: none;
  padding: 18px 15px 18px 28px;
  margin-bottom: 3px;
  background: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

#wwapoint .wwapoint__list li:nth-child(3), #wwapoint .wwapoint__list li:nth-child(4) {
  padding: 23px 12px 24px 28px;
}

#wwapoint .wwapoint__list .wwapoint__number {
  width: 43px;
  height: 60px;
  background: #F5F5F5;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font: bold 28px/1 'PT Sans', sans-serif;
  text-align: center;
  color: #1E488D;
}

#wwapoint .wwapoint__list .wwapoint__text1 {
  width: -webkit-calc(100% - 74px);
  width: calc(100% - 74px);
  padding-left: 31px;
  font: 14px/13px 'PT Sans', sans-serif;
  font: 16px/15px 'PT Sans', sans-serif;
  color: #1E488D;
}

@media (max-width: 1200.98px) {
  .site-content.who-we-are {
    padding: 20px 12px;
  }
  .messages-btn-style::after {
    top: -32%;
    left: 5px;
  }
}

@media (max-width: 800.98px) {
  #wwa {
    width: calc(100% + 17px);
    margin-left: -12px;
    margin-bottom: -20px;
  }
  .break-before-sm::before {
    content: '\A';
    white-space: pre;
  }
  #wwafirst {
    width: 100%;
    max-width: 100%;
  }
  #wwaeggs {
    padding: 21px 21px 47px;
  }
  #wwaeggs .wwaeggs__wrap::after {
    white-space: normal;
  }
  #wwaeggs .wwaeggs__list li {
    width: 49%;
    padding: 35px 20px 32px 20px;
    margin-bottom: -webkit-calc(1%);
    margin-bottom: calc(1%);
  }
  #wwaeggs .wwaeggs__list li.wwaeggs__first {
    padding: 35px 20px 32px;
  }
  #wwaeggs .wwaeggs__list li.wwaeggs__first .wwaeggs__title {
    display: block;
    font: bold 18px/1 'PT Sans', sans-serif;
    margin-bottom: 29px;
  }
  #wwaeggs .wwaeggs__title {
    font: bold 16px/1 'PT Sans', sans-serif;
    margin-bottom: 14px;
  }
  #wwaeggs .wwaeggs__description {
    font: 16px/18px 'PT Sans', sans-serif;
  }
  #wwasteps {
    padding: 0 0 82px;
  }
  #wwasteps .wwasteps__list {
    width: 95%;
    padding: 0 21px;
    margin-bottom: 20px;
    margin-top: -102px;
  }
  .wwasteps__list .wwasteps_arrow {
    display: none;
  }
  #wwasteps .wwasteps__list li {
    padding: 0 5px;
    width: 25%;
    display: none;
  }
  #wwacare {
    padding: 0 21px 30px;
  }
  #wwacare .wwa__title {
    margin-bottom: 16px;
  }
  #wwacare .wwacare__tabs {
    margin-bottom: 20px;
  }
  #wwacare .wwacare__img {
    width: 50%;
  }
  #wwacare .wwacate__right {
    width: 50%;
  }
  #wwacare .wwacate__right .wwacare__item {
    width: -webkit-calc(100% - 9px);
    width: calc(100% - 9px);
    margin-left: 9px;
  }
  #wwacare .wwacate__right .wwacare__item:nth-child(1), #wwacare .wwacate__right .wwacare__item:nth-child(2), #wwacare .wwacate__right .wwacare__item:nth-child(3) {
    padding: 19px 23px 16px;
  }
  #wwacare .wwacate__right .wwacare__item:last-child {
    margin-bottom: 0;
  }
  #wwacare .wwacate__right .wwacare__subtitle {
    font: bold 16px/1 'PT Sans', sans-serif;
    margin-bottom: 9px;
  }
  #wwacare .wwacate__right .wwacare__text1 {
    font: 14px/13px 'PT Sans', sans-serif;
  }
  #wwacare .wwacate__right .wwacare__text1 .break-after::after {
    white-space: normal;
  }
  #wwapoint {
    padding: 0 21px 60px;
  }
  #wwapoint .wwapoint__accent {
    width: 100%;
    padding: 16px 12px;
    font: bold 18px/17px 'PT Sans', sans-serif;
    margin-bottom: 20px;
  }
  #wwapoint .wwapoint__accent.wwapoint__accent_white {
    font: bold 16px/15px 'PT Sans', sans-serif;
    margin-bottom: 21px;
  }
  #wwapoint .wwa__title {
    text-align: center;
  }
  #wwapoint .wwapoint__list {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  #wwapoint .wwapoint__list li {
    width: 100%;
    padding: 10px 11px;
    margin-bottom: 10px;
    display: block;
  }
  #wwapoint .wwapoint__list li:nth-child(3), #wwapoint .wwapoint__list li:nth-child(4) {
    padding: 10px 11px;
  }
  #wwapoint .wwapoint__list li:last-child {
    margin-bottom: 0;
  }
  #wwapoint .wwapoint__list .wwapoint__number {
    width: 43px;
    height: 35px;
    font: bold 28px/1 'PT Sans', sans-serif;
    margin-bottom: 9px;
  }
  #wwapoint .wwapoint__list .wwapoint__text1 {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 0px;
    font: 14px/13px 'PT Sans', sans-serif;
  }
}

@media (max-width: 576.98px) {
  #wwafirst {
    width: 100%;
    max-width: 100%;
    padding: 58px 0 72px 0;
    background: url(@/assets/images/whoWeAre/first__bg-sm.jpg) no-repeat center center/cover;
  }
  #wwafirst .wwafirst__title {
    font: bold 22px/24px 'PT Sans', sans-serif;
    margin-bottom: 12px;
  }
  #wwafirst .wwafirst__text1 {
    font: bold 14px/1 'PT Sans', sans-serif;
    margin-bottom: 35px;
  }
  #wwaeggs {
    padding: 30px 0px 30px;
    overflow: hidden;
  }
  #wwaeggs .wwaeggs__wrap::after {
    white-space: normal;
  }
  #wwaeggs .wwaeggs__list {
    position: relative;
  }
  #wwaeggs .wwaeggs__list li {
    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 8px 17px 11px 30px;
    margin-bottom: 4px;
  }
  #wwaeggs .wwaeggs__list li.wwaeggs__first {
    padding: 14px 20px 15px;
    margin-bottom: 11px;
  }
  #wwaeggs .wwaeggs__list li.wwaeggs__first .wwaeggs__title {
    display: block;
    font: bold 18px/1 'PT Sans', sans-serif;
    margin-bottom: 6px;
  }
  #wwasteps .wwasteps__title {
    padding: 32px 0 65px 0;
    font: bold 18px/17px 'PT Sans', sans-serif;
    background: url(@/assets/images/whoWeAre/steps__bg.jpg) no-repeat center center/cover;
  }
  #wwasteps .wwasteps__wrap::after {
    content: '\A';
    white-space: pre;
  }
  #wwasteps .wwasteps__list {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    margin-top: -21px;
  }
  #wwasteps .wwasteps__list li {
    padding: 0 5px;
    width: 30%;
    min-height: 150px;
  }
  #wwasteps .wwasteps__list li::before {
    display: block;
    position: absolute;
    top: 40px;
    left: -webkit-calc(50% - 13.5px);
    left: calc(50% - 13.5px);
    font: bold 48px/1 'PT Sans', sans-serif;
    color: #1E488D;
  }
  #wwasteps .wwasteps__list li span {
    padding-top: 110px;
  }
  #wwasteps .wwasteps__list li svg:not(.wwasteps_arrow) {
    position: absolute;
    top: -25px;
    right: -webkit-calc(50% - 25px);
    right: calc(50% - 25px);
  }
  #wwasteps .wwasteps__list svg.wwasteps_arrow {
    margin-top: 69px;
    left: 1px;
  }
  #wwareviews {
    overflow: hidden;
  }
  #wwareviews .wwareviews__slider {
    width: 100%;
    margin-left: 0%;
    padding: 30px 0 32px;
    position: relative;
    z-index: 1;
  }
  #wwareviews .wwareviews__slider .owl-stage-outer {
    margin-bottom: 20px;
  }
  #wwareviews .wwareviews__slider .owl-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    width: 20px;
    height: auto;
    position: absolute;
    left: auto;
    right: 0;
    top: auto;
    bottom: 30px;
  }
  #wwareviews .wwareviews__slider .owl-nav .owl-prev {
    display: none;
  }
  #wwareviews .wwareviews__slider .owl-nav img:hover {
    opacity: .5;
  }
  #wwareviews .wwareviews__slider .owl-dots {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 80%;
    margin: 0;
    margin-right: 10px;
    padding-left: 10px;
  }
  #wwareviews .wwareviews__item {
    opacity: 0.5;
    position: relative;
  }
  #wwareviews .wwareviews__item::before {
    height: 0px;
  }
  #wwareviews .wwareviews__item .wwareviews__video {
    min-height: 220px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: contain;
    margin: 0 34px;
    top: 0px;
  }
  #wwareviews .wwareviews__item .wwareviews__video svg {
    display: none;
  }
  #wwareviews .wwareviews__item .wwareviews__description {
    visibility: visible;
    padding: 21px 5px 23px;
  }
  #wwareviews .wwareviews__item .wwareviews__description h4 {
    font: bold 16px/15px 'PT Sans', sans-serif;
    margin-bottom: 9px;
  }
  #wwareviews .wwareviews__item .wwareviews__description p {
    font: 14px/16px 'PT Sans', sans-serif;
  }
  #wwareviews .owl-item.center {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  #wwacare {
    padding: 0 10px 30px;
  }
  #wwacare .wwacare__tabs {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  #wwacare .wwacare__img {
    display: block;
    width: -webkit-calc(100% + 20px);
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 11px;
    height: 100%;
  }
  #wwacare .wwacate__right {
    width: 100%;
  }
  #wwacare .wwacate__right .wwacare__item {
    width: 100%;
    margin-left: 0px;
  }
  #wwacare .wwacate__right .wwacare__item:nth-child(1), #wwacare .wwacate__right .wwacare__item:nth-child(2), #wwacare .wwacate__right .wwacare__item:nth-child(3) {
    padding: 27px 21px 22px;
  }
  #wwacare .wwacate__right .wwacare__item:last-child {
    margin-bottom: 0;
  }
  #wwacare .wwacate__right .wwacare__subtitle {
    font: bold 16px/1 'PT Sans', sans-serif;
    margin-bottom: 9px;
  }
  #wwacare .wwacate__right .wwacare__text1 {
    font: 14px/13px 'PT Sans', sans-serif;
  }
}

.striped-table-wrapper {
  border: 1px solid #c8d8df;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 7px;
}

table.striped-table {
  border-collapse: collapse;
  width: 100%
}

table.striped-table td {
  padding: 5px 8px;
  border-right: 3px solid #fff;
  vertical-align: top
}

table.striped-table tr td:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

table.striped-table tr td:last-child {
  border-right: 0;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0
}

table.striped-table th {
  text-align: left;
  font-weight: 700;
  padding: 3px 8px 10px;
}

table.striped-table tr:nth-child(2n) td {
  background: #e8f2f7
}

table.striped-table-small td {
  font-size: 13px;
}

table.striped-table tr.title th {
  border-right: 1px solid #c8d8df;
  border-bottom: 1px solid #c8d8df
}

table.striped-table .nor {
  border-right: none !important
}

table.striped-table .nob {
  border-bottom: none !important
}

.special-photo-thumbs a,
.user-profile .left-column .photo {
  border: 1px solid #c8d8df;
  padding: 3px;
  line-height: 0
}

table.striped-table tr.white>td {
  background: #fff
}

.user-profile .left-column {
  float: left;
  width: 388px;
}

.user-profile .left-column .photo,
.small-girls-list a {
  position: relative;
}

.sticker-user-btn {
  display: block;
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 40px;
  width: 41px;
  background: url(//storage.victoriyaclub.com/img/sticker_ico.png) no-repeat right center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  z-index: 2;
}

.sticker-user-btn.right {
  left: auto;
  right: 8px;
  height: 26px;
  width: 26px;
  background-size: cover;
  bottom: 5px;
}

.sticker-user-btn.right.with-birthday {
  right: 40px;
}

.sticker-user-btn.left {
  left: 8px;
  right: auto;
  height: 26px;
  width: 26px;
  background-size: cover;
  bottom: 5px;
}

.sticker-user-btn.right.mini {
  width: 18px;
  height: 18px;
}

.newchat-users-list .sticker-user-btn.right.mini {
  width: 16px;
  height: 16px;
  right: 3px;
  bottom: 3px;
}

.small-girls-list .sticker-user-btn.right {
  width: 16px;
  height: 16px;
  right: 10px;
  bottom: 7px;
}

.sticker-user-btn.hide {
  display: none;
}

.sticker-user-btn.clear {
  background-position: left center;
}

.sticker-user-btn .tooltip-info {
  position: absolute;
  left: calc(100% + 13px);
  background: #fffffa;
  color: #333333;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  padding: 8px 16px;
  font-weight: bold;
  top: -50%;
  bottom: -50%;
  margin: auto;
  height: 28px;
  display: none;
  opacity: 0;
  transition: opacity 0.4s;
}

</style>
