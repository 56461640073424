<template>

  <WGTabs
    :tabs="tabs"
    name="auth"
    v-model="currentTab"
    background-type="empty"
    class="wg-auth-tabs-form-wrap"
  >

    <template #tab-head-0>
      <span class="text-capitalize">
        {{ $t('global.signUp') }}
      </span>
      <WGTag class="text-uppercase">
        {{ $t('global.free') }}
      </WGTag>
    </template>

    <template #tab-panel-0>
      <WGSignUpForm :isMini="isMini" />
    </template>

    <template #tab-head-1>
      <span class="text-capitalize">
        {{ $t('global.logIn') }}
      </span>
    </template>

    <template #tab-panel-1>
      <WGLoginForm />
    </template>

  </WGTabs>

</template>

<script>
import { ref } from 'vue'

import WGTabs from '@/components/UI/WGTabs/WGTabs'
import WGSignUpForm from '@/components/auth/WGSignUpForm'
import WGLoginForm from '@/components/auth/WGLoginForm'
import WGTag from '@/components/UI/WGTags/WGTag'

export default {
  name: 'WGAuthForm',
  props: {
    isMini: Boolean
  },
  components: {
    WGTabs,
    WGSignUpForm,
    WGLoginForm,
    WGTag
  },
  setup () {
    const tabs = ref([{ name: 0, value: 0 }, { name: 1, value: 1 }])
    const currentTab = ref({ name: 0, value: 0 })

    return {
      tabs,
      currentTab
    }
  }
}
</script>
