
import WGTabs from '@/components/UI/WGTabs/WGTabs'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGTabs',
  component: WGTabs,
  tags: ['autodocs']
}

const Template = (args) => ({
  // Components used in your story `template` are defined in the `components` object
  components: { WGTabs },
  // The story's `args` need to be mapped into the template through the `setup()` method
  setup () {
    const tabs = [0, 1, 2]

    return { args, tabs }
  },
  // And then the `args` are bound to your component with `v-bind="args"`
  template: '<WGTabs :tabs="tabs"> <template #tab-head-0> head 1 </template> <template #tab-panel-0> panel 1 </template> <template #tab-head-1> head 2 </template> <template #tab-panel-1> panel 2 </template> <template #tab-head-2> head 3 </template> <template #tab-panel-2> panel 3 </template> </WGTabs>'
})

export const Docs = Template.bind({})

Docs.args = {
  tabs: [0, 1, 2],
  initialTab: 0
}
