import WGFooter from '@/components/UI/WGFooter/WGFooter.vue'

import './footer.css'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction

export default {
  title: 'Example/WGFooter',
  component: WGFooter,
  tags: ['autodocs']
}

export const Docs = {
  args: {}
}
