const pages = {
  PageHome: ['home', 'horisontal'],
  SearchGirls: ['search'],
  PageProfile: ['profile'],
  PageProfileRu: ['profile'],
  MeetBrideOnlineProfile: ['profile'],
  UkrainianDateSingleProfile: ['profile']
}

const getPromotionByPage = page => {
  return pages[page] || []
}

export default getPromotionByPage
