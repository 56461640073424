<template>
  <nav class="wg-main-menu" :class="className">

    <WGList
      :list="list"
      :orientations="$props.isMini ? 'vertical' : 'horizontal'"
      :variant="$props.isMini ? 'ordered-zero' : 'none'"
    >

      <template #item="{item}">

        <routerLink
          :to="item.linkHref"
          @click="handleClickItem(item, $emit)"
          class="wg-main-menu-link"
        >
          <WGPromptInfo
            :title="$t(item.title, {'countGlobalUsers': siteSettings?.global_users || 0})"
            :description="$t(item.description)"
            :icon="item.icon"
          >

            {{ item.linkText }}

            <template #descriptionSlot v-if="item.descriptionAccent">
              <strong class="wg-main-menu-link-tag">
                {{ $t(item.descriptionAccent, {'countGlobalOnline': siteSettings?.global_online || 0}) }}
              </strong>
            </template>

            <template #titleSlot v-if="item.dropDownMenu && !$props.isMini">
              <WGIcon :icon="dropDownMenuIcon"/>
            </template>

          </WGPromptInfo>

        </routerLink>

        <WGList
          class="wg-main-menu-drop-down"
          :list="item.dropDownMenu"
        >

          <template #item="{item}">

            <routerLink :to="item.linkHref" class="wg-main-menu-link">

              <WGPromptInfo
                :title="$t(item.title)"
                :description="$t(item.description)"
                :icon="item.icon"
              >
                {{ item.linkText }}
              </WGPromptInfo>

            </routerLink>

          </template>

        </WGList>

      </template>

    </WGList>

  </nav>
</template>

<script>

import { computed, ref, inject } from 'vue'

import WGPromptInfo from '@/components/UI/WGPromptInfo/WGPromptInfo'
import WGList from '@/components/UI/WGLists/WGList'
import WGIcon from '@/components/UI/WGIcons/WGIcon'
import { useStore } from 'vuex'

export default {
  name: 'WGMainMenu',
  components: {
    WGList,
    WGPromptInfo,
    WGIcon
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { mainMenuLinks, mainMenuMobileLinks } = inject('$projectLinks')
    const store = useStore()
    const dropDownMenuIcon = ref('wg-ffi-arrow-down')

    const className = computed(() => {
      return {
        'is-mini': props.isMini
      }
    })
    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })
    const list = computed(() => {
      if (props.isMini) {
        return [...mainMenuMobileLinks]
      }
      return [...mainMenuLinks(props.isMini)]
    })

    const handleClickItem = (item, emit) => {
      if (props.isMini) {
        if (Object.prototype.hasOwnProperty.call(item, 'onClick')) {
          item.onClick(store, emit)
        } else {
          emit('close')
        }
      }
    }

    return {
      dropDownMenuIcon,
      handleClickItem,
      className,
      list,
      siteSettings,
      store
    }
  }
}
</script>
