<template>
  <div class="wg-footer-secondary">
    <WGContainer>
      <div class="wg-footer-row">
        <img src="@/assets/images/transactions-logo.svg" alt="transactions-logo">

        <i18n-t keypath="footer.transactions" tag="p" scope="global" class="wg-footer-text">

          <template #actions1>
            <WGLink linkHref="https://pay.google.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
              {{ $t('global.paymentList.listItem1') }}
            </WGLink>
          </template>

          <template #actions2>
            <WGLink linkHref="https://www.billing.creditcard/" variant="primary" linkTarget="_blank" isFontSizeInherit>
              {{ $t('global.paymentList.listItem2') }}
            </WGLink>
          </template>
          <template #actions3>
            <WGLink linkHref="https://www.unlimit.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
              {{ $t('global.paymentList.listItem3') }}
            </WGLink>
          </template>
          <template #actions4>
            <WGLink linkHref="https://cs.segpay.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
              {{ $t('global.paymentList.listItem4') }}
            </WGLink>
          </template>

        </i18n-t>

      </div>
    </WGContainer>
  </div>
</template>

<script>
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGLink from '@/components/UI/WGLinks/WGLink'

export default {
  name: 'WFFooterSecondaryContent',
  components: {
    WGContainer,
    WGLink
  }
}
</script>
