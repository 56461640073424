<template>
  <div class="wg-logo">
    <WGLink
      :linkHref="linkHref"
      :linkTarget="linkTarget"
      :variant="variant"
      :isUnderline="isUnderline"
    >
      <img
        v-if="isHasPromotion"
        :src="logoSrc"
        alt="promotion-logo"
      >
      <img
        v-else
        :src="logoSrc"
        alt="logo">
    </WGLink>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

import WGLink from '@/components/UI/WGLinks/WGLink'

export default {
  name: 'WGLogo',
  components: {
    WGLink
  },
  props: {
    isHasPromotion: {
      type: Boolean,
      default: false
    },
    fullLogoSrc: {
      type: String
    },
    shortLogoSrc: {
      type: String
    },
    isMini: {
      type: Boolean,
      default: false
    },
    linkHref: {
      type: String,
      default: '/'
    },
    linkTarget: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: 'main'
    },
    isUnderline: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const logoSrc = ref('')

    const initialize = () => {
      props.isMini ? logoSrc.value = props.shortLogoSrc : logoSrc.value = props.fullLogoSrc
    }

    onMounted(() => {
      initialize()
    })

    watch(() => props.isMini, () => {
      initialize()
    })

    return {
      logoSrc,
      initialize
    }
  }
}
</script>
