import { API } from './API'

export class AuthForm {
  Api;
  endpoint = '/user/profile';
  endpointRegGirl = '/girl/profile';
  endpointReg = '/js/html/profile';

  constructor () {
    this.Api = new API()
  }

  login (data) {
    return this.Api.post(`${this.endpoint}/login`, data)
  }

  registration (data) {
    return this.Api.post(`${this.endpoint}/reg`, data)
  }

  registrationGirl (data) {
    return this.Api.post(`${this.endpointRegGirl}/reg/`, data)
  }

  restorePasswordByEmail (email) {
    return this.Api.post(`${this.endpoint}/lost_pass`, email)
  }

  changePassword (payload) {
    return this.Api.post(`${this.endpoint}/lost_pass/confirm`, payload)
  }
}
