<template>
  <div class="wg-filter-popup">
    <div class="wg-filter-popup-wrapper">
      <p
        v-if="!$slots.default"
        class="wg-filter-popup-wrapper_label"
        @click="applyFilters"
      >
        {{ $t('searchFilters.apply') }}
      </p>
      <slot v-else></slot>
      <div class="wg-filter-popup-wrapper_arrow"></div>
    </div>
  </div>
</template>

<script>

export default {
  setup (props, { emit }) {
    const applyFilters = () => {
      emit('apply-filters')
    }

    return {
      applyFilters
    }
  }
}
</script>
