<template>
  <div id="container">
    <div id="flip" ref="flipContainer">
      <div v-for="(word, index) in words" :key="index" class="animated-word">
        <div class="animated-word-item">{{ word }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      words: ['New', 'Online', 'Text'],
      wordsLength: 0,
      currentIndex: 0
    }
  },
  created () {
    this.wordsLength = this.words.length
  },
  mounted () {
    this.animateWords()
  },
  methods: {
    animateWords () {
      const flipContainer = this.$refs.flipContainer

      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.wordsLength
        flipContainer.style.transform = `translateY(-${(100 / this.wordsLength) * this.currentIndex}%)`
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
#container {
  color: #999;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  width: 100%;
  bottom: 45%;
  display: block;
  overflow: hidden;
  max-height: 45px;
}

#flip {
  display: flex;
  flex-direction: column;
  transition: transform 1s ease-in-out;
}

.animated-word {
  height: 100%;
  width: 100%;
}

.animated-word-item {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42c58a;
  backface-visibility: hidden;
}
</style>
