<template>
  <div class="wg-media-container" >
    <template v-for="(image, index) of medias">
      <div
        v-if="isVisible(index)"
        class="wg-media-container_item"
        :class="{'wg-media-container_item--last': index+1 === count}"
        :style="{ order: index + 1 === count ? count : 'auto' }"
        :key="image.id"
      >
        <img
          v-if="$slots.accessRequired && !image.closed && !image.isPaid"
          @click="$emit('handleClick', image)"
          class="wg-media-container_item-picture"
          :src="image.url"
          alt=""
        >
        <slot v-else :image="image" name="accessRequired"></slot>
        <span
          v-if="index+1 === count && (medias.length - visibleMediaCount - 1) && (medias.length - visibleMediaCount - 1) > 0"
          class="wg-media-container_item-count"
        >
          + {{ medias.length - visibleMediaCount - 1 }}
        </span>
        <slot v-if="index+1 !== count" name="default-icon"></slot>
        <slot v-else name="last-icon"></slot>
      </div>
    </template>
  </div>
  <div class="wg-media-container_more">
    <slot name="more"></slot>
  </div>
</template>

<script>
import { useBreakpointStore } from '@/controllers/components/breakpointMixin.js'

export default {
  name: 'WGMediaContainer',
  props: {
    medias: Array,
    columnsLg: {
      type: Number,
      required: false
    },
    columnsMd: {
      type: Number,
      required: false
    },
    columnsSm: {
      type: Number,
      required: false
    },
    columnsXs: {
      type: Number,
      required: false
    }
  },
  emits: ['handleClick'],
  setup (props) {
    const { currentBreakpoint, getCurrentBreakpoint } = useBreakpointStore()

    let count
    const isVisible = (index) => {
      getCurrentBreakpoint()
      switch (currentBreakpoint.value) {
        case 'XXS':
          count = props.columnsXs
          break
        case 'XS':
          count = props.columnsSm
          break
        case 'SM':
          count = props.columnsMd
          break
        default:
          count = props.columnsLg
          break
      }

      return index + 1 <= count
    }

    const visibleMediaCount = props.medias.reduce((count, _, index) => {
      return isVisible(index) ? count + 1 : count
    }, 0)

    return {
      isVisible,
      count,
      visibleMediaCount
    }
  }
}
</script>

<style scoped>

</style>
