
import WGTitleMarkLetter from '@/components/UI/WGTitleMarkLetter/WGTitleMarkLetter'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGTitleMarkLetter',
  component: WGTitleMarkLetter,
  tags: ['autodocs'],
  argTypes: {
    variant: {
      control: 'select',
      options: ['success', 'primary']
    }
  }
}

export const Docs = {
  args: {
    title: 'title',
    default: 'lorem Ips y Argumentum et accus ut labore et dolor Argumentum',
    slotActions: 'slotActions'
  }
}
