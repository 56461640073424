const BCP47 = {
  ua: 'uk-UA',
  en: 'en-EN',
  ru: 'ru-RU'
}
export default {
  state: {
    locale: localStorage.getItem('nwLocale') || 'en',
    localeBCP47: localStorage.getItem('nwLocaleBCP47') || 'en-EN'
  },
  getters: {
    getLocale (state) {
      return state.locale
    },
    getLocaleBCP47 (state) {
      return state.localeBCP47
    }

  },
  mutations: {
    setLocate: function (state, data) {
      if (data !== null) {
        state.locale = data
        state.localeBCP47 = BCP47[data]
        localStorage.setItem('nwLocale', data)
        localStorage.setItem('nwLocaleBCP47', state.localeBCP47)
      } else {
        state.locale = null
        state.localeBCP47 = null
        localStorage.removeItem('nwLocale')
        localStorage.removeItem('nwLocaleBCP47')
      }
    }
  },
  actions: {
    setLocate ({ commit }, data) {
      commit('setLocate', data)
    }
  }
}
