<template>
  <WGModal class="victoria-modal-auth">

    <aside class="victoria-modal-auth-aside">
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-1" />
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-2" />
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-3" />
    </aside>

    <WGResetPasswordByEmail
      title="formAuth.resetPassword.title"
      description="formAuth.resetPassword.description"
    >
      <template #default>
        <div>
          <WGLabel>
            {{ $t('formAuth.resetPassword.labelEmail') }}
          </WGLabel>
          <WGFieldEmail
            v-model="userEmail"
            :placeholder="$t('global.forms.placeholderEmail')"
          />
          <div class="wg-field-error" v-if="emailError">
            {{ $t(emailError) }}
          </div>
        </div>
        <WGButton @click="sendEmail" variant="success">
          {{ $t('formAuth.resetPassword.send') }}
        </WGButton>
      </template>
    </WGResetPasswordByEmail>
  </WGModal>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import WGModal from '@/components/WGModal/WGModal.vue'
import WGLabel from '@/components/UI/WGForms/WGLabel.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGFieldEmail from '@/components/UI/WGForms/WGFieldEmail.vue'
import WGCardPreview from '@/components/UI/WGAsideBanners/WGCardPreview.vue'
import WGResetPasswordByEmail from '@/components/auth/WGResetPasswordByEmail.vue'
import girlPreview from '@/assets/images/cardPreview/cardPreviewResetByEmail.webp'
export default {
  name: 'ResetPassword',
  components: {
    WGLabel,
    WGButton,
    WGFieldEmail,
    WGResetPasswordByEmail,
    WGCardPreview,
    WGModal
  },
  setup () {
    const store = useStore()

    const userEmail = ref('')
    const emailError = ref('')
    const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    const checkingEmailExists = (data) => {
      if (data.result === 'fail') {
        switch (true) {
          case data.error === 'user_not_exists':
            emailError.value = 'formAuth.resetPassword.errorUserNotExist'
            break
          case data.error === 'user_type':
            emailError.value = 'formAuth.resetPassword.errorUserType'
            break
          default:
            emailError.value = ''
            break
        }
      } else {
        store.commit('removeAllModal')
        store.dispatch('toast', {
          text: 'Link to reset your password has been sent!',
          type: 'SUCCESS'
        })
      }
    }
    const sendEmail = () => {
      if (userEmail.value && regexEmail.test(userEmail.value)) {
        store.dispatch('apiRestorePasswordByEmail', userEmail.value).then(response => {
          checkingEmailExists(response)
        })
      }
    }

    return {
      userEmail,
      girlPreview,
      emailError,
      sendEmail
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/common/abstracts/_variables.scss';

.wg-field-error {
  margin-top: 3px;
  font-size: 12px;
  line-height: 1.333;
  color: $sunset-orange;
}
</style>
