<template>
  <div class="wg-auth-trouble">
    <p class="wg-auth-trouble_text">If you can not log in to VictoriyaClub make the following steps :</p>
    <p class="wg-auth-trouble_text">1. Make sure you have switched to <b>Log in</b> tab instead of <b>Sign Up</b>.</p>
    <p class="wg-auth-trouble_text">2. If it still does not work, try to reset your password. To do so press <b>Forgot your password</b>? on Sign in screen and enter your registration email. After that you will receive a message with a link for <b>resetting your password</b>.</p>
    <p class="wg-auth-trouble_text">3. Also our Support Team can reset a password for you.
      Send us a request to reset your password, mentioning your registration email, to <a href="mailto:support@VictoriyaClub.com">support@VictoriyaClub.com</a>  and we will do that for you.
      When your request is processed, you will receive an email with a link for <b>resetting your password</b>.</p>
    <p class="wg-auth-trouble_text">4. If you still have difficulties please contact us <a href="mailto:support@VictoriyaClub.com">support@VictoriyaClub.com</a> .</p>
    <a href="https://victoriyaclub.com/files/faq/Log%20in%20tab.png" target="_blank">
      <img src="@/assets/images/faq/Log-in-tab.png" alt="">
    </a>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.wg-auth-trouble {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &_text {
  }
}
</style>
