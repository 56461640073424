<template>
  <div class="wg-grid" :class="className">
    <!-- TODO: create template &  -->
    <template v-if="dataList && dataList.length">
      <div class="wg-grid-item" v-for="(item, index) in dataList" :key="index">

        <slot
          name="item"
          :item="item"
          :index="index"
        >
          {{ item }}
        </slot>

      </div>
    </template>

      <slot v-else></slot>

  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'WGGrid',
  props: {
    dataList: {
      type: Array,
      default: null
    },
    columns: {
      type: [String, Number],
      required: true,
      default: 3,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    columnsLg: {
      type: Number,
      required: false,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    columnsMd: {
      type: Number,
      required: false,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    columnsSm: {
      type: Number,
      required: false,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    columnsXs: {
      type: Number,
      required: false,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    columnsXXs: {
      type: Number,
      required: false,
      validator: (prop) =>
        [1, 2, 3, 4, 5, 6].includes(
          prop
        )
    },
    gap: {
      type: Number,
      required: true,
      default: null,
      validator: (prop) =>
        [24, 16].includes(
          prop
        )
    },
    gapLg: {
      type: Number,
      default: null,
      validator: (prop) =>
        [24, 16].includes(
          prop
        )
    },
    gapMd: {
      type: Number,
      default: null,
      validator: (prop) =>
        [24, 16].includes(
          prop
        )
    },
    gapSm: {
      type: Number,
      default: null,
      validator: (prop) =>
        [24, 16].includes(
          prop
        )
    },
    gapXs: {
      type: Number,
      default: null,
      validator: (prop) =>
        [24, 16].includes(
          prop
        )
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        // columns
        ['wg-grid-columns-' + props.columns]: props.columns,
        ['wg-grid-columns-lg-' + props.columnsLg]: props.columnsLg,
        ['wg-grid-columns-md-' + props.columnsMd]: props.columnsMd,
        ['wg-grid-columns-sm-' + props.columnsSm]: props.columnsSm,
        ['wg-grid-columns-xs-' + props.columnsXs]: props.columnsXs,
        ['wg-grid-columns-xxs-' + props.columnsXXs]: props.columnsXXs,
        // gaps
        ['wg-grid-gap-' + props.gap]: props.gap,
        ['wg-grid-gap-lg-' + props.gapLg]: props.gapLg,
        ['wg-grid-gap-md-' + props.gapMd]: props.gapMd,
        ['wg-grid-gap-sm-' + props.gapSm]: props.gapSm,
        ['wg-grid-gap-xs-' + props.gapXs]: props.gapXs
      }
    })

    return {
      className
    }
  }
}
</script>
