import { API } from './API'

export class GeoList {
  Api;
  countryEndpoint = 'geo/countries';
  citiesEndpoint = (id) => `geo/country/${id}/cities`;

  constructor () {
    this.Api = new API()
  }

  apiGetCountriesList (unAuth = false) {
    let endpoint = ''
    if (unAuth) endpoint = '/un_auth'
    return this.Api.getM(`${endpoint}/${this.countryEndpoint}`)
  }

  apiGetCitiesList (unAuth = false, id) {
    let endpoint = ''
    if (unAuth) endpoint = '/un_auth'
    return this.Api.getM(`${endpoint}/${this.citiesEndpoint(id)}`)
  }
}
