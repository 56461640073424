import { ref, provide, onMounted, onBeforeUnmount } from 'vue'
import { XXS, XS, SM, MD, LG } from '@/components/UI/breakpoints'

export function useBreakpointStore () {
  const isMobile = ref(false)
  const currentBreakpoint = ref('')

  const getCurrentBreakpoint = () => {
    if (window.matchMedia('(max-width:' + XXS + 'px)').matches) {
      currentBreakpoint.value = 'XXS'
    } else if (window.matchMedia('(max-width:' + XS + 'px)').matches) {
      currentBreakpoint.value = 'XS'
    } else if (window.matchMedia('(max-width:' + SM + 'px)').matches) {
      currentBreakpoint.value = 'SM'
    } else if (window.matchMedia('(max-width:' + MD + 'px)').matches) {
      currentBreakpoint.value = 'MD'
    } else if (window.matchMedia('(max-width:' + LG + 'px)').matches) {
      currentBreakpoint.value = 'LG'
    } else {
      currentBreakpoint.value = 'XL'
    }
  }

  const onChangeBreakpoints = () => {
    const breakpointSM = window.matchMedia('(max-width:' + SM + 'px)')
    isMobile.value = breakpointSM.matches
    if (breakpointSM.matches) {
      currentBreakpoint.value = 'SM'
    }
    breakpointSM.addListener((e) => {
      isMobile.value = e.matches
      if (e.matches) {
        currentBreakpoint.value = 'SM'
      }
    })
  }

  onMounted(() => {
    getCurrentBreakpoint()
    onChangeBreakpoints()
    window.addEventListener('resize', getCurrentBreakpoint)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', getCurrentBreakpoint)
  })

  provide('breakpointStore', { currentBreakpoint, isMobile })

  return { currentBreakpoint, isMobile, getCurrentBreakpoint }
}
