<template>
  <main class="page-main">
    <WGContainer v-if="profile" is-clear-offsets>
      <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
    </WGContainer>
    <WGContainer class="bg-white">
      <div v-if="profile" class="wg-profile-home-content">
        <div v-if="profile.active" class="wg-profile">
          <div class="wg-profile_avatar" @click="handleAvatar">
            <img
              class="wg-profile_avatar-picture"
              :src="siteSettings?.settings?.url_storage+profile.photo_big"
              alt="avatar"
            />
          </div>

          <div class="wg-profile_name-and-options">
            <div class="wg-profile_name-and-options_name">
              <h1 class="wg-profile_name-and-options_item">
                {{profile.marital_status}}&nbsp;{{ profile.name }}
                <span>
                  <WGIndicatorOnline v-if="profile.is_online" />
                  <span class="wg-profile_name-and-options_visited" v-else>
                    {{ $t('profile.lastVisited') }}&nbsp;{{ profile.last_visit }}
                  </span>
                </span>
              </h1>
              <div class="wg-profile_name-and-options_video">
                {{ $t('profile.videoCallAvailable') }}
                <span class="wg-ffi-icon wg-ffi-webcam-fill text-green-700"></span>
              </div>
            </div>
            <div class="wg-profile_name-and-options_holder">
              <div class="wg-profile_name-and-options_id">
                {{ $t('profile.profileID') }}&nbsp;{{ profile.id }}
              </div>
            </div>
          </div>

          <div
            class="wg-profile_media-container"
            :class="{'wg-profile_media-container--full' : !previewVideosList.length}"
          >
            <div v-if="profile.photos.length" class="photo-video_media-container_media">
              <WGMediaContainer
                :medias="smallPhotosList"
                :columns-lg="5"
                :columns-md="4"
                :columns-sm="3"
                :columns-xs="!previewVideosList.length ? 4 : 2"
                @handleClick="handleAvatar"
              >
                <template #accessRequired="{image}">
                  <WGAccessOverlayImage
                    :media="image.closed && image.isPaid ? premiumAccessImage : pictureAccessDenied"
                    :title="image.closed && image.isPaid ? 'premium' : 'for members'"
                    :description="image.closed && image.isPaid ? 'photo' : 'only'"
                    @click="handleAvatar"
                    :photo-background="siteSettings?.settings?.url_storage+profile.photo_medium"
                  />
                </template>
                <template #more>
                  <p class="photo-video_media-container_media-nav">
                    <img src="@/assets/images/icons/photo-media.svg" alt="gallery">
                    {{ $t('profile.girlsPictures') }}
                  </p>
                  <div class="photo-video_media-container_media-holder" @click="handleAvatar">
                    <p class="photo-video_media-container_media-nav--desktop">
                      {{ $t('profile.viewMorePhoto') }}
                    </p>
                    <p class="photo-video_media-container_media-nav--mobile">
                      {{ $t('profile.morePhoto') }}
                    </p>
                    <img src="@/assets/images/icons/blue-shevron-down.svg" alt="shevron">
                  </div>
                </template>
              </WGMediaContainer>
            </div>
            <div
              v-if="previewVideosList.length"
              class="photo-video_media-container_media"
            >
              <WGMediaContainer
                :medias="previewVideosList"
                :columns-lg="5"
                :columns-md="4"
                :columns-sm="3"
                :columns-xs="2"
                @handleClick="handleAvatar"
              >
                <template #accessRequired="video">
                  <WGAccessOverlayImage
                    :media="video.closed && video.isPaid ? premiumAccessImage : pictureAccessDenied"
                    :title="video.closed && video.isPaid ? 'premium' : 'for members'"
                    :description="video.closed && video.isPaid ? 'video' : 'only'"
                    @click="handleAvatar"
                    :photo-background="siteSettings?.settings?.url_storage+profile.photo_medium"
                  />
                </template>
                <template #default-icon>
                  <img
                    class="wg-profile_media-container_media-icon"
                    src="@/assets/images/icons/play-button.svg"
                    alt="play"
                  />
                </template>
                <template #last-icon>
                  <img
                    class="wg-profile_media-container_media-icon"
                    src="@/assets/images/icons/play-button-sm.svg"
                    alt="play"
                  />
                </template>
                <template #more>
                  <p class="photo-video_media-container_media-nav">
                    <img src="@/assets/images/icons/video-media.svg" alt="videos">
                    {{ $t('profile.girlsVideos') }}
                  </p>
                  <div class="photo-video_media-container_media-holder" @click="handleAvatar">
                    <p class="photo-video_media-container_media-nav--desktop">
                      {{ $t('profile.viewMoreVideos') }}
                    </p>
                    <p class="photo-video_media-container_media-nav--mobile">
                      {{ $t('profile.moreVideos') }}
                    </p>
                    <img src="@/assets/images/icons/blue-shevron-down.svg" alt="shevron">
                  </div>
                </template>
              </WGMediaContainer>
            </div>
          </div>

          <div class="wg-profile_messaging">
            <div class="wg-profile_messaging-container">
              <WGOfferPrompt
                price="1 credit / 1 minute"
                question="Ready for instant chat?"
                title="CHAT NOW"
                @onChange="headerActionRegistration"
                :icon="chatIcon"
              />
              <WGOfferPrompt
                price="10 credits / 1 message"
                question="Want to send a letter?"
                title="SEND MESSAGE"
                @onChange="headerActionRegistration"
                :icon="messageIcon"
              />
            </div>
            <p @click="profileMessageInfo" class="wg-profile_messaging-info">
              <WGIcon class="wg-profile_messaging-info-icon" icon="wg-ffi-info-circle" />
              {{ $t('profile.explain') }}
            </p>
          </div>
          <div class="wg-profile_options">
            <div class="wg-profile_options-control">
              <WGButton
                variant="warning"
                outline
                class="wg-button-outline-warning"
                icon="wg-ffi-eye"
                size="md"
                @click="headerActionRegistration"
              >
                <template #default>
                  {{ $t('profile.wink') }}
                </template>
              </WGButton>
              <WGButton
                variant="warning"
                outline
                size="md"
                class="wg-button-outline-warning"
                icon="wg-ffi-like-fill"
                @click="headerActionRegistration"
              >
                <template #default>
                  {{ $t('profile.like') }}
                </template>
              </WGButton>
              <WGButton
                variant="warning"
                class="wg-button-outline-warning"
                outline
                size="md"
                icon="wg-ffi-star-fill"
                @click="headerActionRegistration"
              >
                <template #default>
                  {{ $t('profile.addToFavorites') }}
                </template>
              </WGButton>
            </div>
            <div class="wg-profile_options-control_gift">
              <WGButton
                icon="wg-ffi-gift-fill"
                class="text-uppercase"
                variant="error"
                size="md"
                @click="headerActionRegistration"
              >
                <template #default>
                  {{ $t('profile.sendGift') }}
                </template>
              </WGButton>
              <div class="wg-profile_options-control_gift-discount">
                -10%
              </div>
            </div>
          </div>
          <div class="wg-profile_table-description">
            <WGDescriptionFields :elements="girlPhysiquesDescription">
              <template #key="{item}">
                <span>
                  {{ item.isStaticData ? $t(item.key) : $t(`${item.key}.title`) }}
                </span>
              </template>

              <template #item="{item}">
                 <span v-if="['profile.weight', 'profile.height'].includes(item.key)">
                  {{ item.value }}
                </span>
                <span v-else-if="!item.value">
                  -
                </span>
                <span v-else>
                  {{ item.isStaticData ? $t(item.value) : $t(`${item.key}.${item.value.toLowerCase()}`) }}
                </span>
              </template>
            </WGDescriptionFields>
            <WGDescriptionFields :elements="girlSocialDescription">
              <template #key="{item}">
                <span>
                  {{ item.isStaticData ? $t(item.key) : $t(`${item.key}.title`) }}
                </span>
              </template>
              <template #item="{item}">
                 <span v-if="['profile.weight', 'profile.height'].includes(item.key)">
                  {{ item.value }}
                </span>
                <span v-else-if="!item.value">
                  -
                </span>
                <span v-else>
                  {{ item.isStaticData ? $t(item.value) : $t(`${item.key}.${item.value.toLowerCase()}`) }}
                </span>
              </template>
            </WGDescriptionFields>
          </div>
          <WGTitleDescriptionSection
            class="wg-profile_info"
            :description="getGirlDescription"
            title="profile.about"
          />
          <WGTitleDescriptionSection
            class="wg-profile_info"
            :description="getGirlDesire"
            title="profile.desire"
          />
        </div>
        <div v-else class="wg-profile--deactivated">
          <div class="wg-profile--deactivated_avatar" @click="handleAvatar">
            <img
              class="wg-profile_avatar-picture"
              :src="siteSettings?.settings?.url_storage+profile.photo_big"
              alt="avatar"
            />
          </div>
          <div class="wg-profile_name-and-options wg-profile--deactivated_options">
            <div class="wg-profile_name-and-options_name">
              <h1 class="wg-profile_name-and-options_item">
                {{ profile.name }}
              </h1>
            </div>
            <div class="wg-profile_name-and-options_holder">
              <div class="wg-profile_name-and-options_id">
                {{ $t('profile.profileID') }}&nbsp;{{ profile.id }}
              </div>
            </div>
            <div class="wg-profile_name-and-options_deactivated-fake-text">
              <img src="@/assets/images/profile/fake-txt.webp" alt="fake text">
              <p class="wg-profile_name-and-options_deactivated-fake-text_item">
                {{ $t('profile.deactivated.not_available') }}
              </p>
            </div>
          </div>
          <div class="wg-profile--deactivated_decor">
            <img
              class="wg-profile--deactivated_decor-girl"
              src="@/assets/images/profile/girl.webp"
              alt="girl with laptop"
            />
            <img
              class="wg-profile--deactivated_decor-folder"
              src="@/assets/images/profile/folder.webp"
              alt="folders in background"
            />
          </div>
        </div>
        <WGTitleMarkLetter
          class="wg-profile_girls"
          :title="girlsReactiveTitle"
        >
          <template #slotActions>
            <WGLink
              :linkHref="searchLink"
              variant="warning"
              isBold
              size="sm"
            >
              {{ $t('global.seeAll') }}
            </WGLink>
          </template>
          <template #default>
            <WGGrid
              v-if="allGirlsList"
              :dataList="allGirlsList.list.data"
              :columns="6"
              :columnsMd="3"
              :columnsSm="4"
              :columnsXs="3"
              :columnsXXs="2"
              :gap="24"
              :gapXs="16"
            >
              <template #item="{item}">
                <WGCardModel
                  class="wg-card-model-recommendation"
                  :user="item"
                  :urlStorage="siteSettings?.settings?.url_storage || null"
                />
              </template>
            </WGGrid>
            <WGPreloader v-else />
          </template>
        </WGTitleMarkLetter>
      </div>
      <WGPreloader v-if="!profile" />
    </WGContainer>
  </main>
</template>

<script>
import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useBreakpointStore } from '@/controllers/components/breakpointMixin'
import useDateWorker from '@/composable/dateWorker'
import chatIcon from '@/assets/images/icons/chat.svg'
import messageIcon from '@/assets/images/icons/message.svg'
import pictureAccessDenied from '@/assets/images/image-for-member.svg'
import premiumAccessImage from '@/assets/images/premium-image.svg'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import WGGrid from '@/components/UI/WGGrids/WGGrid.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGCardModel from '@/components/UI/WGCards/WGCardModel.vue'
import WGPreloader from '@/components/UI/WGPreloader/WGPreloader.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs.vue'
import WGOfferPrompt from '@/components/UI/WGOfferPrompt/WGOfferPrompt.vue'
import WGIndicatorOnline from '@/components/UI/WGIndicators/WGIndicatorOnline.vue'
import WGMediaContainer from '@/components/UI/WGMediaContainer/WGMediaContainer.vue'
import WGDescriptionFields from '@/components/UI/WGDescriptionFields/WGDescriptionFields.vue'
import WGTitleDescriptionSection from '@/components/UI/WGTypography/WGTitleDescriptionSection.vue'
import WGTitleMarkLetter from '@/components/UI/WGTitleMarkLetter/WGTitleMarkLetter.vue'
import WGAccessOverlayImage from '@/components/UI/WGAccessOverlayImage/WGAccessOverlayImage'

export default {
  name: 'PageProfile',
  components: {
    WGAccessOverlayImage,
    WGCardModel,
    WGGrid,
    WGLink,
    WGTitleMarkLetter,
    WGTitleDescriptionSection,
    WGDescriptionFields,
    WGButton,
    WGIcon,
    WGOfferPrompt,
    WGPreloader,
    WGMediaContainer,
    WGIndicatorOnline,
    WGBreadCrumbs,
    WGContainer
  },
  props: {
    age: String
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler (params) {
      }
    }
  },
  setup (props) {
    const route = useRoute()
    const { currentProjectLink } = inject('$projectLinks')
    const store = useStore()
    const { t } = useI18n()
    const { profilePage } = inject('$projectLinks')
    const profile = ref(null)
    const getGirlDescription = ref(null)
    const getGirlDesire = ref(null)
    let locale = store.state.locale.locale
    const { currentBreakpoint, getCurrentBreakpoint } = useBreakpointStore()
    const searchLink = '/search/online/1'
    const { getAgeByYears } = useDateWorker()

    const handleRouteChange = async () => {
      if (!route.params.id) {
        return
      }

      await store.dispatch('getGirlInfo', route.params.id)
      await store.dispatch('apiGetGirlsList', { mode: 'all', perPage: 12, page: 1 })
      profile.value = store.getters.getProfileFields
      getGirlDescription.value = profile.value['about_' + locale]
      getGirlDesire.value = profile.value['desire_' + locale]
      if (profile.value.active) {
        document.title = `
         Singles online:
         ${profile.value.name} ID-${route.params.id} - beautiful woman from
         ${profile.value.city.title_en}, ${profile.value.country.title_en}
         | Victoriyaclub.com
       `
        updateMetaDescription(profile.value.active)
      } else {
        document.title = `
         Singles online:
         ${profile.value.name} ID-${route.params.id} - beautiful woman
         | Victoriyaclub.com
       `
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    const girlsReactiveTitle = computed(() => {
      getCurrentBreakpoint()
      switch (true) {
        case ['XL', 'MD', 'LG'].includes(currentBreakpoint.value):
          return t('index.titleDeactivatedProfileDesktop')
        case ['SM', 'MD'].includes(currentBreakpoint.value):
          return t('index.titleDeactivatedProfileTablet')
        case ['XXS', 'XS'].includes(currentBreakpoint.value):
          return t('index.titleMark4')
        default:
          return t('index.titleDeactivatedProfileTablet')
      }
    })

    const updateMetaDescription = (isActive) => {
      let newDescription
      if (isActive) {
        newDescription = `Meet a beautiful mail order bride ${profile.value.name} (ID-${profile.value.id}) from ${profile.value.country.title_en} on the best dating site ${currentProjectLink} | ✅ Only verified profiles ❤️ Free registration 😍 Chat with webcam 👍 24/7 Support`
      } else {
        newDescription = `Meet a beautiful mail order bride ${profile.value.name} (ID-${profile.value.id}) on the best dating site ${currentProjectLink} | ✅ Only verified profiles ❤️ Free registration 😍 Chat with webcam 👍 24/7 Support`
      }
      const metaDescriptionTag = document.querySelector('meta[name="description"]')
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', newDescription)
      } else {
        const newMetaTag = document.createElement('meta')
        newMetaTag.setAttribute('name', 'description')
        newMetaTag.setAttribute('content', newDescription)
        document.head.appendChild(newMetaTag)
      }
    }

    const headerActionRegistration = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }

    const profileMessageInfo = () => {
      store.commit('addModal', 'MessageNChat')
    }

    watch(route, handleRouteChange, { immediate: true })
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setLocate') {
        locale = state.locale.locale
        getGirlDescription.value = profile.value['about_' + locale]
        getGirlDesire.value = profile.value['desire_' + locale]
      }
    })

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    const allGirlsList = computed(() => {
      return store.getters.getGirlsList(('all'))
    })

    const heightCalculate = computed(() => {
      const heightInCm = profile.value.reference_height
      const heightInMeters = heightInCm / 100
      const heightInFeet = heightInCm / 2.54 / 12
      const feet = Math.floor(heightInFeet)
      const inches = Math.round((heightInFeet - feet) * 12)
      return `${heightInMeters.toFixed(2)} m. (${feet}' ${inches}″)`
    })

    const weightCalculate = computed(() => {
      const weightInKg = profile.value.reference_weight
      const weightInLbs = weightInKg * 2.20462
      return `${weightInKg} kg. (${weightInLbs.toFixed(1)} lbs)`
    })

    const girlPhysiquesDescription = computed(() => {
      return [
        { key: 'profile.age', isStaticData: true, value: props.age ?? getAgeByYears(profile.value.birth) },
        { key: 'profile.country', isStaticData: true, value: profile.value.country['title_' + locale] },
        { key: 'profile.eyesColor', value: profile.value.eyes_color },
        { key: 'profile.height', isStaticData: true, value: heightCalculate },
        { key: 'profile.marital', value: profile.value.marital_status },
        { key: 'profile.physique', value: profile.value.physique },
        { key: 'profile.drinker', value: profile.value.drinker }
      ]
    })

    const girlSocialDescription = computed(() => {
      return [
        { key: 'profile.zodiac', value: profile.value.zodiac },
        { key: 'profile.city', isStaticData: true, value: profile.value.city ? profile.value.city['title_' + locale] : null },
        { key: 'profile.hairColor', value: profile.value.hair_color },
        { key: 'profile.weight', isStaticData: true, value: weightCalculate },
        { key: 'profile.children', value: profile.value.children },
        { key: 'profile.smoke', value: profile.value.smoker },
        { key: 'profile.english', value: profile.value.english }
      ]
    })

    const videos = computed(() => {
      return profile.value.videos.filter(video => !video.deleted)
    })

    const smallPhotosList = computed(() => {
      if (profile.value && profile.value.photos) {
        return profile.value.photos
          // .filter(photo => photo.approved && !photo.deleted)
          .map(photo => ({
            id: photo.id,
            url: siteSettings.value?.settings?.url_storage + photo.photo_small,
            isPaid: photo.paid && photo?.paid_approved,
            closed: photo.closed
          }))
      } else {
        return []
      }
    })
    const previewVideosList = computed(() => {
      if (profile.value && profile.value.videos) {
        return profile.value.videos.filter(video => video.approved && !video.deleted)
          .map(video => ({
            id: video.id,
            url: siteSettings.value?.settings?.url_storage + video.cover_big,
            isPaid: video.paid && video?.paid_approved,
            closed: video.closed
          }))
      } else {
        return []
      }
    })

    const handleAvatar = () => {
      headerActionRegistration()
    }

    const breadCrumbsList = computed(() => (
      [...profilePage.breadCrumbs(profile, route.params.id)]
    ))

    return {
      breadCrumbsList,
      profile,
      smallPhotosList,
      siteSettings,
      previewVideosList,
      messageIcon,
      chatIcon,
      pictureAccessDenied,
      searchLink,
      girlPhysiquesDescription,
      girlSocialDescription,
      headerActionRegistration,
      handleAvatar,
      profileMessageInfo,
      premiumAccessImage,
      getGirlDescription,
      getGirlDesire,
      locale,
      allGirlsList,
      t,
      videos,
      girlsReactiveTitle
    }
  }
}
</script>

<style scoped>

</style>
