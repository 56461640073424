<template>
  <WGContainer>
    <div class="wg-header-inner">

      <WGLogo
        v-if="$props.promotionLogo"
        :fullLogoSrc="fullLogoSrc"
        :shortLogoSrc="shortLogoSrc"
        :isMini="isLogoMini"
      />
      <WGLogo
        v-else
        :fullLogoSrc="fullLogoSrc"
        :shortLogoSrc="shortLogoSrc"
        :isMini="isLogoMini"
      />

      <WGButtonBurger
        :isMini="isBurgerMini"
        :isActive="isShowMenu"
        @click="onShowMenu"
        v-if="$props.isMini"
      >
        Menu site
      </WGButtonBurger>

      <Transition name="fade">
        <WGHeaderMenu @close="onShowMenu" :isMini="$props.isMini" v-if="isShowMenu"/>
      </Transition>

      <WGHeaderActions />

    </div>
  </WGContainer>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'

// eslint-disable-next-line no-unused-vars
import { XS, SM, MD, LG } from '@/components/UI/breakpoints.js'

import WGContainer from '@/components/UI/WGContainers/WGContainer'
import WGLogo from '@/components/UI/WGLogos/WGLogo'
import WGButtonBurger from '@/components/UI/WGButtons/WGButtonBurger'
import WGHeaderActions from './WGHeaderActions'
import WGHeaderMenu from './WGHeaderMenu'

export default {
  name: 'WGHeaderContentVictoria',
  components: {
    WGContainer,
    WGLogo,
    WGButtonBurger,
    WGHeaderActions,
    WGHeaderMenu
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    promotionLogo: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const store = useStore()

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    const getLogoSrc = (promotionLogo, urlStorage, srcKey = 'src') => {
      if (promotionLogo && promotionLogo[srcKey]) {
        return urlStorage + promotionLogo[srcKey]
      }
      return require('@/assets/images/logo-full.svg')
    }

    const fullLogoSrc = computed(() => {
      return getLogoSrc(props.promotionLogo, siteSettings.value?.settings?.url_storage)
    })

    const shortLogoSrc = computed(() => {
      return getLogoSrc(props.promotionLogo, siteSettings.value?.settings?.url_storage, 'src2')
    })

    const isBurgerMini = ref(false)
    const isLogoMini = ref(false)

    const isShowMenu = ref(!props.isMini)

    const onShowMenu = () => {
      isShowMenu.value = !isShowMenu.value
    }

    const onChangeBreakpoints = () => {
      const breakpointXS = window.matchMedia('(max-width:' + XS + 'px)')

      isBurgerMini.value = breakpointXS.matches
      isLogoMini.value = breakpointXS.matches

      breakpointXS.onchange = (e) => {
        isBurgerMini.value = e.matches
        isLogoMini.value = e.matches
      }
    }

    onMounted(() => {
      onChangeBreakpoints()
    })

    watch(() => props.isMini, (value) => {
      isShowMenu.value = !value
    })

    return {
      fullLogoSrc,
      shortLogoSrc,
      isBurgerMini,
      isShowMenu,
      isLogoMini,
      onShowMenu,
      onChangeBreakpoints
    }
  }
}
</script>
