<template>
  <WGModal model-name="MessageNChat">
    <div class="message-and-chat">
      <div class="message-and-chat_body">
          <WGCardDescriptionOptions
            class="message-and-chat_body-item-left"
            title="Instant Chat"
            :cardImage="girlChat"
            :mainIcon="chatDefaultIcon"
            description="Usually used for live short talks with smiles, stickers, video"
            :fields="chatFields"
            :buttonIcon="girlChatWarning"
            @handleChat="handleChat"
            buttonTitle="Chat Now"
          />
          <WGCardDescriptionOptions
            class="message-and-chat_body-item"
            title="Messages"
            :cardImage="girlMessage"
            :mainIcon="messageDefaultIcon"
            description="Perfect for longtime mailing, sharing stories & photos etc."
            :fields="messageFields"
            :buttonIcon="girlMessageWarning"
            buttonTitle="Send Message"
            :is-offline="true"
            @handleChat="handleChat"
            titleStatus="Offline"
          />
        <div class="message-and-chat_body-line"></div>
        <div class="message-and-chat_body-centered-title">
          Difference
        </div>
      </div>
    </div>
  </WGModal>
</template>

<script>
import { useStore } from 'vuex'
import girlChat from '@/assets/images/girl-chat.svg'
import girlMessage from '@/assets/images/girl-message.svg'
import girlChatWarning from '@/assets/images/icons/chat.svg'
import girlMessageWarning from '@/assets/images/icons/message.svg'
import chatDefaultIcon from '@/assets/images/icons/chat-default.svg'
import messageDefaultIcon from '@/assets/images/icons/message-default.svg'
import WGModal from '@/components/WGModal/WGModal.vue'
import WGCardDescriptionOptions from '@/components/UI/WGCards/WGCardDescriptionOptions.vue'
export default {
  name: 'MessageAndChat',
  components: {
    WGCardDescriptionOptions,
    WGModal
  },
  setup () {
    const store = useStore()

    const headerActionRegistration = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }
    const handleChat = () => {
      headerActionRegistration()
    }
    const handleMessage = () => {
      headerActionRegistration()
    }

    const chatFields = [
      { id: 0, image: chatDefaultIcon, title: 'Possible only with users online', description: '' },
      { id: 1, image: chatDefaultIcon, title: 'You pay for time, not for quantity', description: '(1 credit for 1 minute extra)' },
      { id: 2, image: chatDefaultIcon, title: 'You can use both text & video', description: '(1 credit for 1 minute chatting)' }
    ]

    const messageFields = [
      { id: 0, image: messageDefaultIcon, title: 'You can send it when lady is offline', description: '' },
      { id: 1, image: messageDefaultIcon, title: 'You pay for quantity, not for time', description: '(10 credits for 1 sent message)' },
      { id: 2, image: messageDefaultIcon, title: 'Attach your files to message for free', description: '(photos, pictures...)' }
    ]

    return {
      messageDefaultIcon,
      chatDefaultIcon,
      handleMessage,
      handleChat,
      chatFields,
      messageFields,
      girlMessage,
      girlMessageWarning,
      girlChatWarning,
      girlChat
    }
  }
}
</script>

<style scoped>

</style>
