import { SiteSetting } from '@/core/api/api-SiteSetting'
import { apiConfig } from '@/config'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

export default {
  state: {
    isLoading: true,
    maxYear: 50,
    siteSettings: null,
    changePassword: {}
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    getSiteSettings (state) {
      return state.siteSettings
    }
  },
  mutations: {
    setIsLoading (state, data) {
      state.isLoading = data
    },
    setLostPassParams (state, payload) {
      state.changePassword = payload
    }
  },
  actions: {
    initialize ({ dispatch }) {
      const referer = document.referrer ? document.referrer : window.location.origin
      const params = new URLSearchParams(window.location.search)
      let queryObject = {}
      if (!cookies.isKey('pid') || !cookies.isKey('clickid')) {
        for (const [key, value] of params) {
          queryObject[key] = value
        }
        if (!queryObject.pid && !queryObject.clickid) {
          queryObject = {}
        } else {
          queryObject.referer = referer
        }
      }

      return dispatch('getSiteSetting', queryObject)
    },
    getSiteSetting ({ commit, state }, query) {
      return (new SiteSetting())
        .getSiteSetting(true, { ...query, ...state.changePassword })
        .then(resp => {
          if (!resp.settings) {
            resp.settings = { url_storage: '' }
          } else if (resp.settings.api_base_path) {
            apiConfig.basePath = resp.settings.api_base_path
          }
          if (resp.settings.url_storage.indexOf('localhost') >= 0) {
            resp.settings.url_storage = '//storage.mysweetheart.link'
          }
          state.siteSettings = resp
          state.siteSettings.max_years = state.maxYear
          state.siteSettings.ages = []
          for (let i = 18; i <= state.maxYear; i++) {
            state.siteSettings.ages.push(i)
          }
          commit('setIsLoading', true)

          if (query.pid || query.clickid) {
            const emptyParams = ''
            window.history.pushState('object or string', 'Title', '/' + emptyParams)
            window.history.pushState({}, document.title, '/' + emptyParams)
          }

          return resp
        })
    }
  }
}
