
import WGIcon from '@/components/UI/WGIcons/WGIcon'

import '@/assets/styles/victoria/main.scss'

import { LIST_ICON_TYPE } from './data/listIconType'
// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGIcon',
  component: WGIcon,
  tags: ['autodocs'],
  argTypes: {
    icon: {
      control: {
        type: 'select'
      },
      options: LIST_ICON_TYPE
    }
  }
}

export const Docs = {
  args: {
    icon: 'wg-ffi-webcam-fill'
  }
}
