const infoPages = [
  { path: '/our-dating-site/our-dating-site', name: 'OurDatingSite', pageId: 1 },
  { path: '/our-dating-site/license-agreement', name: 'LicenseAgreement', pageId: 2, seo: { title: 'License Agreement | VictoriyaClub.com', md: 'Explore License Agreement to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/about-us/contact-us', name: 'ContactUs', pageId: 3 },
  { path: '/faq-test', name: 'Faq', pageId: 4 },
  { path: '/about-us/anti-scam-policy', name: 'AntiScamPolicy', pageId: 5, seo: { title: 'Anti-Scam Policy | VictoriyaClub.com', md: 'Explore the Anti-Scam Policy to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/about-us/affiliate-program', name: 'AffiliateProgram', pageId: 6 },
  { path: '/about-us/dating-agencies', name: 'DatingAgencies', pageId: 7 },
  { path: '/about-us/about-us', name: 'AboutUs', pageId: 8 },
  { path: '/about-us/who-we-are', name: 'WhoWeAre', pageId: 9, seo: { title: 'Who We Are | VictoriyaClub.com', md: 'Explore Who we are to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/about-us/how-it-works', name: 'HowItWorks', pageId: 10 },
  { path: '/about-us/tips-and-advice', name: 'TipsAndAdvice', pageId: 11 },
  { path: '/about-us/trip-to-bride', name: 'TripToBride', pageId: 12 },
  { path: '/about-us/pretty-russian-girl', name: 'PrettyRussianGirl', pageId: 13 },
  { path: '/about-us/dating-ukrainian-girls', name: 'DatingUkrainianGirls', pageId: 14 },
  { path: '/about-us/about-ukrainian-girls', name: 'AboutUkrainianGirls', pageId: 15 },
  { path: '/about-us/testimonials', name: 'Testimonials', pageId: 16 },
  { path: '/about-us/hear-the-bride', name: 'HearTheBride', pageId: 17 },
  { path: '/about-us/recognize-a-scammer', name: 'RecognizeAScammer', pageId: 18, seo: { title: 'Recognize a Scammer | Victoriyaclub.com', md: 'Find out how to recognize a scammer to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/about-us/better-agency', name: 'BetterAgency', pageId: 19 },
  { path: '/our-dating-site/terms-and-conditions', name: 'TermsAndConditions', pageId: 20, seo: { title: 'Terms and Conditions | VictoriyaClub.com', md: 'Explore Terms and Conditions to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/our-dating-site/assistance', name: 'Assistance', pageId: 22, seo: { title: 'Online Dating and Assistance 💌 | VictoriyaClub', md: 'Want 🤩to meet our brides in real life? Want to come and date with one of beautiful single girls? No problem 👌! We can assist you with it!' } },
  { path: '/our-dating-site/system', name: 'System', pageId: 23 },
  { path: '/our-dating-site/payment_error', name: 'PaymentError', pageId: 24 },
  { path: '/our-dating-site/cancellation-policy', name: 'CancellationPolicy', pageId: 26, seo: { title: 'Cancellation Policy | VictoriyaClub.com', md: 'Explore the Cancellation Policy to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/our-dating-site/privacy-policy', name: 'PrivacyPolicy', pageId: 27 },
  { path: '/our-dating-site/privacy-policy-new', name: 'PrivacyPolicyNew', pageId: 28, seo: { title: 'Privacy Policy | Victoriyaclub.com', md: 'Explore the Privacy Policy to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/our-dating-site/cookie-policy', name: 'CookiePolicy', pageId: 29, seo: { title: 'Cookie Policy | Victoriyaclub.com', md: 'Explore the Cookie Policy to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/our-dating-site/acceptable-use-policy', name: 'AcceptableUsePolicy', pageId: 30, seo: { title: ' Acceptable Use Policy | Victoriyaclub.com', md: 'Explore the Acceptable Use Policy to navigate the Victoriyaclub dating site safely 24/7' } },
  { path: '/our-dating-site/complaint-and-content-removal-policy--procedures', name: 'ComplaintAndContentRemoval', pageId: 32 },
  { path: '/our-dating-site/consumer-age-verification-policy', name: 'ConsumerAgeVerification', pageId: 33 },
  { path: '/our-dating-site/content-management-policy--procedures', name: 'ContentManagementPolicy', pageId: 34 }
]

export default infoPages
