<template>
  <dl
    class="wg-list-definition"
    v-if="list && list.length"
  >

    <template v-for="(item, index) in list" :key="index">

      <dt class="wg-list-definition-dt">
        {{ item.dt }}
      </dt>

      <dd class="wg-list-definition-dd">
        {{ item.dd }}
      </dd>

    </template>

  </dl>
</template>

<script>

export default {
  name: 'WGListDefinition',
  props: {
    list: {
      type: Array,
      default: () => [] // dt dd
    }
  }
}
</script>
