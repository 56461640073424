<template>
  <div class="wg-title-mark-letter-box" :class="className">

    <div class="wg-title-mark-letter-head">

      <div :class="titleClass" role="heading" v-if="$slots.slotTitle || $props.title">
        <slot name="slotTitle">
          <h2 class="wg-title-mark-letter_headline">{{ $props.title }}</h2>
        </slot>
      </div>

      <div class="wg-title-mark-letter-actions" v-if="$slots.slotActions">
        <slot name="slotActions"></slot>
      </div>

      <div class="wg-title-mark-letter-tooltip" v-if="$slots.slotTooltip">
        <slot name="slotTooltip"></slot>
      </div>

    </div>

    <div class="wg-title-mark-letter-content" v-if="$slots.default">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import { computed } from 'vue'
// ROLE html docs: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles
export default {
  name: 'WGTitleMarkLetter',
  props: {
    title: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      required: false,
      default: 'success',
      validator (value) {
        return ['success', 'primary'].includes(value)
      }
    },
    titleClass: {
      type: String,
      default: 'wg-title-mark-letter'
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['wg-title-mark-letter-' + props.variant]: props.variant
      }
    })

    return {
      className
    }
  }
}
</script>
