import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: () => import(/* webpackChunkName: "PageHome" */'@/components/pages/PageHome.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.name) {
    window.location.assign(to.fullPath)
    return
  }
  next()
})

export default router
