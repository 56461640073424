import '@/assets/styles/victoria/main.scss'

import WGIndicatorCamera from '@/components/UI/WGIndicators/WGIndicatorCamera.vue'

export default {
  title: 'Example/WGIndicatorCamera',
  component: WGIndicatorCamera,
  tags: ['autodocs']
}

export const Docs = {
  args: {
  }
}
