<script>
import { defineComponent } from 'vue'
import WGField from '@/components/UI/WGForms/WGField.vue'

const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export default defineComponent({
  name: 'WGFieldEmail',
  components: {
    WGField
  },
  emits: ['update:modelValue', 'update:invalidText'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    invalidText: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: 'example@mail.com'
    },
    iconLeft: {
      type: String,
      default: 'wg-ffi-sms-fill'
    }

  },
  data () {
    return {
      isInvalid: false,
      defaultInvalidText: ''
    }
  },
  methods: {
    updateModelValue (e) {
      this.$emit('update:modelValue', e)
      this.isInvalid = !regexEmail.test(e)
      this.defaultInvalidText = this.isInvalid ? this.$t('invalid.email') : ''
      this.$emit('update:invalidText', this.defaultInvalidText)
    }
  }
})
</script>

<template>
  <WGField
    type="email"
    :placeholder="$props.placeholder"
    :iconLeft="$props.iconLeft"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :isInvalid ="isInvalid"
    auto-complete="on"
    ariaAutocomplete="list"
    :invalidText="$props.invalidText || defaultInvalidText"
  />
</template>
