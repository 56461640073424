<template>
  <div>
    <p>There are two types of messages: 1) your dialogs and 2) messages sent to you, to which you did not reply, and ways of deleting differs depending on the type.</p>
    <br>
    <b>I. How to delete my dialogs?</b>
    <br>
    <br>
    <p>In your dialogs you can delete messages from ladies to you. There is no option to delete your messages in dialogs since this is a charged service and such messages are kept for records.</p>
    <br>
    <p>To delete messages from ladies in your dialogs make the following steps:</p>
    <p>1. Press ‘Messages’ button in the top right corner to go to your messages screen.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/1.How_can_I_delete_messages_send_to_me_.png" alt="">
      <img src="@/assets/images/faq/1.How_can_I_delete_messages_send_to_me_(mobile).png" alt="">
    </div>
    <br>
    <p>2. On your messages screen switch to ‘Show only dialogs with me’.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/2.How_can_I_delete_messages_send_to_me_.png" alt="">
      <img src="@/assets/images/faq/2.How_can_I_delete_messages_send_to_me_(mobile).png" alt="">
    </div>
    <br>
    <p>3. Open the desired dialog and press ‘[delete this message]’ under a message from a lady you want to delete.</p>
    <p>4. On a pop up window appeared ‘Delete this message’ press ‘OK’.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/3.How_to_delete_my_dialogs_.png" alt="">
      <img src="@/assets/images/faq/3.How_to_delete_my_dialogs_(mobile).png" alt="">
    </div>
    <br>
    <b>II. How can I delete messages send to me?</b>
    <p>If you want to delete messages sent to you (if there are no your replies) please make the following steps:</p>
    <p>1. Press ‘Messages’ button in the top right corner to go to your messages screen.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/1.How_can_I_delete_messages_send_to_me_.png" alt="">
      <img src="@/assets/images/faq/1.How_can_I_delete_messages_send_to_me_(mobile).png" alt="">
    </div>
    <br>
    <p>2. Make sure it is switched to ‘Show absolutely all messages’.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/2.How_can_I_delete_messages_send_to_me_.png" alt="">
      <img src="@/assets/images/faq/2.How_can_I_delete_messages_send_to_me_(mobile).png" alt="">
    </div>
    <br>
    <p>3. Press [delete this dialog] on the dialog you want to delete.</p>
    <p>4. On a pop up window appeared ‘Delete the subject and all messages in it?’ press ‘OK’.</p>
    <br>
    <div class="wg-delete-messages_images">
      <img src="@/assets/images/faq/3.How_to_delete_my_dialogs_.png" alt="">
      <img src="@/assets/images/faq/3.How_to_delete_my_dialogs_(mobile).png" alt="">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>
