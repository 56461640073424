
import WGListBecomeAccordion from '@/components/UI/WGListBecomeAccordion/WGListBecomeAccordion.vue'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGListBecomeAccordion',
  component: WGListBecomeAccordion,
  tags: ['autodocs']
}
export const Docs = {
  args: {
    list: [
      {
        linkHref: '#',
        linkTitle: 'First item'
      },
      {
        linkHref: '#',
        linkTitle: 'Second item'
      },
      {
        linkHref: '#',
        linkTitle: 'Third item'
      },
      {
        linkHref: '#',
        linkTitle: 'Fourth item'
      },
      {
        linkHref: '#',
        linkTitle: 'Fifth item'
      }],
    title: 'Our dating site'
  }
}
