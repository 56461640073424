import { API } from './API'

export class GirlsList {
  Api;
  endpoint = 'girls_list';

  constructor () {
    this.Api = new API()
  }

  getGirlsList (mode, unAuth = false, params) {
    let endpoint = ''
    if (unAuth) endpoint = '/un_auth'
    const url = `${endpoint}/${this.endpoint}?mode=` + mode
    return this.Api.getM(url, params)
  }
}
