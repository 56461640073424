import '@/assets/styles/victoria/main.scss'

import WGCardModel from '@/components/UI/WGCards/WGCardModel.vue'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction

export default {
  title: 'Example/WGCardModel',
  component: WGCardModel,
  tags: ['autodocs'],
  argTypes: {
  }
}

export const lg = {
  args: {
    user: {
      name: 'Kate',
      years: '25',
      id: '912370',
      img: require('@/assets/images/girlPhoto.png'),
      online: true,
      camera: true
    }
  }
}
