<template>
  <div class="wg-color-element">
    <div class="wg-color-element-container" :style="`background-color: ${color}`">
      <div
        :class="{'wg-color-element-container_dot--active': isActive}"
        class="wg-color-element-container_dot"
      >
      </div>
    </div>
    <p class="wg-color-element_title" :class="{'wg-color-element_title--active': isActive}">
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'WGColorElement',
  props: {
    isActive: Boolean,
    title: String,
    color: String
  }
}
</script>
