
import WGLogo from '@/components/UI/WGLogos/WGLogo'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGLogo',
  component: WGLogo,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    fullLogoSrc: require('@/assets/images/logo-full.svg'),
    shortLogoSrc: require('@/assets/images/logo-short.svg')
  }
}
