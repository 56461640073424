<template>
  <main class="page-main page-registration-for-woman">

    <WGContainer is-clear-offsets>
      <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
    </WGContainer>

    <WGContainer class="bg-white">

      <WGContentWithAside>

        <template #section>

          <h1 class="page-registration-for-woman-heading title-1 text-green-700">
            <strong>
              {{ $t('PageRegistrationWoman.mainTitle') }}
            </strong>
          </h1>

          <i18n-t keypath="PageRegistrationWoman.mainText" tag="p" scope="global" class="page-registration-for-woman-desc">
            <template #actions1>
              {{ siteUrl }}
            </template>
            <template #actions2>
              {{ siteUrl }}
            </template>
          </i18n-t>

          <WGRegistrationForWomanForm />

        </template>

        <template #aside>

          <WGLoveStoryBanner />

        </template>

      </WGContentWithAside>

    </WGContainer>

  </main>

</template>

<script>
import { ref, defineComponent, inject } from 'vue'

import { SITE_URL } from '@/settings/links'

import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGContentWithAside from '@/components/UI/WGContentWithAside/WGContentWithAside.vue'
import WGRegistrationForWomanForm from '@/components/auth/WGRegistrationForWomanForm.vue'
import WGLoveStoryBanner from '@/components/UI/WGAsideBanners/WGLoveStoryBanner.vue'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs.vue'
export default defineComponent({
  name: 'PageRegistrationForWoman',
  components: {
    WGBreadCrumbs,
    WGLoveStoryBanner,
    WGRegistrationForWomanForm,
    WGContentWithAside,
    WGContainer
  },
  setup () {
    const siteUrl = SITE_URL
    const { currentProjectLink } = inject('$projectLinks')
    document.title = `Registration for Women |  ${currentProjectLink}`
    const newDescription = 'Create account on Victoriyaclub dating site and meet your destiny without going out. Take advantage of  ✅ simple registration ✅cheap dating services  ✅ real members.'
    const metaDescriptionTag = document.querySelector('meta[name="description"]')

    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', newDescription)
    } else {
      const newMetaTag = document.createElement('meta')
      newMetaTag.setAttribute('name', 'description')
      newMetaTag.setAttribute('content', newDescription)
      document.head.appendChild(newMetaTag)
    }

    const breadCrumbsList = ref([
      { label: 'Home', url: '/' },
      { label: 'Services' }
    ])

    return {
      siteUrl,
      breadCrumbsList
    }
  }
})
</script>
