import '@/assets/styles/victoria/main.scss'

import WGCardActions from '@/components/UI/WGCards/WGCardActions.vue'

export default {
  title: 'Example/WGCardActions',
  component: WGCardActions,
  tags: ['autodocs']
}

export const Docs = {
  args: {
  }
}
