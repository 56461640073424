let config = null
switch (process.env.VUE_APP_VERSION) {
  case 'victoria':
    config = {
      apiVersion: '/vc-api',
      urlStorage: '//storage.victoriya.club'
    }
    break
  case 'loveinchat':
    config = {
      apiVersion: '/lc-api',
      urlStorage: '//storage.loveinchat.com'
    }
    break
  case 'victoriamain':
    config = {
      apiVersion: '/vc-api',
      urlStorage: '//storage.victoriyaclub.com'
    }
    break
}
config.basePath = window.location.origin.includes('localhost') ? process.env.VUE_APP_REQUEST : window.location.origin

module.exports = {
  apiConfig: config
}
