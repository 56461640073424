<template>
  <div class="wg-date-picker">
    <VueDatePicker
      :range="isRange"
      :hide-navigation="['month', 'year', 'calendar', 'time', 'minutes', 'hours', 'seconds']"
      :yearPicker="yearPicker"
      :enable-time-picker="false"
      :max-date="maxDate"
      :modelValue="modelValue"
      @update:modelValue="(e)=>{$emit('update:modelValue', e)}"
    >
      <template #dp-input="{value}">
        <WGField
          :isReadonly="true"
          :modelValue="value"
          required
          :isDisabled="disabled"
          iconLeft="wg-ffi-note-text-fill"
          :placeholder="placeholder"
        >
        </WGField>
      </template>

    </VueDatePicker>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import WGField from '@/components/UI/WGForms/WGField.vue'

export default {
  name: 'WGDatePicker',
  components: {
    WGField,
    VueDatePicker
  },
  props: {
    modelValue: [String, Array, Date, Number],
    isRange: {
      type: Boolean,
      default: false
    },
    yearPicker: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxDate: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
