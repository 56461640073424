import { API } from './API'

export class OurBlog {
  Api;
  endpoint = 'our_blog';

  constructor () {
    this.Api = new API()
  }

  getOurBlog (unAuth = false) {
    let endpoint = ''
    if (unAuth) endpoint = '/un_auth'
    const url = `${endpoint}/${this.endpoint}`
    return this.Api.getM(url)
  }
}
