
import WGList from '@/components/UI/WGLists/WGList'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGList',
  component: WGList,
  tags: ['autodocs'],
  argTypes: {
    orientations: {
      control: 'select',
      options: ['vertical', 'horizontal']
    },
    variant: {
      control: 'select',
      options: ['none', 'ordered-zero']
    }
  }
}

export const Docs = {
  args: {
    list: ['First item', 'Second item', 'Third item', 'Fourth item', 'Fifth item'],
    orientations: 'vertical'
  }
}
