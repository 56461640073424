<template>

  <div class="wg-sign-up-form-wrap">

    <WGLabel class="text-capitalize">
      {{ $t('global.forms.labelName') }}
    </WGLabel>

    <WGFieldStandard v-model="userName" v-model:invalidText="invalidUserName" :placeholder="$t('global.forms.placeholderName')" iconLeft="wg-ffi-profile-fill"></WGFieldStandard>

    <WGLabel>
      {{ $t('global.forms.labelEmail') }}
    </WGLabel>

    <div class="wg-sign-up-form-wrap_email-hint" ref="node">
      <WGFieldEmail
        v-model="userEMail"
        v-model:invalidText="invalidUserEmail"
        ref="emailField"
        :placeholder="$t('global.forms.placeholderEmail')"
      />
      <div class="wg-sign-up-form-wrap_hint" v-show="isEmailHint">
        <WGList
          :list="emailHints"
          orientations="horizontal"
          class="wg-sign-up-form-wrap_hint-list"
        >
          <template #item="{ item }">
            <span @click="choiceHint(item.name)" class="wg-sign-up-form-wrap_hint-item">
              {{ item.name }}
            </span>
          </template>
        </WGList>
      </div>
    </div>

    <WGButtons :disabled="invalidData" full @click.prevent="registration">{{ $t('global.createAccount2') }}</WGButtons>

    <WGCheckbox
      class="wg-sign-up-form-wrap_agreements"
      :class="{'agreements-error': isAgreementsError}"
      :labelFor="'checkboxAcceptTerms' + updateIdForTerms"
      v-model="isAcceptTerms"
    >

      <i18n-t keypath="global.useRights" tag="span" scope="global">

        <template #actions1>
          <WGLink
            type="button"
            variant="primary"
            isFontSizeInherit
            @click="openModal('TermsUserAgreement')"
          >
            {{ $t('global.agreement') }}
          </WGLink>
        </template>

        <template #actions2>
          <WGLink
            type="button"
            variant="primary"
            isFontSizeInherit
            @click="openModal('TermsAndConditions')"
          >
            {{ $t('global.conditions') }}
          </WGLink>
        </template>

        <template #actions3>
          <WGLink
            type="button"
            variant="primary"
            isFontSizeInherit
            @click="openModal('PrivacyPolicy')"
          >
            {{ $t('global.policy') }}
          </WGLink>
        </template>
      </i18n-t>
      <span
        v-show="isAgreementsError"
        class="agreements-error_description">
          {{ $t('global.errorAgree') }}
        </span>
    </WGCheckbox>

    <WGBoxLines class="text-normal-small text-nepal-400">
      {{ $t('formAuth.titleLine') }}
    </WGBoxLines>

    <WGPromptInfo
      icon="wg-ffi-girl"
      class="wg-sign-up-form-wrap_prompt"
      :title="$t('formAuth.notRegistered')"
    >
      <template #descriptionSlot>
        <WGLink type="router" variant="primary" linkHref="/registration-girl" isFontSizeInherit>
          {{ $t('formAuth.toRegister') }}
        </WGLink>
      </template>
    </WGPromptInfo>

  </div>

</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import WGList from '@/components/UI/WGLists/WGList.vue'
import WGLabel from '@/components/UI/WGForms/WGLabel.vue'
import WGButtons from '@/components/UI/WGButtons/WGButton.vue'
import WGCheckbox from '@/components/UI/WGForms/WGCheckbox.vue'
import WGBoxLines from '@/components/UI/WGBoxLines/WGBoxLines.vue'
import WGFieldEmail from '@/components/UI/WGForms/WGFieldEmail.vue'
import WGPromptInfo from '@/components/UI/WGPromptInfo/WGPromptInfo.vue'
import WGFieldStandard from '@/components/UI/WGForms/WGFieldStandard.vue'

export default {
  name: 'WGSignUpForm',
  components: {
    WGList,
    WGFieldStandard,
    WGFieldEmail,
    WGBoxLines,
    WGButtons,
    WGLabel,
    WGPromptInfo,
    WGCheckbox,
    WGLink
  },
  setup () {
    const store = useStore()
    const userName = ref('')
    const userEMail = ref('')
    const isAcceptTerms = ref(false)
    const isAgreementsError = ref(false)
    const invalidUserName = ref('')
    const invalidUserEmail = ref('')
    const updateIdForTerms = Math.floor(1000000 + Math.random() * 9000000).toString()
    const isClickedOutside = ref(false)
    const node = ref(null)
    const emailField = ref(null)

    const isModal = computed(() => {
      return store.state.modals.modalList.includes('VictoriaModalAuth')
    })

    const handleClickOutside = (event) => {
      isClickedOutside.value = node.value && !node.value.contains(event.target)
    }

    onMounted(() => {
      window.addEventListener('click', handleClickOutside)
    })

    onUnmounted(() => {
      window.removeEventListener('click', handleClickOutside)
    })

    const invalidData = computed(() => !(userName.value.length >= 2 && userEMail.value && !invalidUserName.value && !invalidUserEmail.value))

    const registration = () => {
      if (!isAcceptTerms.value) {
        isAgreementsError.value = !isAcceptTerms.value
        return
      }

      store.dispatch('apiRegistration', {
        name: userName.value,
        email: userEMail.value,
        signAgree: isAcceptTerms.value ? 'on' : 'off'
      })
    }

    const choiceHint = (name) => {
      userEMail.value = userEMail.value + name
      emailField.value.updateModelValue(userEMail.value)
    }

    const isEmailHint = computed(() => {
      return userEMail.value.includes('@') &&
        userEMail.value.charAt(userEMail.value.length - 1) === '@' &&
        (!isClickedOutside.value || isModal.value)
    })

    const emailHints = [
      { id: 1, name: 'gmail.com' },
      { id: 2, name: 'yahoo.com' },
      { id: 3, name: 'hotmail.com' },
      { id: 4, name: 'outlook.com' },
      { id: 5, name: 'icloud.com' },
      { id: 6, name: 'aol.com' },
      { id: 7, name: 'live.com' },
      { id: 8, name: 'mail.com' }
    ]

    const openModal = (modalName) => {
      store.commit('addModal', modalName)
      switch (modalName) {
        case 'TermsUserAgreement':
          store.dispatch('apiGetPageText', 'license-agreement')
          break
        case 'TermsAndConditions':
          store.dispatch('apiGetPageText', 'terms-and-conditions')
          break
        case 'PrivacyPolicy':
          store.dispatch('apiGetPageText', 'privacy-policy')
          break
      }
    }
    return {
      userName,
      userEMail,
      isAcceptTerms,
      isAgreementsError,
      emailField,
      invalidUserName,
      invalidUserEmail,
      invalidData,
      updateIdForTerms,
      isEmailHint,
      emailHints,
      node,
      isClickedOutside,
      choiceHint,
      registration,
      openModal
    }
  }
}
</script>
