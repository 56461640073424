import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/victoriamain.js'
import infoPages from '@/router/info-pages'
import linksForFAQ from '@/router/faq-pages'
import canonicalLinks from '@/controllers/SEO/canonicalLinks.js'

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: () => import(/* webpackChunkName: "PageHome" */'@/components/pages/PageHome.vue')
  },
  {
    path: '/registration-girl',
    name: 'PageRegistrationForWoman',
    component: () => import(/* webpackChunkName: "PageRegistrationForWoman" */'@/components/pages/PageRegistrationForWoman.vue')
  },
  {
    path: '/registration',
    name: 'PageRegistrationForMan',
    component: () => import(/* webpackChunkName: "PageRegistrationForMan" */'@/components/pages/PageRegistrationForMan.vue')
  },
  {
    path: '/our-dating-site/our-services',
    name: 'PageService',
    component: () => import(/* webpackChunkName: "PageService" */'@/components/pages/PageService.vue')
  },
  {
    path: '/search/:pathMatch(.*)*',
    name: 'SearchGirls',
    component: () => import(/* webpackChunkName: "SearchGirls" */ '@/components/pages/SearchGirls.vue')
  },
  {
    path: '/:name-ID-:id-:age-years-old',
    name: 'PageProfile',
    component: () => import(/* webpackChunkName: "PageProfile" */ '@/components/pages/PageProfile.vue'),
    props: true
  },
  {
    path: '/:name-ID-:id-:age-let',
    name: 'PageProfileRu',
    component: () => import(/* webpackChunkName: "PageProfile" */ '@/components/pages/PageProfile.vue'),
    props: true
  },
  {
    path: '/all-brides/ukrainian-brides/date-single-:username-id-:id',
    name: 'UkrainianDateSingleProfile',
    component: () => import(/* webpackChunkName: "PageProfile" */ '@/components/pages/PageProfile.vue'),
    props: true
  },
  {
    path: '/all-brides/:country-brides/meet-bride-online-:username-id-:id',
    name: 'MeetBrideOnlineProfile',
    component: () => import(/* webpackChunkName: "PageProfile" */ '@/components/pages/PageProfile.vue'),
    props: true
  },
  {
    path: '/faq',
    name: 'PageFAQ',
    component: () => import(/* webpackChunkName: "PageFAQ" */ '@/components/pages/PageFAQ.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'WGSupportCenter',
        component: () => import('@/components/UI/WGFAQ/WGSupportCenter.vue')
      },
      ...linksForFAQ.map((link) => ({
        path: link.title.replace(/\s+/g, '-').replace(/[?,.]/g, '').toLowerCase(),
        name: link.componentName,
        component: () => import(`@/components/UI/WGFAQ/${link.componentName}.vue`),
        props: { link }
      }))
    ]
  }
]

infoPages.forEach(page => {
  routes.push({
    path: `${page.path}`,
    name: `InformationPage${page.name}`,
    props: {
      pageId: page.pageId,
      seo: page.seo
    },
    component: () => import(/* webpackChunkName: "InformationPage" */ '@/components/pages/PageInformation.vue')
  })
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('removeAllModal')
  const lostPassAction = to.query.action === 'lost_pass'
  const email = to.query.email
  const key = to.query.key
  const action = to.query.action

  if (lostPassAction && email && key) {
    store.commit('setLostPassParams', { email, key, action })
    store.dispatch('initialize').then(response => {
      if (response.lost_pass) {
        store.commit('addModal', 'VictoriaModalChangePassword')
      }
    })
  }
  if (!to.name) {
    window.location.assign(to.fullPath)
    return
  }
  next()
})

router.afterEach((to, from) => {
  canonicalLinks()
})

export default router
