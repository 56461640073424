<template>
  <main class="page-main">
    <WGContainer is-clear-offsets>
      <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
    </WGContainer>
    <WGContainer class="bg-white wg-faq">
      <h1 class="wg-page-faq_title title-1">Frequently Asked Questions</h1>
      <div class="wg-page-faq">
        <div class="wg-page-faq_left-slides">
          <div
            v-for="link of linksFAQ"
            :key="link.id"
            class="wg-page-faq_left-slide"
            :class="{ 'wg-page-faq_left-slide--active': link.id === activeItem.id}"
            @click="activeItem = link"
          >
            {{ link[`name_${locale.locale}`] || link[`name_en`] }}
          </div>
        </div>
        <div class="wg-page-faq_right-view" v-html="activeItem[`text_${locale.locale}`] || activeItem['text_en']">
        </div>
      </div>
    </WGContainer>
  </main>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import WGContainer from '@/components/UI/WGContainers/WGContainer'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs'

export default {
  name: 'PageFAQ',
  components: { WGBreadCrumbs, WGContainer },
  props: {
    link: Object
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const activeItem = ref({})
    const alreadyExecuted = ref(false)

    document.title = 'Frequently Asked Questions About Online Dating Services 😊👋 | VictoriyaClub'
    const newDescription = 'Do you have questions about dating website and foreign brides? Here you can find all the necessary information👍!'
    const metaDescriptionTag = document.querySelector('meta[name="description"]')
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', newDescription)
    } else {
      const newMetaTag = document.createElement('meta')
      newMetaTag.setAttribute('name', 'description')
      newMetaTag.setAttribute('content', newDescription)
      document.head.appendChild(newMetaTag)
    }

    store.dispatch('apiGetFaqPages')

    const breadCrumbsList = ref(computed(() => [
      { label: 'breadCrumbs.home', url: '/' },
      { label: 'FAQ', isNotI18n: true }
    ]))
    const locale = computed(() => {
      return store.state.locale
    })
    const linksFAQ = computed(() => {
      return store.getters.getFaqPages
    })

    onMounted(() => {
      if (linksFAQ.value.length > 0) {
        activeItem.value = linksFAQ.value[0]
        alreadyExecuted.value = true
      }
    })

    watchEffect(() => {
      if (!alreadyExecuted.value && linksFAQ.value.length > 0) {
        activeItem.value = linksFAQ.value[0]
        alreadyExecuted.value = true
      }
    })

    return {
      breadCrumbsList,
      locale,
      linksFAQ,
      activeItem,
      route
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/common/abstracts/_variables.scss';
@import '@/assets/styles/common/UI/faq-pages.scss';

</style>
