<template>
  <div
    class="wg-checkbox-box"
    :class="[className, { 'is-disabled': disabled }]"
  >
    <input
      type="checkbox"
      class="wg-checkbox-field"
      hidden
      :disabled="disabled"
      :id="labelFor"
      :value="value"
      :true-value="trueValue"
      :false-value="falseValue"
      :required="required"
      v-model="model"
    />
    <label class="wg-checkbox-label" :for="labelFor">
      <span v-show="!isHiddenCheckMark" class="wg-checkbox-check-mark"></span>
      <span v-if="(hasLabel || $slots.default) && isClickableLabel" class="wg-checkbox-txt">
        <slot :contentName="label">
          {{ label }}
        </slot>
      </span>
    </label>
    <span v-if="(hasLabel || $slots.default) && !isClickableLabel" class="wg-checkbox-txt">
      <slot :contentName="label">
        {{ label }}
      </slot>
    </span>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'WGCheckbox',
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    name: String,
    labelFor: String,
    label: String,
    value: {},
    required: Boolean,
    disabled: Boolean,
    variant: {
      type: String,
      default: 'default',
      validator: prop => ['default', 'toggle'].includes(prop)
    },
    isTextLeft: Boolean,
    isClickableLabel: Boolean,
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    isHiddenCheckMark: Boolean
  },
  setup: function (props, { emit }) {
    const model = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    const className = computed(() => ({
      ['wg-checkbox-' + props.variant]: props.variant,
      'is-text-left': props.isTextLeft
    }))
    const hasLabel = computed(() => Boolean(props.label))

    return {
      className,
      model,
      hasLabel
    }
  }
}
</script>
