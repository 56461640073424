<template>
  <div class="wg-promotion-banner">
    <img
      class="wg-promotion-banner_instance"
      :src="urlStorage + $props.banner.src"
      alt="banner"
    />
  </div>
</template>

<script>
export default {
  name: 'WGPromotionBanner',
  props: {
    banner: Object,
    urlStorage: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.wg-promotion-banner {
  &_instance {
    width: 100%;
  }
}
</style>
