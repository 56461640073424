<template>

  <div class="sb-preview-icons-wrap">

    <div class="sb-preview-icon-box" v-for="(icon, index) in icons" :key="index">

      <WGIcon
        :icon="icon"
      />

      <div class="sb-preview-icon-name">
        <strong>{{ icon }}</strong>
      </div>

      <button type="button" title="Copy / Копіювати" class="sb-preview-icon-btn" @click="copy(icon)" >
        Copy / Копіювати
      </button>

    </div>

  </div>

</template>

<script>

import '@/assets/styles/victoria/main.scss'
import './icons.css'

import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

import { LIST_ICON_TYPE } from './data/listIconType'

export default {
  name: 'WGIconAll',
  components: {
    WGIcon
  },
  setup () {
    const icons = LIST_ICON_TYPE

    const copy = async (e) => {
      try {
        await navigator.clipboard.writeText(e)

        alert(e + ' Copied ! ')
      } catch ($e) {
        console.log('Cannot copy')
      }
    }

    return {
      icons,
      copy
    }
  }
}
</script>
