
import '@/assets/styles/victoria/main.scss'

import WGLabel from '@/components/UI/WGForms/WGLabel'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGLabel',
  component: WGLabel,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    default: 'Label'
  }
}
