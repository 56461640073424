
import WGAccordion from '@/components/UI/WGAccordions/WGAccordion'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction

export default {
  title: 'Example/WGAccordions',
  component: WGAccordion,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    title: 'Our dating site',
    isAccordion: true,
    accordionValue: 5,
    modelValue: 6,
    accordionGroup: 'storybook',
    content: 'Lorem ipsum dolor sit amet.'
  }
}
