
import { ref } from 'vue'

import '@/assets/styles/victoria/main.scss'

import WGCheckbox from '@/components/UI/WGForms/WGCheckbox'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGCheckbox',
  component: WGCheckbox,
  tags: ['autodocs'],
  argTypes: {
    variant: {
      control: {
        type: 'select'
      },
      options: ['default', 'toggle']
    }
  }
}

// Docs: https://storybook.js.org/blog/storybook-vue3/

const Template1 = (args) => ({
  // Components used in your story `template` are defined in the `components` object
  components: { WGCheckbox },
  // The story's `args` need to be mapped into the template through the `setup()` method
  setup () {
    const listData = ref([
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      },
      {
        labelFor: 'id-',
        label: 'label-',
        value: 'lorem-',
        name: 'radio'
      }
    ])

    const selectedValues = ref([])

    return { args, listData, selectedValues }
  },
  // And then the `args` are bound to your component with `v-bind="args"`
  template: '<WGCheckbox v-for="(item, index) in listData" :key="item" :labelFor="item.labelFor + index" :label="item.label + index" :value="item.value + index" :name="item.name" v-model="selectedValues"></WGCheckbox>{{ selectedValues }}'
})

export const CheckboxWithArray = Template1.bind({})

const Template2 = (args) => ({
  // Components used in your story `template` are defined in the `components` object
  components: { WGCheckbox },
  setup () {
    const checkboxData = ref(false)

    return {
      args,
      checkboxData
    }
  },
  template: '<WGCheckbox v-bind="args" label="label" labelFor="checkbox" value="lorem" v-model="checkboxData"/>{{ checkboxData }}'
})

export const Docs = Template2.bind({})
