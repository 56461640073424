<template>
  <div class="wg-card-description-options">
    <div class="wg-card-description-options_header-item">
      <img :src="mainIcon" alt="chat-icon">
      <p>{{ title }}</p>
    </div>
    <div class="wg-card-description-options_body-content">
      <h5 class="wg-card-description-options_body-headline">
        {{ description }}
      </h5>
      <div class="wg-card-description-options_body-container">
        <div class="wg-card-description-options_body-image">
          <img
            class="wg-card-description-options_body-image_item"
            :src="cardImage"
            alt="girl-chat"
          />
          <WGIndicatorOnline
            class="wg-card-description-options_body-image_status"
            :titleStatus="titleStatus"
            :is-offline="isOffline"
          />
        </div>
        <WGList
          :list="fields"
          orientations="horizontal"
          class="wg-card-description-options_body-wrapper"
        >
          <template #item="{item}">
            <img :src="item.image" alt="chat-icon">
            <div>
              <p class="wg-card-description-options_body-title">
                {{ item.title }}
              </p>
              <span
                v-if="item.description"
                class="wg-card-description-options_body-description"
              >
                {{ item.description }}
              </span>
            </div>
          </template>
        </WGList>
      </div>
      <WGButton
        variant="warning"
        size="xs"
        class="wg-offer-prompt_case-button wg-card-description-options_body-button text-uppercase"
        @click="$emit('handleChat')"
      >
        <template #default>
          <div class="wg-offer-prompt_button">
            <img
              v-if="buttonIcon"
              :src="buttonIcon"
              alt="message"
            />
            <p>{{ buttonTitle }}</p>
          </div>
        </template>
      </WGButton>
    </div>
  </div>
</template>

<script>
import WGButton from '@/components/UI/WGButtons/WGButton'
import WGIndicatorOnline from '@/components/UI/WGIndicators/WGIndicatorOnline'
import WGList from '@/components/UI/WGLists/WGList'
export default {
  name: 'WGCardDescriptionOptions',
  components: {
    WGList,
    WGIndicatorOnline,
    WGButton
  },
  props: {
    title: String,
    cardImage: String,
    mainIcon: String,
    description: String,
    fields: Array,
    buttonIcon: String,
    buttonTitle: String,
    isOffline: {
      type: Boolean,
      default: false
    },
    titleStatus: {
      type: String,
      default: 'Online'
    }

  }
}
</script>

<style scoped>

</style>
