
import WGCopyright from '@/components/UI/WGCopyright/WGCopyright'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGCopyright',
  component: WGCopyright,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    textPrepend: 'textPrepend',
    dateFrom: 2008,
    textAppend: 'textAppend'
  }
}
