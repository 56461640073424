<template>
  <p class="wg-title" :class="className">
    {{ title }}
  </p>
</template>

<script>
export default {
  name: 'WGTitle',
  props: {
    title: String,
    className: String
  }
}
</script>

<style scoped>

</style>
