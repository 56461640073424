import { API } from './API'

export class SiteSetting {
  Api;
  endpoint = 'site_setting';

  constructor () {
    this.Api = new API()
  }

  getSiteSetting (unAuth = false, params) {
    let endpoint = ''

    if (unAuth) endpoint = '/un_auth'
    const url = `${endpoint}/${this.endpoint}`

    return this.Api.getM(url, params)
  }

  async getSeoText ({ unAuth = false, country, isRepeatedRequest = true }) {
    let endpoint = ''

    if (!unAuth) endpoint = '/un_auth'
    const url = `${endpoint}/seo/page_text/${country}`

    try {
      return await this.Api.getM(url)
    } catch (error) {
      if (error && error.status === 502) {
        return
      }
      if (error && error.status === 404 && !isRepeatedRequest) {
        return '<div></div>'
      } else {
        return await this.getSeoText({ unAuth: false, country: '', isRepeatedRequest: false })
      }
    }
  }
}
