
import WGAuthForm from '@/components/auth/WGAuthForm'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGAuthForm',
  component: WGAuthForm,
  tags: ['autodocs']
}

export const Docs = {

}
