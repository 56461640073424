<template>
  <vSelect
    class="wg-select-locale"
    :options="availableLocales"
    :label="selectedLocale"
    :clearable="false"
    :searchable="false"
    v-model="selectedLocale"
    @option:selected="onChangeLocale"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <WGIcon :icon="'wg-ffi-arrow-down'"/>
      </span>
    </template>
    <template #selected-option>
      <span> {{ $t('global.language') }}: <span class="wg-selected-locale"> {{ selectedLocale }}</span></span>
    </template>
    <template #option="option">
      <span class="overflow-ellipsis">
        <span v-if="Object.values(option)[0] === 'en'">English</span>
        <span v-else-if="Object.values(option)[0] === 'ru'">Русский</span>
      </span>
    </template>
  </vSelect>
</template>

<script>
import { ref } from 'vue'

import vSelect from 'vue-select'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'WGSelectLocales',
  components: {
    WGIcon,
    vSelect
  },
  setup () {
    const store = useStore()
    const { locale } = useI18n({ useScope: 'global' })
    const availableLocales = ref(['en', 'ru'])
    const selectedLocale = ref(locale)

    const onChangeLocale = (selectedOption) => {
      locale.value = selectedOption
      store.dispatch('setLocate', locale.value)
    }

    return {
      selectedLocale,
      availableLocales,
      onChangeLocale
    }
  }
}
</script>
