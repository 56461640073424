<template>
  <WGModalBG
    isShowButtonClose
  />
  <WGCookieAlert />
  <WGContainer isClearOffsets>
    <WGHeaderVictoria :logo="promotionLogo" />
  </WGContainer>

  <WGContainer
    v-if="isActivePromotion && siteSettings?.settings?.url_storage && banner"
    isClearOffsets
  >
    <WGPromotionBanner
      :urlStorage="siteSettings?.settings?.url_storage"
      :banner="banner"
    />
  </WGContainer>

  <router-view/>

  <WGContainer isClearOffsets>
    <WGFooterVictoria/>
  </WGContainer>
  <VictoriaModalAuth
    modelName="VictoriaModalAuth"
  />
  <ThanksModal
    modelName="VictoriaModalResetFinished"
  />
  <ResetPassword
    modelName="VictoriaModalResetPassword"
  />
  <ChangePassword
    modelName="VictoriaModalChangePassword"
  />

  <MessageAndChat modelName="MessageNChat" />

  <VictoriaModalPageText
    modelName="TermsUserAgreement"
    :title="$store.getters.getModuleFromPageText('license-agreement', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('license-agreement', 'text_' + $store.getters.getLocale)"
  />
  <VictoriaModalPageText
    modelName="TermsAndConditions"
    :title="$store.getters.getModuleFromPageText('terms-and-conditions', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('terms-and-conditions', 'text_' + $store.getters.getLocale)"
  />
  <VictoriaModalPageText
    modelName="PrivacyPolicy"
    :title="$store.getters.getModuleFromPageText('privacy-policy', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('privacy-policy', 'text_' + $store.getters.getLocale)"
  />

</template>

<script>

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, ref, watchEffect } from 'vue'
import getPromotionByPage from '@/configurations/promotions-config/victoriyaMainPromotion'

import WGHeaderVictoria from '@/components/Victoria/UI/WGHeader/WGHeader'
import WGFooterVictoria from '@/components/Victoria/UI/WGFooter/WGFooter'
import WGModalBG from '@/components/WGModal/WGModalBG.vue'
import VictoriaModalAuth from '@/components/Victoria/Modals/VictoriaModalAuth.vue'
import VictoriaModalPageText from '@/components/Victoria/Modals/VictoriaModalPageText.vue'
import MessageAndChat from '@/components/Victoria/Modals/MessageAndChat'
import WGContainer from '@/components/UI/WGContainers/WGContainer'
import WGCookieAlert from '@/components/UI/WGCookieAlert/WGCookieAlert'
import ThanksModal from '@/components/Victoria/Modals/ThanksModal'
import ResetPassword from '@/components/Victoria/Modals/ResetPassword'
import ChangePassword from '@/components/Victoria/Modals/ChangePassword'
import WGPromotionBanner from '@/components/UI/WGBanners/WGPromotionBanner.vue'

export default {
  name: 'AppVictoria',
  components: {
    ChangePassword,
    ResetPassword,
    ThanksModal,
    WGCookieAlert,
    WGContainer,
    MessageAndChat,
    VictoriaModalPageText,
    VictoriaModalAuth,
    WGModalBG,
    WGHeaderVictoria,
    WGFooterVictoria,
    WGPromotionBanner
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const promotions = ref(null)
    const banner = ref(null)
    const promotionLogo = ref(null)

    store.dispatch('initialize')

    const isHasPromotion = computed(() => {
      return isActivePromotion.value && getBanner.value
    })
    const isHasPromotionBackground = computed(() => {
      return isActivePromotion.value && getPromotionBackground.value
    })

    const isActivePromotion = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.active
    })

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    const getBanner = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials.banners
    })

    const getPromotionBackground = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials.fons
    })

    const getLogo = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials?.logo[0]
    })

    const updatePromotionsData = () => {
      promotions.value = getPromotionByPage(route.name)
      const bannerKey = Object.keys(getBanner.value).find(key => promotions.value.includes(key))
      if (bannerKey) {
        banner.value = getBanner.value[bannerKey]
      }
    }

    const updatePromotionsBackgroundData = () => {
      promotions.value = getPromotionByPage(route.name)
      const backgroundKey = Object.keys(getPromotionBackground.value).find(key => promotions.value.includes(key))
      if (backgroundKey) {
        document.body.style.backgroundImage = `url(${siteSettings.value?.settings?.url_storage + getPromotionBackground.value[backgroundKey]?.src})`
      }
    }

    watchEffect(() => {
      if (isHasPromotion.value && getPromotionByPage(route.name).length) {
        promotionLogo.value = getLogo.value
        updatePromotionsData()
      } else {
        promotions.value = null
        banner.value = null
      }
      if (isHasPromotionBackground.value && getPromotionByPage(route.name).length) {
        updatePromotionsBackgroundData()
      } else {
        document.body.style.backgroundImage = ''
      }
    })

    return {
      isActivePromotion,
      siteSettings,
      banner,
      promotionLogo
    }
  }
}
</script>
