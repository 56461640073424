import { API } from './API'

export class DistribWallList {
  Api;
  endpoint = '/user/distrib_templates/firewall';

  constructor () {
    this.Api = new API()
  }

  getAll () {
    return this.Api.getM(`${this.endpoint}`)
  }

  getById (id) {
    return this.Api.getM(`${this.endpoint}/${id}`)
  }

  save (item) {
    if (item.id) {
      return this.Api.put(`${this.endpoint}/${item.id}`, item)
    }
    return this.Api.post(`${this.endpoint}`, item)
  }

  addUsers (id, userIds) {
    return this.Api.post(`${this.endpoint}/${id}/add`, { userIds })
  }

  removeUser (id, userId) {
    return this.Api.delete(`${this.endpoint}/${id}/${userId}`)
  }
}
