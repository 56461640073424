<template>
  <div class="wg-filter-search">
    <div class="wg-filter-search_tabs-wrapper wg-filter-search_bottom-border wg-filter-search_slider">
      <div class="wg-popup-parent">
        <WGTabs
          :tabs="newTabs"
          name="new-girls"
          v-model="filterParams.new"
          background-type="warning"
          @update:modelValue="applyPopup('new')"
        />
        <WGFilterPopup
          v-if="isShowTooltip('new')"
          @apply-filters="submitRequest"
        />
      </div>
      <div class="wg-popup-parent">
        <WGTabs
          :tabs="onlineTabs"
          name="online"
          v-model="filterParams.online"
          background-type="custom"
          @update:modelValue="applyPopup('online')"
        />
        <WGFilterPopup
          v-if="isShowTooltip('online')"
          @apply-filters="submitRequest"
        />
      </div>
      <div class="wg-popup-parent">
        <WGTabs
          :tabs="cameraTabs"
          name="camera"
          v-model="filterParams.camera"
          @update:modelValue="applyPopup('camera')"
        />
        <WGFilterPopup
          v-if="isShowTooltip('camera')"
          @apply-filters="submitRequest"
        />
      </div>
    </div>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.purpose') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('goals')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <WGList
          :list="purposes"
          variant="none"
          tagName="div"
          class="wg-filter-search_icons wg-filter-search_slider wg-filter-search_bottom-border"
        >
          <template v-slot:item="{ item }">
            <WGCheckbox
              :isHiddenCheckMark="true"
              :isClickableLabel="true"
              :labelFor="item.labelFor"
              :label="item.label"
              :value="item.name"
              v-model="filterParams.goals"
              @update:modelValue="applyPopup('goals')"
            >
              <div class="wg-filter-search_purpose" :class="{'wg-filter-search_purpose--active': !!filterParams.goals.includes(item.name)}">
                <img
                  class="wg-filter-search_purpose-image"
                  :src="!!filterParams.goals.includes(item.name) ? item.activeHref : item.href"
                  :alt="item.label"
                />
                <p class="wg-filter-search_purpose-title">
                  {{ $t(item.title) }}
                </p>
              </div>
            </WGCheckbox>
          </template>
        </WGList>
      </template>
    </WGTitleMarkLetter>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.age') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('sliderAge')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <div class="wg-filter-search_slider wg-filter-search_bottom-border">
          <slider
            class="wg-range-slider"
            v-model="filterGirls.age"
            :min="18"
            :max="68"
            :tooltips="true"
            :lazy="true"
            :tooltipPosition="'bottom'"
            @end="setAge"
          />
        </div>
      </template>
    </WGTitleMarkLetter>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.selectHeight') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('sliderHeight')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <div class="wg-filter-search_slider wg-filter-search_bottom-border">
          <slider
            class="wg-range-slider"
            v-model="filterGirls.height"
            :min="130"
            :max="200"
            :tooltips="true"
            :lazy="true"
            :tooltipPosition="'bottom'"
            @end="setHeight"
          />
        </div>
      </template>
    </WGTitleMarkLetter>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.physique') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('physique')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <WGList
          :list="physique"
          variant="none"
          orientations="vertical"
          tagName="div"
          class="wg-filter-search_bottom-border wg-filter-search_slider"
        >
          <template v-slot:item="{ item, index }">
            <WGCheckbox
              class="wg-filter-search_physique"
              :class="{'wg-filter-search_physique--active': filterParams.physique.includes(item.id)}"
              :isClickableLabel="true"
              :label="$t(item.label)"
              :labelFor="`physique-${item.id}-${index}`"
              :value="item.id"
              v-model="filterParams.physique"
              @update:modelValue="applyPopup('physique')"
            />
          </template>
        </WGList>
      </template>
    </WGTitleMarkLetter>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.eyes') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('eyes_color')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <WGList
          :list="eyes_color"
          variant="none"
          orientations="vertical"
          tagName="div"
          class="wg-filter-search_colors-wrapper wg-filter-search_bottom-border"
        >
          <template v-slot:item="{ item }">
            <WGCheckbox
              :isHiddenCheckMark="true"
              :isClickableLabel="true"
              :labelFor="`eyes-${item.color}`"
              :value="item.id"
              v-model="filterParams.eyes_color"
              @update:modelValue="applyPopup('eyes_color')"
            >
              <WGColorElement
                :is-active="filterParams.eyes_color.includes(item.id)"
                :color="item.color"
                :title="$t(item.title)"
              />
            </WGCheckbox>
          </template>
        </WGList>
      </template>
    </WGTitleMarkLetter>
    <WGTitleMarkLetter className="wg-popup-parent" titleClass="wg-title">
      <template #slotTitle>
        {{ $t('searchFilters.hair') }}
      </template>
      <template #slotTooltip>
        <WGFilterPopup
          v-if="isShowTooltip('hair_color')"
          @apply-filters="submitRequest"
        />
      </template>
      <template #default>
        <WGList
          :list="hair_color"
          variant="none"
          orientations="vertical"
          tagName="div"
          class="wg-filter-search_colors-wrapper wg-filter-search_bottom-border"
        >
          <template v-slot:item="{ item }">
            <WGCheckbox
              :isHiddenCheckMark="true"
              :isClickableLabel="true"
              :labelFor="`hair-${item.color}`"
              :value="item.id"
              v-model="filterParams.hair_color"
              @update:modelValue="applyPopup('hair_color')"
            >
              <WGColorElement
                :is-active="filterParams.hair_color.includes(item.id)"
                :color="item.color"
                :title="$t(item.title)"
              />
            </WGCheckbox>
          </template>
        </WGList>
      </template>
    </WGTitleMarkLetter>

    <div class="wg-popup-parent wg-filter-search_text-field">
      <WGSelect
        :placeholder="$t('searchFilters.selectCountry')"
        v-model="filterParams.countries"
        :options="countries"
        :reduce="item => item"
        @option:selected="handleCountry"
        @option:deselected="setGeo('countries', $event)"
        :clearable="false"
        :searchable="false"
        :multiple="true"
        iconClass="text-green-700"
      />
      <WGFilterPopup
        v-if="isShowTooltip('countries')"
        @apply-filters="submitRequest"
      />
    </div>
    <div class="wg-popup-parent wg-filter-search_text-field" v-show="cities && cities.length">
      <WGSelect
        :placeholder="$t('searchFilters.selectCities')"
        v-model="filterParams.cities"
        :options="cities"
        :reduce="item => item"
        @option:selected="applyPopup('cities')"
        @option:deselected="setGeo('cities', $event)"
        :clearable="false"
        :searchable="false"
        :multiple="true"
        iconClass="text-green-700"
      />
      <WGFilterPopup
        v-if="isShowTooltip('cities')"
        @apply-filters="submitRequest"
      />
    </div>
    <div class="wg-popup-parent wg-filter-search_text-field">
      <WGField
        type="number"
        :placeholder="$t('searchFilters.ids')"
        :modelValue="filterParams.id"
        @update:modelValue="setFieldValue('id', $event)"
      />
      <WGFilterPopup
        v-if="isShowTooltip('id')"
        @apply-filters="submitRequest"
      />
    </div>
    <div class="wg-popup-parent wg-filter-search_text-field">
      <WGField
        class="wg-filter-search_slider wg-filter-search_bottom-border"
        :placeholder="$t('searchFilters.name')"
        :modelValue="filterParams.name"
        @update:modelValue="setFieldValue('name', $event)"
      />
      <WGFilterPopup
        v-if="isShowTooltip('name')"
        @apply-filters="submitRequest"
      />
    </div>

    <div class="wg-filter-search_button-wrapper">
      <WGButton
        v-show="isMobile"
        variant="success"
        @click="handlePopup(isMobile)"
        size="sm"
        class="wg-filter-search_button-wrapper_button"
      >
          <span class="wg-page-search_title">
            {{ $t('searchFilters.close') }}
          </span>
      </WGButton>
      <WGButton
        size="xs"
        variant="primary"
        @click="resetFilter"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        class="wg-button-outline-success wg-filter-search_button-wrapper_button"
      >
        <div class="wg-filter-search_button-wrapper_button-reset">
          <img
            :src="hovered ? whiteFilterIcon : filterIcon"
            alt="filter-icon"
          >
          <span class="wg-page-search_title">{{ $t('searchFilters.reset') }}</span>
        </div>
      </WGButton>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider'
import diamondIcon from '@/assets/images/icons/interests/diamong-ring.svg'
import diamondFillIcon from '@/assets/images/icons/interests/diamong-ring-fill.svg'
import companionIcon from '@/assets/images/icons/interests/companion.svg'
import companionFillIcon from '@/assets/images/icons/interests/companion-fill.svg'
import eveningIcon from '@/assets/images/icons/interests/evening.svg'
import eveningFillIcon from '@/assets/images/icons/interests/evening-fill.svg'
import flirtingIcon from '@/assets/images/icons/interests/flirting.svg'
import flirtingFillIcon from '@/assets/images/icons/interests/flirting-fill.svg'
import friendshipIcon from '@/assets/images/icons/interests/friendship.svg'
import friendshipFillIcon from '@/assets/images/icons/interests/friendship-fill.svg'
import funIcon from '@/assets/images/icons/interests/fun.svg'
import funFillIcon from '@/assets/images/icons/interests/fun-fill.svg'
import hookupIcon from '@/assets/images/icons/interests/hookup.svg'
import hookupFillIcon from '@/assets/images/icons/interests/hookup-fill.svg'
import moralIcon from '@/assets/images/icons/interests/moral.svg'
import moralFillIcon from '@/assets/images/icons/interests/moral-fill.svg'
import naughtyIcon from '@/assets/images/icons/interests/naughty.svg'
import naughtyFillIcon from '@/assets/images/icons/interests/naughty-fill.svg'
import travelIcon from '@/assets/images/icons/interests/travel.svg'
import travelFillIcon from '@/assets/images/icons/interests/travel-fill.svg'
import whiteFilterIcon from '@/assets/images/icons/filter-white.svg'
import filterIcon from '@/assets/images/icons/filter.svg'
import WGTabs from '@/components/UI/WGTabs/WGTabs.vue'
import WGList from '@/components/UI/WGLists/WGList.vue'
import WGField from '@/components/UI/WGForms/WGField.vue'
import WGSelect from '@/components/UI/WGSelect/WGSelect.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGCheckbox from '@/components/UI/WGForms/WGCheckbox.vue'
import WGColorElement from '@/components/UI/WGElements/WGColorElement.vue'
import WGFilterPopup from '@/components/UI/WGPopups/WGFilterPopup.vue'
import WGTitleMarkLetter from '@/components/UI/WGTitleMarkLetter/WGTitleMarkLetter.vue'
import canonicalLinks from '@/controllers/SEO/canonicalLinks'

export default {
  name: 'WGFilterSearchGirls',
  components: {
    WGTitleMarkLetter,
    WGFilterPopup,
    WGList,
    WGColorElement,
    WGField,
    WGButton,
    WGSelect,
    WGCheckbox,
    WGTabs,
    slider: Slider
  },
  props: {
    isMobile: Boolean,
    currentPage: Number
  },
  data () {
    return {
      whiteFilterIcon,
      filterIcon,
      testValue: [],
      newTabs: [{ name: 'searchFilters.newGirls', value: 1 }, { name: 'searchFilters.allGirls', value: 0 }],
      onlineTabs: [{ name: 'searchFilters.online', value: 1 }, { name: 'searchFilters.allGirls', value: 0 }],
      cameraTabs: [{ name: 'searchFilters.videoCalls', value: 1, icon: 'wg-ffi-icon wg-ffi-webcam-fill' }, { name: 'searchFilters.allGirls', value: 0 }],
      purposes: [
        { id: 'marriage', title: 'purpose.marriage', name: 'serious_relationships', href: diamondIcon, activeHref: diamondFillIcon, labelFor: 'icon-diamond', label: 'icon' },
        { id: 'companion', title: 'purpose.companion', name: 'life_partner', href: companionIcon, activeHref: companionFillIcon, labelFor: 'icon-companion', label: 'icon' },
        { id: 'evening', title: 'purpose.evening', name: 'romantic_evening', href: eveningIcon, activeHref: eveningFillIcon, labelFor: 'icon-evening', label: 'icon' },
        { id: 'hookup', title: 'purpose.hookup', name: 'hookup', href: hookupIcon, activeHref: hookupFillIcon, labelFor: 'icon-hookup', label: 'icon' },
        { id: 'heart', title: 'purpose.friendship', name: 'online_friendship', href: friendshipIcon, activeHref: friendshipFillIcon, labelFor: 'icon-friendship', label: 'icon' },
        { id: 'flirting', title: 'purpose.flirting', name: 'flirting', href: flirtingIcon, activeHref: flirtingFillIcon, labelFor: 'icon-flirting', label: 'icon' },
        { id: 'naughty', title: 'purpose.naughty', name: 'naughty_talks', href: naughtyIcon, activeHref: naughtyFillIcon, labelFor: 'icon-naughty', label: 'icon' },
        { id: 'icon-ticket', title: 'purpose.travel', name: 'travel_companion', href: travelIcon, activeHref: travelFillIcon, labelFor: 'icon-travel', label: 'icon' },
        { id: 'moral', title: 'purpose.moral', name: 'moral_support', href: moralIcon, activeHref: moralFillIcon, labelFor: 'icon-moral', label: 'icon' },
        { id: 'fun', title: 'purpose.fun', name: 'have_fun', href: funIcon, activeHref: funFillIcon, labelFor: 'icon-fun', label: 'icon' }
      ],
      eyes_color: [
        { id: 1, color: '#692D08', title: 'colors.brown' },
        { id: 2, color: '#14991D', title: 'colors.green' },
        { id: 3, color: '#627680', title: 'colors.gray' },
        { id: 4, color: '#1DACFC', title: 'colors.blue' },
        { id: 5, color: '#1142DA', title: 'colors.darkBlue' },
        { id: 6, color: '#000000', title: 'colors.black' },
        { id: 7, color: '#DDD900', title: 'colors.yellow' }
      ],
      hair_color: [
        { id: 1, color: '#DDD900', title: 'hairColor.blonde' },
        { id: 2, color: '#692D08', title: 'hairColor.brown' },
        { id: 3, color: '#E0713A', title: 'hairColor.red' },
        { id: 4, color: '#000000', title: 'hairColor.brunette' },
        { id: 5, color: '#C8D0D3', title: 'hairColor.dyed' }
      ],
      filterGirls: {
        age: [18, 68],
        height: [130, 200]
      },
      physique: [
        { id: 1, label: 'searchFilters.thin' },
        { id: 2, label: 'searchFilters.athletic' },
        { id: 3, label: 'searchFilters.average' },
        { id: 4, label: 'searchFilters.pounds' }
      ],
      debounceTimer: null,
      filterParams: {
        new: { name: 'searchFilters.allGirls', value: 0 },
        online: { name: 'searchFilters.allGirls', value: 0 },
        camera: { name: 'searchFilters.allGirls', value: 0 },
        age_min: null,
        age_max: null,
        height_min: null,
        height_max: null,
        eyes_color: [],
        physique: [],
        goals: [],
        hair_color: [],
        countries: [],
        cities: [],
        id: null,
        name: null,
        page: this.page ?? 1
      },
      paramOrder: [
        'new',
        'online',
        'camera',
        'target_travel',
        'target_marriage',
        'target_communication',
        'target_friendship',
        'goals',
        'age_min',
        'age_max',
        'height_min',
        'height_max',
        'eyes_color',
        'physique',
        'hair_color',
        'countries',
        'cities',
        'id',
        'name',
        'page'
      ],
      slavicCountries: [
        'ukraine',
        'russian-speaking',
        'belarus',
        'moldova',
        'poland',
        'bulgaria'
      ],
      asianCountries: [
        'thailand',
        'turkish',
        'israeli',
        'philippine',
        'united-arab-emirates',
        'vietnamese'
      ],
      europeCountries: [
        'germany',
        'france',
        'italy',
        'spain',
        'latvia',
        'romania'
      ],
      latinaCountries: [
        'argentina',
        'brazil',
        'colombia',
        'venezuela',
        'mexico',
        'portugal'
      ],
      isShowFilterPopup: false,
      referredElement: null,
      hovered: false,
      currentRoute: '',
      defaultFilterParams: {
        new: { name: 'searchFilters.allGirls', value: 0 },
        online: { name: 'searchFilters.allGirls', value: 0 },
        camera: { name: 'searchFilters.allGirls', value: 0 },
        age_min: null,
        age_max: null,
        height_min: null,
        height_max: null,
        eyes_color: [],
        physique: [],
        goals: [],
        hair_color: [],
        countries: [],
        cities: [],
        id: null,
        name: null
      }
    }
  },
  created () {
    window.addEventListener('popstate', this.updateData)
    this.currentRoute = this.$route.fullPath
    this.fillFilterParamsFromURL()
    // :TODO find way to remove setTimeout in a future [Pronkurov 8.01.2024]
    setTimeout(() => {
      this.updateData()
      this.setAgesUrl()
    }, 1000)
  },
  unmounted () {
    window.removeEventListener('popstate', this.updateData)
  },
  computed: {
    countries () {
      return this.$store.getters.getCountriesList
    },
    cities () {
      return this.$store.getters.getCitiesList
    }
  },
  watch: {
    '$route' (to) {
      this.currentRoute = to
      this.updateData()
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  methods: {
    updateData () {
      this.filterParams = {
        new: { name: 'searchFilters.allGirls', value: 0 },
        online: { name: 'searchFilters.allGirls', value: 0 },
        camera: { name: 'searchFilters.allGirls', value: 0 },
        age_min: null,
        age_max: null,
        height_min: null,
        height_max: null,
        eyes_color: [],
        physique: [],
        hair_color: [],
        goals: [],
        countries: [],
        cities: [],
        id: null,
        name: null
      }
      this.fillFilterParamsFromURL()
      this.$emit('close')
      this.isShowFilterPopup = false
      this.$emit('handleFilter', { filters: this.filterParams, page: null })
    },
    async fetchCities (ids, citiesIds) {
      for (const id of ids) {
        await this.$store.dispatch('getCitiesByCountryId', id)
      }
      const uniqueCityIds = new Set()
      this.filterParams.cities = this.cities.reduce((filteredCities, city) => {
        if (!uniqueCityIds.has(city.id) && citiesIds.includes(city.id)) {
          uniqueCityIds.add(city.id)
          filteredCities.push(city)
        }
        return filteredCities
      }, [])
      this.$emit('handleFilter', { filters: this.filterParams, page: null })
    },
    filterCitiesCountries (paramName, value, isCountry) {
      let isLinkCountries = null
      let keyOfCountries = ''
      switch (true) {
        case value.includes('slavic-brides'):
          isLinkCountries = value.includes('slavic-brides')
          keyOfCountries = 'slavicCountries'
          this.clearFilterWithoutRequest()
          break
        case value.includes('european-brides'):
          isLinkCountries = value.includes('european-brides')
          keyOfCountries = 'europeCountries'
          this.clearFilterWithoutRequest()
          break
        case value.includes('latina-brides'):
          isLinkCountries = value.includes('latina-brides')
          keyOfCountries = 'latinaCountries'
          this.clearFilterWithoutRequest()
          break
        case value.includes('asian-brides'):
          isLinkCountries = value.includes('asian-brides')
          keyOfCountries = 'asianCountries'
          this.clearFilterWithoutRequest()
          break
        default: isLinkCountries = []
          break
      }
      const filterValues = isLinkCountries ? [] : this.filterParams[paramName]
      this.filterParams[paramName] = this[paramName].filter(item => {
        if (isCountry) {
          const isSlavic = isLinkCountries ? this[keyOfCountries] : value
          for (const key in isSlavic) {
            if (typeof value[key] === 'string') {
              if (value[key] !== 'russian-speaking') {
                value[key] = value[key].replaceAll('-', ' ')
              }
            }
          }

          if (isLinkCountries) {
            filterValues.push(item)
            return this[keyOfCountries].includes(item.name.trim().toLowerCase().replaceAll(' ', '-'))
          }

          return filterValues.includes(item.name.trim().toLowerCase().replaceAll(' ', '-'))
        } else {
          for (const key in filterValues) {
            if (typeof value[key] === 'string') {
              value[key] = value[key].replace('-', ' ')
            }
          }
        }

        return filterValues.includes(item.id)
      })

      this.fetchCities(this.filterParams.countries.map(item => item.id), value)
    },
    parseDatingString (datingString) {
      const ageMatch = datingString.match(/dating-over-(\d+)/)

      if (ageMatch) {
        const ageMin = ageMatch[1]
        const ageMax = parseInt(ageMin) + 9

        return ['age_min', ageMin, 'age_max', ageMax.toString()]
      }

      return []
    },
    fillFilterParamsFromURL () {
      let urlParams = window.location.pathname.split('/').slice(2)
      let paramName = null
      const datingKeywords = ['dating-over-30', 'dating-over-40', 'dating-over-50']
      if (datingKeywords.some(keyword => urlParams.includes(keyword))) {
        urlParams = this.parseDatingString(urlParams.toString())
      }

      if (urlParams.includes('senior-dating')) {
        urlParams = ['age_min', '60', 'age_max', '69']
      }

      urlParams.forEach((paramOrValue) => {
        if (!paramName) {
          paramName = paramOrValue
        } else {
          if (this.paramOrder.includes(paramName)) {
            this.updateFilterParam(paramName, paramOrValue)
          }

          paramName = null
        }
      })
    },
    updateFilterParam (paramName, value) {
      if (this.filterParams[paramName] === undefined) {
        return
      } else if (Array.isArray(this.filterParams[paramName])) {
        if (value !== '') {
          this.filterParams[paramName] = value.split(',').map(item => {
            if (!isNaN(item)) {
              return Number(item)
            }
            return item
          })
        }
      } else if (['new', 'online', 'camera'].includes(paramName)) {
        this.setTabsValueFromURL(paramName, value)
      } else if (this.filterParams[paramName] === null) {
        this.handleNullOrUndefinedFilterParam(paramName, value)
      } else {
        this.filterParams[paramName] = value
      }

      if (paramName === 'countries') {
        this.filterCitiesCountries(paramName, this.filterParams[paramName], true)
      }

      if (paramName === 'cities') {
        this.filterCitiesCountries(paramName, this.filterParams[paramName])
      }

      this.updateFilterGirls(paramName)
    },
    setTabsValueFromURL (paramName, value) {
      if (value === '1') {
        this.filterParams[paramName] = this[`${paramName}Tabs`].find(tab => tab.value === parseInt(value))
      } else {
        this.filterParams[paramName] = this[`${paramName}Tabs`].find(tab => tab.value !== parseInt(value))
      }
    },
    handleNullOrUndefinedFilterParam (paramName, value) {
      if (value === 'true') {
        this.filterParams[paramName] = true
      } else if (value === 'false') {
        this.filterParams[paramName] = false
      } else if (value.includes(',')) {
        this.filterParams[paramName] = value.split(',').map(item => {
          if (!isNaN(item)) {
            return Number(item)
          }
          return item
        })
      } else {
        this.filterParams[paramName] = value
      }
    },

    updateFilterGirls (paramName) {
      const paramMapping = {
        height_min: 'height',
        height_max: 'height',
        age_min: 'age',
        age_max: 'age'
      }

      const targetProperty = paramMapping[paramName]
      if (targetProperty && this.filterParams[paramName]) {
        this.filterGirls[targetProperty] = [
          this.filterParams[targetProperty + '_min'],
          this.filterParams[targetProperty + '_max']
        ]
      }
    },
    updateURLWithFilterParams () {
      const urlSegments = ['search']

      if (this.currentPage && this.currentPage > 1) {
        urlSegments.push('page', this.currentPage.toString())
      }

      this.paramOrder.forEach(param => {
        let paramValue
        if (this.filterParams[param]) {
          if (['new', 'online', 'camera'].includes(param)) {
            if (this.filterParams[param].value) {
              paramValue = this.filterParams[param].value
              return urlSegments.push(param, paramValue)
            } else {
              return
            }
          }
          paramValue = this.filterParams[param]

          if (Array.isArray(paramValue)) {
            if (paramValue.length === 0) {
              return
            }
            if (param === 'goals') {
              if (paramValue.length === this.filterParams.goals.length) {
                paramValue = paramValue.map(item => item).toString()
              }
            } else {
              if (paramValue.length === this[param].length && param !== 'cities') {
                return
              }
            }
            if (param === 'countries') {
              paramValue = paramValue.map(item => {
                return item.name.trim().toLowerCase()
              }).join(',').replaceAll(' ', '-')
            }

            if (param === 'cities') {
              paramValue = paramValue.map(item => item.id).join(',')
            }
          }

          urlSegments.push(param, paramValue)
        }
      })

      const newURL = `/${urlSegments.join('/')}`
      window.history.pushState({}, '', newURL)
      canonicalLinks()
    },
    setAge (ages) {
      this.filterParams.age_min = ages[0]
      this.filterParams.age_max = ages[1]
      this.applyPopup('sliderAge')
    },
    setAgesUrl () {
      if (!this.checkIsEmptyFilterObject(['age_min', 'age_max'])) {
        if (+this.filterParams.age_min === 30 && +this.filterParams.age_max === 39) {
          this.$router.replace('/search/dating-over-30')
          window.history.pushState(null, '', '/search/dating-over-30')
        }
        if (+this.filterParams.age_min === 40 && +this.filterParams.age_max === 49) {
          this.$router.replace('/search/dating-over-40')
          window.history.pushState(null, '', '/search/dating-over-40')
        }
        if (+this.filterParams.age_min === 50 && +this.filterParams.age_max === 59) {
          this.$router.replace('/search/dating-over-50')
          window.history.pushState(null, '', '/search/dating-over-50')
        }
        if (+this.filterParams.age_min === 60 && +this.filterParams.age_max === 68) {
          this.$router.replace('/search/senior-dating')
          window.history.pushState(null, '', '/search/senior-dating')
        }
      }
    },
    setHeight (height) {
      this.filterParams.height_min = height[0]
      this.filterParams.height_max = height[1]
      this.applyPopup('sliderHeight')
    },
    checkIsEmptyFilterObject (skippedValues = []) {
      return Object.keys(this.filterParams).map(item => {
        if (skippedValues.includes(item)) {
          return false
        }

        return this.filterParams[item] !== false && this.filterParams[item]?.value !== 0 && this.filterParams[item] !== null && this.filterParams[item]?.length !== 0
      }).includes(true)
    },
    applyPopup (type) {
      this.referredElement = type
      this.handlePopup()
    },
    toggleArrayParamsFilter (type, value) {
      if (!this.filterParams[type]) {
        this.filterParams[type] = []
      }

      const index = this.filterParams[type].indexOf(value.id)
      if (index !== -1) {
        this.filterParams[type].splice(index, 1)
      } else {
        this.filterParams[type].push(value.id)
      }
      this.filterParams[type].sort()
      this.applyPopup(type)
    },
    setGeo (geoType, geo) {
      if (geo && geoType[geo.length - 1]) {
        this.toggleArrayParamsFilter(geoType, { id: geo })
      } else {
        this.filterParams[geoType] = this.filterParams[geoType].filter(param => param.id !== geo.id)
      }
      if (geoType === 'countries') {
        this.handleCountry(this.filterParams[geoType])
      }
      this.applyPopup(geoType)
    },
    isShowTooltip (reference) {
      return !this.isMobile && this.isShowFilterPopup && this.referredElement === reference
    },
    handleCountry (countries) {
      this.$store.commit('clearCities')
      this.filterParams.cities = countries.flatMap(country =>
        this.filterParams.cities.filter(item => item.country_id === country.id)
      )

      countries.map(country => this.$store.dispatch('getCitiesByCountryId', country.id))
      this.applyPopup('countries')
    },
    handlePopup (isMobile) {
      this.updateURLWithFilterParams()
      this.setAgesUrl()
      if (isMobile) {
        this.submitRequest()
      } else {
        this.isShowFilterPopup = true
      }
    },
    submitRequest () {
      this.$emit('close')
      this.isShowFilterPopup = false
      this.$emit('handleFilter', { filters: this.filterParams, page: 1 })
    },
    setFieldValue (type, value) {
      clearTimeout(this.debounceTimer)
      this.filterParams[type] = value
      this.applyPopup(type)
    },
    resetFilter () {
      this.filterParams = {
        new: { name: 'searchFilters.allGirls', value: 0 },
        online: { name: 'searchFilters.allGirls', value: 0 },
        camera: { name: 'searchFilters.allGirls', value: 0 },
        age_min: null,
        age_max: null,
        height_min: null,
        height_max: null,
        eyes_color: [],
        physique: [],
        goals: [],
        hair_color: [],
        countries: [],
        cities: [],
        id: null,
        name: null
      }
      this.$store.commit('clearCities')
      this.filterGirls = {
        age: [18, 68],
        height: [130, 200]
      }
      this.handlePopup()
      this.referredElement = null
      this.submitRequest()
    },
    clearFilterWithoutRequest () {
      this.filterParams = {
        new: { name: 'searchFilters.allGirls', value: 0 },
        online: { name: 'searchFilters.allGirls', value: 0 },
        camera: { name: 'searchFilters.allGirls', value: 0 },
        age_min: null,
        age_max: null,
        height_min: null,
        height_max: null,
        eyes_color: [],
        physique: [],
        goals: [],
        hair_color: [],
        countries: [],
        cities: [],
        id: null,
        name: null
      }
      this.filterGirls = {
        age: [18, 68],
        height: [130, 200]
      }
    }
  }
}
</script>
