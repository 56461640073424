<template>
  <div class="wg-top-bar" :class="className">
    <h1 class="wg-top-bar_title">
      {{ $t(title) }}
      <slot></slot>
    </h1>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'WGTopBar',
  props: {
    title: {
      type: String,
      required: true
    },
    textPosition: {
      type: String,
      default: 'center'
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (prop) =>
        ['success', 'warning', 'custom', 'primary', 'success-revers']
          .includes(prop)
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['wg-top-bar-' + props.variant]: true,
        ['wg-top-bar-text-' + props.textPosition]: true
      }
    })

    return {
      className
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/victoria/UI/top-bar.scss';
</style>
