
import WGButtonBurger from '@/components/UI/WGButtons/WGButtonBurger'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGButtonBurger',
  component: WGButtonBurger,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    default: 'Menu site'
  }
}
