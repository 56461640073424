<template>

  <article class="wg-content-with-aside">
    <section class="wg-content-section">
      <slot name="section"></slot>
    </section>
    <aside class="wg-content-aside">
      <slot name="aside"></slot>
    </aside>
  </article>

</template>

<script>

export default {
  name: 'WGContentWithAside'
}
</script>
