<template>
  <WGModalBG
    isShowButtonClose
  />
  <WGContainer
    v-if="!isRemovedLayout"
    isClearOffsets
    class="wg-header-wrapper"
    :class="{'wg-header-wrapper--active': isScrolled}"
  >
    <WGHeaderVictoria :logo="promotionLogo" />
  </WGContainer>
  <WGContainer
    v-if="isActivePromotion && siteSettings?.settings?.url_storage && banner"
    isClearOffsets
  >
   <WGPromotionBanner
     :urlStorage="siteSettings?.settings?.url_storage"
     :banner="banner"
   />
  </WGContainer>
  <router-view/>

  <WGContainer v-if="!isRemovedLayout" isClearOffsets>
    <WGFooterVictoria/>
  </WGContainer>
  <VictoriaModalAuth
    modelName="VictoriaModalAuth"
  />
  <ThanksModal
    modelName="VictoriaModalResetFinished"
  />
  <ResetPassword
    modelName="VictoriaModalResetPassword"
  />
  <ChangePassword
    modelName="VictoriaModalChangePassword"
  />

  <MessageAndChat modelName="MessageNChat" />

  <VictoriaModalPageText
    modelName="TermsUserAgreement"
    :title="$store.getters.getModuleFromPageText('license-agreement', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('license-agreement', 'text_' + $store.getters.getLocale)"
  />
  <VictoriaModalPageText
    modelName="TermsAndConditions"
    :title="$store.getters.getModuleFromPageText('terms-and-conditions', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('terms-and-conditions', 'text_' + $store.getters.getLocale)"
  />
  <VictoriaModalPageText
    modelName="PrivacyPolicy"
    :title="$store.getters.getModuleFromPageText('privacy-policy', 'name_' + $store.getters.getLocale)"
    :content="$store.getters.getModuleFromPageText('privacy-policy', 'text_' + $store.getters.getLocale)"
  />

</template>

<script>

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'
import WGModalBG from '@/components/WGModal/WGModalBG.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import ThanksModal from '@/components/Victoria/Modals/ThanksModal.vue'
import ResetPassword from '@/components/Victoria/Modals/ResetPassword.vue'
import WGHeaderVictoria from '@/components/Victoria/UI/WGHeader/WGHeader.vue'
import WGFooterVictoria from '@/components/Victoria/UI/WGFooter/WGFooter.vue'
import MessageAndChat from '@/components/Victoria/Modals/MessageAndChat.vue'
import ChangePassword from '@/components/Victoria/Modals/ChangePassword.vue'
import WGPromotionBanner from '@/components/UI/WGBanners/WGPromotionBanner.vue'
import VictoriaModalAuth from '@/components/Victoria/Modals/VictoriaModalAuth.vue'
import VictoriaModalPageText from '@/components/Victoria/Modals/VictoriaModalPageText.vue'
import getPromotionByPage from '@/configurations/promotions-config/victoriyaMainPromotion.js'

export default {
  name: 'AppVictoriaMain',
  components: {
    WGPromotionBanner,
    ThanksModal,
    ChangePassword,
    ResetPassword,
    WGContainer,
    MessageAndChat,
    VictoriaModalPageText,
    VictoriaModalAuth,
    WGModalBG,
    WGHeaderVictoria,
    WGFooterVictoria
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const removeLayout = ['/registration']

    const isRemovedLayout = ref(removeLayout.includes(window.location.pathname))

    const isScrolled = ref(false)
    const promotions = ref(null)
    const banner = ref(null)
    const promotionLogo = ref(null)

    store.dispatch('initialize')

    const isHasPromotion = computed(() => {
      return isActivePromotion.value && getBanner.value
    })
    const isHasPromotionBackground = computed(() => {
      return isActivePromotion.value && getPromotionBackground.value
    })

    const isActivePromotion = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.active
    })

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    const getBanner = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials.banners
    })

    const getPromotionBackground = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials.fons
    })

    const getLogo = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.promotion_materials?.logo[0]
    })

    const updatePromotionsData = () => {
      promotions.value = getPromotionByPage(route.name)
      const bannerKey = Object.keys(getBanner.value).find(key => promotions.value.includes(key))
      if (bannerKey) {
        banner.value = getBanner.value[bannerKey]
      }
    }

    const updatePromotionsBackgroundData = () => {
      promotions.value = getPromotionByPage(route.name)
      const backgroundKey = Object.keys(getPromotionBackground.value).find(key => promotions.value.includes(key))
      if (backgroundKey) {
        document.body.style.backgroundImage = `url(${siteSettings.value?.settings?.url_storage + getPromotionBackground.value[backgroundKey]?.src})`
      }
    }

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 100
    }

    watchEffect(() => {
      if (isHasPromotion.value && getPromotionByPage(route.name).length) {
        promotionLogo.value = getLogo.value
        updatePromotionsData()
      } else {
        promotions.value = null
        banner.value = null
      }
      if (isHasPromotionBackground.value && getPromotionByPage(route.name).length) {
        updatePromotionsBackgroundData()
      } else {
        document.body.style.backgroundImage = ''
      }
    })

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
    })
    return {
      isScrolled,
      isRemovedLayout,
      isActivePromotion,
      siteSettings,
      banner,
      promotionLogo
    }
  }
}
</script>
