<template>
  <div>
    <slot :options="options.data"></slot>
    <div v-if="options.last_page > '1' && !$slots.lazyload" class="wg-pagination wg-swiper-external-navigation">
      <button
        type="button"
        aria-label="Previous slide"
        @click="goToPage(options.current_page-1)"
        class="wg-swiper-external-navigation-btn wg-pagination_page-icon"
        :class="{'wg-swiper-external-navigation-btn--pagination-disabled': options.current_page === 1}"
      >
        <WGIcon icon="wg-ffi-arrow-left"/>
      </button>
      <button
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
        @click="goToPage(pageNumber)"
        class="wg-pagination_page-number"
        :class="{'wg-pagination_page-number--active': pageNumber === options.current_page}"
      >
        {{ pageNumber === 0 ? '...' : pageNumber }}
      </button>
      <button
        type="button"
        aria-label="Next slide"
        @click="goToPage(options.current_page+1)"
        class="wg-swiper-external-navigation-btn wg-pagination_page-icon"
        :class="{'wg-swiper-external-navigation-btn--pagination-disabled': options.current_page === options.last_page}"
      >
        <WGIcon icon="wg-ffi-arrow-right"/>
      </button>
    </div>
  </div>
</template>

<script>
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

export default {
  name: 'WGPaginatedList',
  components: { WGIcon },
  props: {
    options: Object
  },
  computed: {
    visiblePages () {
      if (this.options.last_page <= 6) {
        return Array.from({ length: this.options.last_page }, (_, i) => i + 1)
      } else if (this.options.current_page <= 3) {
        return [
          1, 2, 3, 0,
          this.options.last_page - 2,
          this.options.last_page - 1,
          this.options.last_page
        ]
      } else if (this.options.current_page >= this.options.last_page - 2) {
        return [
          1, 2, 3, 0,
          this.options.last_page - 2,
          this.options.last_page - 1,
          this.options.last_page
        ]
      } else {
        return [
          1,
          this.options.current_page - 1,
          this.options.current_page,
          this.options.current_page + 1,
          0,
          this.options.last_page - 2,
          this.options.last_page - 1,
          this.options.last_page
        ]
      }
    }
  },
  methods: {
    goToPage (pageNumber) {
      if (pageNumber < 1) {
        pageNumber = this.options.current_page
        return
      }
      if (pageNumber > this.options.last_page) {
        pageNumber = this.options.last_page
        return
      }

      this.$emit('onChange', pageNumber)
    }
  }
}
</script>
