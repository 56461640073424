<template>
  <label class="wg-label" :for="labelFor">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'WGLabel',
  props: {
    labelFor: {
      type: String,
      default: null
    }
  }
}
</script>
