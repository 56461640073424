<template>

  <span class="wg-link-box" :class="className">

    <WGIcon :icon="$props.linkIcon" :class="$props.linkIconClass" v-if="$props.linkIcon"/>

    <a v-if="$props.type === 'default'" :href="$props.linkHref" :target="$props.linkTarget" class="wg-link" @click="$emit('click')">
      <slot></slot>
    </a>
    <RouterLink v-else-if="$props.type === 'router'" :to="$props.linkHref" :target="$props.linkTarget" class="wg-link" @click="$emit('click')">
      <slot></slot>
    </RouterLink>
    <button v-else-if="$props.type === 'button'" class="wg-link" @click="$emit('click')">
      <slot></slot>
    </button>
  </span>

</template>

<script>
import { computed } from 'vue'

import WGIcon from '@/components/UI/WGIcons/WGIcon'

export default {
  name: 'WGLink',
  components: {
    WGIcon
  },
  emits: ['click'],
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (prop) => ['default', 'router', 'button'].includes(prop)
    },
    linkHref: {
      type: String,
      default: null
    },
    linkTarget: {
      type: String,
      default: null
    },
    isFontSizeInherit: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      required: false,
      default: 'main',
      validator: (prop) =>
        ['main', 'main-secondary', 'success', 'primary', 'warning'].includes(
          prop
        )
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: (prop) =>
        ['md', 'sm'].includes(
          prop
        )
    },
    isUnderline: {
      type: Boolean,
      default: true
    },
    isBold: {
      type: Boolean,
      default: false
    },
    linkIcon: {
      type: String,
      default: null
    },
    linkIconClass: {
      type: String,
      default: null
    },
    linkIconDirection: {
      type: String,
      required: false,
      default: 'left',
      validator: (prop) =>
        ['left', 'right'].includes(
          prop
        )
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['wg-link-' + props.variant]: props.variant,
        ['wg-link-' + props.size]: props.size,
        'wg-link-underline': props.isUnderline,
        ['wg-link-icon-' + props.linkIconDirection]: props.linkIconDirection && props.linkIcon,
        'font-size-inherit': props.isFontSizeInherit,
        'fw-bold': props.isBold
      }
    })

    return {
      className
    }
  }
}
</script>
