<template>
  <WGContainer>
    <div class="wg-footer-row">
      <p class="wg-footer-text">
        Mirrana corp.s.r.o., Rybná 716/24, Praha, Czech Republic Phone number:
        <a class="wg-footer-text" href="tel:+420775931202">+420775931202</a>
      </p>
      <WGCopyright
        class="text-center wg-footer-text"
        :textPrepend="currentProjectLink"
        :dateFrom="2008"
        :textAppend="$t('global.siteRights')"
      />
    </div>
  </WGContainer>
</template>

<script>
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGCopyright from '@/components/UI/WGCopyright/WGCopyright'
import { inject } from 'vue'

export default {
  name: 'WFFooterCopyright',
  components: {
    WGContainer,
    WGCopyright
  },
  setup () {
    const { currentProjectLink } = inject('$projectLinks')

    return {
      currentProjectLink
    }
  }
}
</script>
