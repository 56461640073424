<template>
  <div class="wg-tabs">

    <div
      class="wg-tabs-header"
      :class="[`wg-tabs-header--${backgroundType}`]"
    >
      <div
        class="wg-tabs-header-item"
        :class="{
          [`wg-tabs-header-item-${backgroundType}--active`]: $props.modelValue.value === tab.value,
          [`wg-tabs-header-item-${backgroundType}`]: true
        }"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="changeTab(tab)"
      >
        <WGIcon v-if="tab.icon" :icon="tab.icon" />
        <slot :name="tabHeadSlotName(tab.value)">
          {{ $t(tab.name) }}
        </slot>
      </div>
    </div>

    <div class="wg-tabs-content">
      <slot :name="tabPanelSlotName"></slot>
    </div>

  </div>
</template>

<script>

import { computed } from 'vue'
import WGIcon from '@/components/UI/WGIcons/WGIcon'

export default {
  name: 'WGTabs',
  components: { WGIcon },
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    backgroundType: {
      type: String,
      default: 'success'
    },
    name: String,
    tabs: Object
  },
  setup (props, { emit }) {
    const tabHeadSlotName = (tabName) => {
      return `tab-head-${tabName}`
    }

    const changeTab = (tab) => {
      emit('update:modelValue', tab)
    }

    const tabPanelSlotName = computed(() => {
      return `tab-panel-${props.modelValue.value}`
    })

    return {
      changeTab,
      tabPanelSlotName,
      tabHeadSlotName
    }
  }
}
</script>
