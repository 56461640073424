
import WGPromptInfo from '@/components/UI/WGPromptInfo/WGPromptInfo'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGPromptInfo',
  component: WGPromptInfo,
  tags: ['autodocs'],
  argTypes: {
    icon: {
      control: {
        type: 'select'
      },
      options: [
        '',
        'wg-ffi-apple-fill',
        'wg-ffi-instagram',
        'wg-ffi-linkedin-fill'
      ]
    }
  }
}

export const ico = {
  args: {
    icoSrc: 'http://via.placeholder.com/32x32',
    title: 'title',
    description: 'description'
  }
}

export const Icon = {
  args: {
    icon: 'wg-ffi-apple-fill',
    title: 'title',
    description: 'description'
  }
}

export const Slots = {
  args: {
    icon: '',
    icoSlot: 'icon Slot',
    titleSlot: 'title Slot',
    descriptionSlot: 'description Slot'
  }
}
