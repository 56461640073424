<script>
import { computed, defineComponent, ref } from 'vue'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'WGModal',
  components: { WGIcon },
  props: {
    modelName: {
      type: String,
      required: true
    },
    isShowButtonClose: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const isOpenModal = ref(computed(() => store.getters.getCurrentModal === props.modelName))
    const onClose = () => {
      store.commit('removeModal')
      emit('closeModal')
    }

    return {
      isOpenModal,
      onClose
    }
  }
})
</script>

<template>
  <div
    v-if="isOpenModal"
    class="wg-modal"
    @click.stop
  >
    <button
      v-if="$props.isShowButtonClose"
      class="button-modal-close"
      @click="onClose"
    >
      <WGIcon icon="wg-ffi-close"/>
    </button>
    <div class="wg-modal-content">
      <slot></slot>
    </div>
  </div>
</template>
