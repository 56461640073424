<template>
  <WGModal class="victoria-modal-auth">

    <aside class="victoria-modal-auth-aside">
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-1" />
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-2" />
      <WGCardPreview :card-image="girlPreview" class="wg-card-preview-3" />
    </aside>

    <WGResetPasswordByEmail
      title="formAuth.changePassword.title"
      description="formAuth.changePassword.description"
    >
      <template #default>
        <div>
          <WGLabel>
            {{ $t('formAuth.changePassword.labelPassword') }}
          </WGLabel>
          <WGField
            v-model="userPassword"
            :type="isText ? 'text' : 'password'"
            name="pass"
            placeholder="****************"
            iconLeft="wg-ffi-lock-fill"
          >
           <template #iconRight>
             <img
               v-if="isPasswordValid"
               class="wg-change-password_password-icon wg-field-icon-right wg-ffi-icon"
               :src="iconSuccess"
               alt=""
             >
             <img
               @click="isText = !isText"
               class="wg-change-password_password-icon-eye cursor-pointer wg-field-icon-right wg-ffi-icon"
               :class="{'wg-change-password_password-icon-eye--active': isText}"
               :src="iconEye"
               alt=""
             >
           </template>
          </WGField>
          <span class="wg-field-error" v-if="!isPasswordValid && userPassword">
            {{ $t('formAuth.changePassword.regexPassword') }}
          </span>
          <WGLabel>
            {{ $t('formAuth.changePassword.labelPasswordConfirm') }}
          </WGLabel>
          <WGField
            v-model="userConfirmPassword"
            :type="isTextConfirm ? 'text' : 'password'"
            name="pass"
            placeholder="****************"
            iconLeft="wg-ffi-lock-fill"
          >
            <template #iconRight>
              <img v-if="isPasswordMatched" class="wg-change-password_password-icon wg-field-icon-right wg-ffi-icon" :src="iconSuccess" alt="">
              <img
                @click="isTextConfirm = !isTextConfirm"
                class="wg-change-password_password-icon-eye cursor-pointer wg-field-icon-right wg-ffi-icon"
                :class="{'wg-change-password_password-icon-eye--active': isTextConfirm}"
                :src="iconEye"
                alt=""
              />
            </template>
          </WGField>
          <span v-if="!isPasswordMatched && (userConfirmPassword !== '' || userPassword !== '')" class="wg-field-error">
            {{ $t('formAuth.changePassword.regexPasswordMatch') }}
          </span>
        </div>
        <WGButton @click="handleChangePassword" variant="success">
          {{ $t('formAuth.changePassword.send') }}
        </WGButton>
      </template>
    </WGResetPasswordByEmail>
  </WGModal>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import WGModal from '@/components/WGModal/WGModal.vue'
import WGField from '@/components/UI/WGForms/WGField.vue'
import WGLabel from '@/components/UI/WGForms/WGLabel.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGCardPreview from '@/components/UI/WGAsideBanners/WGCardPreview.vue'
import WGResetPasswordByEmail from '@/components/auth/WGResetPasswordByEmail.vue'
import iconSuccess from '@/assets/images/icons/info-circle-success.svg'
import iconEye from '@/assets/images/icons/eye-show-password.svg'
import girlPreview from '@/assets/images/cardPreview/cardPreviewResetPswrd.webp'

export default {
  name: 'ChangePassword',
  components: {
    WGLabel,
    WGField,
    WGButton,
    WGResetPasswordByEmail,
    WGCardPreview,
    WGModal
  },
  setup () {
    const store = useStore()

    const regex = /^(?=.*[A-Za-z\d])[A-Za-z\d]*$/
    const userPassword = ref('')
    const userConfirmPassword = ref('')
    const isText = ref(false)
    const isTextConfirm = ref(false)

    const isPasswordValid = computed(() => {
      return regex.test(userPassword.value)
    })
    const isPasswordMatched = computed(() => {
      return userPassword.value && userConfirmPassword.value && userPassword.value === userConfirmPassword.value
    })

    const handleChangePassword = () => {
      if (!isPasswordValid.value || !isPasswordMatched.value) {
        return
      }

      const data = {
        pass: userPassword.value,
        pass2: userConfirmPassword.value,
        email: store.state.initialize.changePassword.email,
        key: store.state.initialize.changePassword.key
      }
      store.dispatch('apiChangePassword', data).then(response => {
        if (!response.error) {
          store.commit('removeAllModal')
          store.commit('addModal', 'VictoriaModalResetFinished')
        }
      })
    }

    return {
      userPassword,
      userConfirmPassword,
      isPasswordMatched,
      girlPreview,
      iconSuccess,
      isPasswordValid,
      iconEye,
      isTextConfirm,
      isText,
      handleChangePassword
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/victoria/components/modal/change-password.scss';
</style>
