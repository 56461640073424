import '@/assets/styles/victoria/main.scss'

import WGIndicatorOnline from '@/components/UI/WGIndicators/WGIndicatorOnline.vue'

export default {
  title: 'Example/WGIndicatorOnline',
  component: WGIndicatorOnline,
  tags: ['autodocs']
}

export const Docs = {
  args: {
  }
}
