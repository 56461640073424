<template>
  <span class="wg-ffi-icon" :class="icon"></span>
</template>

<script>
export default {
  name: 'WGIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
