<template>
  <vSelect
    :class="className"
    :label="label"
    :disabled="disabled"
    :multiple="multiple"
  >

    <template #search="{ attributes, events }">
      <slot name="slotSelect">
        <WGIcon :class="iconClass" :icon="$props.icon" v-if="$props.icon"/>
      </slot>

      <input
        maxlength="1"
        class="vs__search"
        v-bind="attributes"
        v-on="events"
      />
    </template>

    <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <WGIcon :icon="'wg-ffi-arrow-down'"/>
      </span>
    </template>

    <template
      #option="option"
    >
      <div class="vs__selected-options">

        <img :src="option.src" :alt="option[label]" v-if="option.src">

        <span class="overflow-ellipsis">
          {{ option[label] }}
        </span>
      </div>
    </template>

  </vSelect>
</template>

<script>
import vSelect from 'vue-select'
import WGIcon from '@/components/UI/WGIcons/WGIcon'
import { computed } from 'vue'

// docs: https://vue-select.org/api/props.html
export default {
  name: 'WGSelect',
  components: {
    WGIcon,
    vSelect
  },
  props: {
    size: {
      type: String,
      default: 'lg',
      validator: (prop) => ['md', 'lg'].includes(prop)
    },
    label: {
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'text-nepal-400'
    }
  },
  setup (props) {
    const className = computed(() => {
      return [
        'select-' + props.size
      ]
    })

    return {
      className
    }
  }
}
</script>
