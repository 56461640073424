const victoriaLinks = {
  mainMenuLinks: (isMini) => ([
    {
      linkHref: '/search',
      icon: isMini ? '' : 'wg-ffi-girl',
      title: 'mainMenu.items.singleGirls.title',
      descriptionAccent: 'mainMenu.items.singleGirls.description',
      description: 'empty'
    },
    {
      linkHref: '/our-dating-site/our-services/',
      icon: isMini ? '' : 'wg-ffi-dollar-coins',
      title: 'mainMenu.items.services.title',
      description: isMini ? 'empty' : 'mainMenu.items.services.description'
    },
    {
      linkHref: '/our-dating-site/assistance',
      icon: isMini ? '' : 'wg-ffi-handshake',
      title: 'mainMenu.items.assistance.title',
      description: isMini ? 'empty' : 'mainMenu.items.assistance.description'
    },
    {
      linkHref: '/about-us/contact-us/',
      icon: isMini ? '' : 'wg-ffi-customer-care',
      title: 'mainMenu.items.tickets.title',
      description: isMini ? 'empty' : 'mainMenu.items.tickets.description'
    }
  ]),
  mainMenuMobileLinks: [
    {
      linkHref: '',
      title: 'mainMenu.items.joinNow.title',
      descriptionAccent: 'mainMenu.items.joinNow.description',
      description: 'empty',
      icon: '',
      onClick: (store, emit) => {
        store.commit('addModal', 'VictoriaModalAuth')
        emit('close')
      }
    },
    {
      linkHref: '/search',
      title: 'mainMenu.items.findGirls.title',
      descriptionAccent: 'mainMenu.items.singleGirls.description',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/our-dating-site/our-services/',
      icon: '',
      title: 'mainMenu.items.services.title',
      description: 'empty'
    },
    {
      linkHref: '/our-dating-site/assistance',
      title: 'mainMenu.items.assistance.title',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/about-us/contact-us/',
      title: 'mainMenu.items.contacts.title',
      description: 'empty',
      icon: ''
    },
    {
      linkHref: '/faq',
      title: 'mainMenu.items.faq.title',
      description: 'empty',
      icon: ''
    }
  ],
  currentProjectLink: 'Victoriya.Club',
  searchLink: '/search/online/1',
  footerLinksStartDating: [
    {
      linkHref: '/search/countries/ukraine,russian-speaking,belarus,moldova,poland,bulgaria',
      linkTitle: 'footer.footerMenu.menuList3.listItem1'
    },
    {
      linkHref: '/search/countries/germany,france,italy,spain,latvia,romania',
      linkTitle: 'footer.footerMenu.menuList3.listItem2'
    },
    {
      linkHref: '/search/countries/argentina,brazil,colombia,venezuela,mexico,portugal',
      linkTitle: 'footer.footerMenu.menuList3.listItem3'
    },
    {
      linkHref: '/search/countries/thailand,turkish,israeli,philippine,united-arab-emirates,vietnamese',
      linkTitle: 'footer.footerMenu.menuList3.listItem4'
    },
    {
      linkHref: '/search/age_min/30/age_max/39',
      linkTitle: 'footer.footerMenu.menuList3.listItem5'
    },
    {
      linkHref: '/search/age_min/40/age_max/49',
      linkTitle: 'footer.footerMenu.menuList3.listItem6'
    },
    {
      linkHref: '/search/age_min/50/age_max/59',
      linkTitle: 'footer.footerMenu.menuList3.listItem7'
    },
    {
      linkHref: '/search/age_min/60/age_max/69',
      linkTitle: 'footer.footerMenu.menuList3.listItem9'
    }
  ],
  footerPayLogos: [
    {
      titleLogo: 'visa',
      linkLogo: require('@/assets/images/payLogos/visa.svg')
    },
    {
      titleLogo: 'mastercard',
      linkLogo: require('@/assets/images/payLogos/mastercard.svg')
    },
    {
      titleLogo: 'americanexpress',
      linkLogo: require('@/assets/images/payLogos/americanexpress.svg')
    },
    {
      titleLogo: 'maestro',
      linkLogo: require('@/assets/images/payLogos/maestro.svg')
    },
    {
      titleLogo: 'bitcoin',
      linkLogo: require('@/assets/images/payLogos/bitcoin.svg')
    },
    {
      titleLogo: 'dinnerclub',
      linkLogo: require('@/assets/images/payLogos/dinnerclub.svg')
    }
  ],
  footerLinksAboutUs: [
    {
      linkHref: '/about-us/who-we-are/',
      linkTitle: 'footer.footerMenu.menuList2.listItem1'
    },
    {
      linkHref: '/about-us/anti-scam-policy/',
      linkTitle: 'footer.footerMenu.menuList2.listItem2'
    },
    {
      linkHref: '/about-us/recognize-a-scammer/',
      linkTitle: 'footer.footerMenu.menuList2.listItem3'
    },
    {
      linkHref: '/about-us/contact-us/',
      linkTitle: 'footer.footerMenu.menuList2.listItem4'
    },
    {
      linkHref: '/about-online-dating/',
      linkTitle: 'footer.footerMenu.menuList2.listItem5'
    }
  ],
  footerLinksOurDatingSite: [
    {
      linkHref: '/our-dating-site/license-agreement',
      linkTitle: 'footer.footerMenu.menuList1.listItem1'
    },
    {
      linkHref: '/our-dating-site/cancellation-policy',
      linkTitle: 'footer.footerMenu.menuList1.listItem2'
    },
    {
      linkHref: '/our-dating-site/terms-and-conditions',
      linkTitle: 'footer.footerMenu.menuList1.listItem3'
    },
    {
      linkHref: '/our-dating-site/acceptable-use-policy/',
      linkTitle: 'footer.footerMenu.menuList1.listItem9'
    },
    {
      linkHref: '/our-dating-site/our-services/',
      linkTitle: 'footer.footerMenu.menuList1.listItem4'
    },
    {
      linkHref: '/our-dating-site/assistance',
      linkTitle: 'footer.footerMenu.menuList1.listItem5'
    },
    {
      linkHref: '/faq/',
      linkTitle: 'footer.footerMenu.menuList1.listItem6'
    },
    {
      linkHref: '/our-dating-site/privacy-policy-new/',
      linkTitle: 'footer.footerMenu.menuList1.listItem7'
    },
    {
      linkHref: '/our-dating-site/cookie-policy/',
      linkTitle: 'footer.footerMenu.menuList1.listItem8'
    }
  ],
  profilePage: {
    createProfileLink (user) {
      return user.url
    },
    breadCrumbs (profile, id) {
      return [
        { label: 'Home', url: '/' },
        { label: `User_${id}` }
      ]
    }
  }
}

export default victoriaLinks
