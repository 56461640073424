<template>
  <div class="wg-swiper-external-navigation">

    <button type="button" aria-label="Previous slide" class="wg-swiper-external-navigation-btn" @click="slidePrev">
      <WGIcon icon="wg-ffi-arrow-left"/>
    </button>

    <button type="button" aria-label="Next slide" class="wg-swiper-external-navigation-btn" @click="slideNext">
      <WGIcon icon="wg-ffi-arrow-right"/>
    </button>

  </div>
</template>

<script>
import { toRef } from 'vue'

import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

export default {
  name: 'WGSwiperExternalNavigation',
  components: {
    WGIcon
  },
  props: {
    swiperName: {
      type: Object
    }
  },
  setup (props) {
    const swiperInstance = toRef(props, 'swiperName')

    const slidePrev = () => {
      swiperInstance.value.slidePrev()
    }

    const slideNext = () => {
      swiperInstance.value.slideNext()
    }

    return {
      slidePrev,
      slideNext
    }
  }
}
</script>
