<template>
  <div
    class="wg-card-advantages"
    :class="{
      'wg-card-advantages_with-icon': advantage.icon,
      ['wg-card-advantages_' + variant]: true
    }"
  >
    <div v-if="advantage.icon" class="wg-card-advantages_icon">
      <img :src="advantage.icon" :alt="$t(advantage.title)">
    </div>
    <div class="wg-card-advantages_text">
      <p class="wg-card-advantages_title">
        <slot :titleAdds="advantage"></slot>
        {{ $t(advantage.title) }}
      </p>
      <p class="wg-card-advantages_description">
        {{ $t(advantage.description) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WGCardAdvantages',
  props: {
    advantage: {
      required: true,
      default: () => ({
        title: '',
        description: ''
      })
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (prop) =>
        ['success', 'warning', 'custom', 'primary', 'success-revers']
          .includes(prop)
    }
  }
}
</script>

<style scoped>

</style>
