function canonicalLinks () {
  const canonicalMirrorLink = document.querySelector('link[rel="canonical"]')

  if (canonicalMirrorLink) {
    canonicalMirrorLink.href = window.location.href
  } else {
    const newCanonicalLink = document.createElement('link')
    newCanonicalLink.rel = 'canonical'
    newCanonicalLink.href = window.location.href
    document.head.appendChild(newCanonicalLink)
  }
}

export default canonicalLinks
