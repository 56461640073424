<template>
  <div class="wg-box-lines">

    <div class="wg-box-lines-inner" v-if="$slots.default">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WGBoxLines'
}
</script>
