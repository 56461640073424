<template>
  <div class="wg-html-renderer">
    <h1 v-if="title && isShowTitle" class="wg-html-renderer_title">
      {{ title }}
    </h1>
    <br v-show="isShowTitle" />
    <div v-html="page"></div>
  </div>
</template>

<script>
import girlPicture from '@/assets/images/whoWeAre/care__gitrl.jpg'
import footerAssistImage from '@/assets/images/assistance/bot-img.png'

const imagePaths = [
  require('@/assets/images/assistance/assist-img-1.png'),
  require('@/assets/images/assistance/assist-img-2.png'),
  require('@/assets/images/assistance/assist-img-3.png'),
  require('@/assets/images/assistance/assist-img-4.png'),
  require('@/assets/images/assistance/assist-img-5.png')
]

export default {
  name: 'WGHtmlRenderer',
  props: {
    page: String,
    title: String,
    isShowTitle: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    const imageElement = document.querySelector('.wwacare__img')
    const authButtons = Array.from(document.querySelectorAll('.wwa__btn.toggle-size-btn, .wwa__btn.wwa__btn-medium')).flat()
    if (authButtons.length) {
      authButtons.forEach(element => {
        element.removeAttribute('onclick')
        element.addEventListener('click', () => {
          this.$store.commit('addModal', 'VictoriaModalAuth')
        })
      })
    }
    // Assist
    const assistCols = Array
      .from(document.querySelectorAll('.assist-col-3'))
      .flat()
      .map(item => item.querySelector('img'))
    const footerAssist = document.querySelector('.assist-div-bot')?.querySelector('img')

    if (footerAssist) {
      footerAssist.src = footerAssistImage
    }

    if (this.title === 'Assistance') {
      const menuItems = document.querySelectorAll('.assist-menu')

      menuItems.forEach(item => {
        item.addEventListener('click', scrollToSection)
      })

      function scrollToSection (event) {
        event.preventDefault()
        const targetId = this.getAttribute('id')
        const targetSection = document.querySelector(`.assist-div-${targetId.slice(-1)}`)
        if (targetSection) {
          targetSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          })
        }
      }
    }

    if (assistCols.length) {
      assistCols.forEach((image, index) => {
        image.src = imagePaths[index]
      })
    }
    if (imageElement) {
      imageElement.src = girlPicture
    }
  }
}
</script>

<style lang="scss">
.wg-html-renderer {
  &_title {
    font-weight: 700;
  }
  h3 {
    color: #00ae99;
    font-weight: 700;
    font-size: 24px;
  }
  .assist-menu:before {
    background: url(@/assets/images/assistance/assist-spritel.png);
  }
  .assist-align{
    width: 100% !important;
    margin: 0 auto;
    overflow: hidden;
  }
  .assist-align a:hover{
    color: #ffffff !important;
  }
  .assist-top{
    padding-top: 13px;
    padding-left: 8px;
  }
  #uslugi-menu{
    float: right;
    width: 280px;
    height: 230px;
    margin-top: 15px;
    margin-right: 12px;
    position: relative;
    z-index: 2;
  }
  .assist-top h1{
    font-size: 47px;
    font-weight: 400;
  }
  .assist-top h2{
    margin-top: 4px;
    color: #21b7a2;
    margin-bottom: 31px;
  }
  .assist-top p{
    width: 466px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 18px;
  }
  .assist-top-btn{
    color: white;
    padding: 14px 29px;
    background-color: #21b7a2;
    border-bottom: 3px solid #008876;
    display: inline-block;
    font-size: 22px;
    text-decoration: none;
    margin-top: 4px;
  }
  .assist-top-btn:hover {
    cursor: pointer;
    background-color: #1da491;
    border-bottom: 3px solid #007d6c;
  }
  .assist-div-1{
    background-color: #ddf4f1;
    width: 100% !important;
    margin-top: 31px;
    border: 1px solid #c6f7f5;
    margin-left: 0;
    position: relative;
  }
  .assist-div-1:before{
    display: inline-block;
    content: '';
    position: absolute;
    background: url(@/assets/images/assistance/assist-girl.png);
    width: 424px;
    height: 445px;
    top: -447px;
    right: 93px;
  }
  .assist-col-1{
    vertical-align: top;
    display: inline-block;
    margin-top: 128px;
    width: 68px;
    height: 109px;
    background-color: #0478b3;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #c6f7f5;
    margin-left: -1px;
    margin-right: 73px;
  }
  .assist-div-1 .assist-col-1 .assist-col-inner{
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 51px;
    height: 43px;
    background-position: 0px -176px;
    margin-top: 36px;
    margin-left: 9px;
  }
  .assist-div-1 .assist-col-2{
    vertical-align: top;
    display: inline-block;
    width: 329px;
    margin-top: 99px;
  }
  .assist-div-title{
    color: #21b7a9;
    font-size: 29px;
    margin-bottom: 31px;
    line-height: 1;
  }
  .assist-menu {
    min-height: 44px;
  }
  .assist-div-text{
    font-size: 14px;
    line-height: 1.45;
  }
  .assist-col-1{
    vertical-align: top;
    display: inline-block;
  }
  .assist-col-2{
    vertical-align: top;
    display: inline-block;
    width: 329px;
  }
  .assist-col-3{
    vertical-align: top;
    display: inline-block;
  }
  .assist-div-1 .assist-col-3{
    margin-left: 104px;
    margin-top: 22px;
    margin-bottom: 15px;
  }
  .assist-col-3 img{}
  .assist-div-2{
    width: 100% !important;
    margin-top: 1px;
    border: 1px solid #c6f7f5;
    margin-left: 0;
    position: relative;
  }
  .assist-div-2 .assist-col-1 .assist-col-inner{
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 51px;
    height: 43px;
    background-position: 0px -280px;
    margin-top: 36px;
    margin-left: 9px;
  }
  .assist-div-3 .assist-col-1 .assist-col-inner{
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 51px;
    height: 50px;
    background-position: 0px -223px;
    margin-top: 31px;
    margin-left: 9px;
  }
  .assist-div-4 .assist-col-1 .assist-col-inner{
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 51px;
    height: 52px;
    background-position: 0px -322px;
    margin-top: 28px;
    margin-left: 12px;
  }
  .assist-div-5 .assist-col-1 .assist-col-inner{
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 51px;
    height: 43px;
    background-position: 0px -381px;
    margin-top: 35px;
    margin-left: 15px;
  }
  .assist-div-2 .assist-col-3{
    display: inline-block;
    vertical-align: top;
    margin-top: 31px;
  }
  .assist-div-2 .assist-col-1{
    display: inline-block;
    margin-right: 31px;
    vertical-align: top;
  }
  .assist-div-2 .assist-col-2{
    display: inline-block;
    vertical-align: top;
    width: 329px;
    margin-top: 74px;
    margin-left: 100px;
    margin-bottom: 85px;
  }
  .assist-div-3{
    background-color: #ddf4f1;
    width: 100% !important;
    margin-top: 1px;
    border: 1px solid #c6f7f5;
    margin-left: 0;
    position: relative;
  }
  .assist-div-4{
    width: 100% !important;
    margin-top: 1px;
    border: 1px solid #c6f7f5;
    position: relative;
    margin-left: 0;
  }
  .assist-div-5{
    background-color: #ddf4f1;
    width: 100% !important;
    margin-top: 1px;
    border: 1px solid #c6f7f5;
    margin-left: 0;
    position: relative;
  }
  .assist-div-3 .assist-col-2{
    margin-top: 94px;
    margin-right: 93px;
  }
  .assist-div-4 .assist-col-2{
    margin-left: 94px;
    margin-top: 107px;
  }
  .assist-div-5 .assist-col-2{
    margin-top: 85px;
    margin-right: 74px;
  }
  .assist-div-3 .assist-col-3{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .assist-div-4 .assist-col-3{
    margin-left: -64px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .assist-div-5 .assist-col-3{
    margin-top: 25px;
    margin-bottom: 44px;
  }
  .assist-div-bot{
    padding-bottom: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .assist-div-bot img{
    margin-left: 195px;
    margin-top: 21px;
    vertical-align: top;
  }
  .assist-div-bot .assist-top-btn{
    margin-top: 25px;
  }
  #uslugi-menu div{
    position: relative;
    background-color: #21b7a2;
    border-bottom: 2px solid #2beed3;
    height: 34px;
    color: #f4f4f4;
    padding-left: 49px;
    padding-top: 10px;
    font-size: 18px;
  }
  #uslugi-menu .assist-menu:hover{
    border-bottom: 0;
    border-top: 2px solid #03527a;
    background-color: #0478b3;
    cursor: pointer;
    padding-top: 8px;
    height: 36px;
  }
  .assist-menu:before{
    position: absolute;
    display: inline-block;
    content: '';
    background: url(@/assets/images/assistance/assist-spritel.png);
    width: 37px;
    height: 26px;
    left: 3px;
    top: 10px;
    background-position: 0px -10px;
  }
  .assist-menu-1:before{
    background-position: 0px -39px;
  }
  .assist-menu-2:before{
    background-position: 0px -65px;
  }
  .assist-menu-3:before{
    background-position: 0px -101px;
  }
  .assist-menu-4:before{
    background-position: 0px -137px;
    height: 31px;
    top: 6px;
    left: -1px;
  }
}
</style>
