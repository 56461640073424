<template>

  <div class="wg-header-menu-wrap">

    <WGMainMenu @close="$emit('close')" :isMini="$props.isMini" />

    <WGLogo :fullLogoSrc="fullLogoSrc" @click.prevent v-if="isShowLogo && $props.isMini"/>

    <WGCopyright
      class="text-center"
      :textPrepend="currentProjectLink"
      :dateFrom="2008"
      :textAppend="$t('global.siteRights')"
      v-if="$props.isMini"
    />

  </div>

</template>

<script>

import { inject, onMounted, ref } from 'vue'
// eslint-disable-next-line no-unused-vars
import { XS, SM, MD, LG } from '@/components/UI/breakpoints.js'

import WGMainMenu from '@/components/UI/WGMainMenu/WGMainMenu'
import WGLogo from '@/components/UI/WGLogos/WGLogo'
import WGCopyright from '@/components/UI/WGCopyright/WGCopyright'

export default {
  name: 'WGHeaderMenuVictoria',
  components: {
    WGMainMenu,
    WGLogo,
    WGCopyright
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const fullLogoSrc = ref(require('@/assets/images/logo-vertical.svg'))
    const { currentProjectLink } = inject('$projectLinks')
    const isShowLogo = ref(true)

    const onChangeBreakpoints = () => {
      const breakpointXS = window.matchMedia('(max-width:' + (XS + 1) + 'px)')

      isShowLogo.value = !breakpointXS.matches

      breakpointXS.onchange = (e) => {
        isShowLogo.value = !e.matches
      }
    }

    onMounted(() => {
      onChangeBreakpoints()
    })

    return {
      currentProjectLink,
      fullLogoSrc,
      isShowLogo
    }
  }
}
</script>
