import { ApiSeo } from '@/core/api/api-Seo'
import { SiteSetting } from '@/core/api/api-SiteSetting'

export default {
  state: {
    seoText: {}
  },
  getters: {
    getSeoTextForSearch (state) {
      return state.seoText
    }
  },
  mutations: {
    setSeoText (state, payload) {
      state.seoText = payload
    }
  },
  actions: {
    getTextByCountries ({ commit }, { countries }) {
      const api = new ApiSeo()
      return api.getSearchSeoByCountries(countries).then(response => {
        commit('setSeoText', response)
      })
    },
    getSearchSeoText ({ commit }, payload) {
      return (new SiteSetting()).getSeoText(payload).then(response => {
        commit('setSeoText', response)
      })
    },
    getTextByAge ({ commit }, ages) {
      const api = new ApiSeo()
      return api.getSearchSeoByAge(ages).then(response => {
        commit('setSeoText', response)
      })
    }
  }
}
