<template>
  <div>
    <p>To see your messages history you should press 'Messages' button in the right top corner on your page (please see a screenshot below). On the Messages page you can switch between two options: 'Show absolutely all messages' and 'Show only dialogs with me'.</p>
    <div class="wg-finish-chat-faq_images">
      <img src="@/assets/images/faq/Messageshistory_pc.png" alt="finish chat">
      <img src="@/assets/images/faq/Messageshistory_mobile.png" alt="finish chat mobile">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>
