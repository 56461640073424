<template>
  <button
    class="wg-button"
    :class="className"
    :type="$props.type"
    :disabled="$props.disabled"
  >
    <WGIcon :icon="$props.icon" v-if="$props.icon"/>

    <span class="wg-button-text" v-if="$slots.default">
      <slot></slot>
    </span>

    <WGTag class="wg-button-tag" v-if="$props.tag">
      {{ $props.tag }}
    </WGTag>

  </button>
</template>

<script>

import { computed } from 'vue'

import WGIcon from '@/components/UI/WGIcons/WGIcon'

import WGTag from '@/components/UI/WGTags/WGTag'

export default {
  name: 'WGButton',
  components: {
    WGTag,
    WGIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
      validator: (prop) => ['xs', 'sm', 'md', 'lg'].includes(prop)
    },
    variant: {
      type: String,
      required: false,
      default: 'success',
      validator: (prop) =>
        ['success', 'error', 'warning', 'custom', 'primary', 'success-revers'].includes(
          prop
        )
    },
    icon: {
      type: String,
      default: ''
    },
    iconDirection: {
      type: String,
      default: 'left',
      required: false,
      validator: (prop) => ['left', 'right'].includes(prop)
    },
    tag: {
      type: String,
      default: ''
    },
    tagDirection: {
      type: String,
      default: 'right',
      required: false,
      validator: (prop) => ['left', 'right'].includes(prop)
    },
    outline: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['wg-button-' + props.size]: props.size !== '',
        ['wg-button-' + props.variant]: props.variant !== '' && !props.outline,
        ['wg-button-outline-' + props.variant]: props.outline,
        ['wg-button-icon-' + props.iconDirection]: props.icon !== '',
        ['wg-button-tag-' + props.tagDirection]: props.tag !== '',
        disabled: props.disabled,
        'wg-button-full': props.full
      }
    })

    return {
      className
    }
  }
}
</script>
