
<!-- TODO: create component for range slider Sirko / D.D. -->
<template>
  <div class="wg-filter-girl-main">

    <div class="wg-range-slider-box">

      <p class="wg-filter-girl-main-text">{{ $t('filterMain.text') }}</p>

      <slider
        class="wg-range-slider"
        v-model="filterGirls.age"
        :min="18"
        :max="68"
        :tooltips="true"
        :lazy="true"
        :tooltipPosition="'bottom'"
        @end="setParams"
      />

    </div>

    <WGSelect
      :placeholder="$t('filterMain.selectPlaceholder')"
      v-model="filterGirls.webcam"
      :options="webcamOptions"
      :reduce="item => item.value"
      @option:selected="setParams"
      :clearable="false"
      :searchable="false"
      iconClass="text-green-700"
      :icon="filterGirls.webcam === 0 ? 'wg-ffi-webcam-fill' : 'wg-ffi-webcam-checked-fill'"
    >
    </WGSelect>

    <div class="wg-checkbox-wrap">

      <WGCheckbox
        labelFor="filterGirlsOnline"
        :trueValue="0"
        :falseValue="1"
        variant="toggle"
        isTextLeft
        v-model="filterGirls.online"
        @update:modelValue="setParams"
      >
        {{ $t('filterMain.label') }}
      </WGCheckbox>

      <WGLink
        linkHref="#0"
        variant="success"
        isBold
        size="sm"
      >
        {{ $t('filterMain.text2') }}
      </WGLink>

    </div>

  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

import Slider from '@vueform/slider'
import WGSelect from '@/components/UI/WGSelect/WGSelect.vue'
import WGCheckbox from '@/components/UI/WGForms/WGCheckbox.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'

export default defineComponent({
  name: 'WGFilterGirlMain',
  components: {
    WGCheckbox,
    WGSelect,
    slider: Slider,
    WGLink
  },
  emits: ['changeFilter'],
  data () {
    return {
      webcamOptions: [
        { label: this.$t('filterMain.select.option1'), value: 1 },
        { label: this.$t('filterMain.select.option2'), value: 0 }
      ]
    }
  },
  setup (props, { emit }) {
    const filterGirls = ref({
      webcam: 0,
      online: 1,
      age: [18, 68]
    })
    const setParams = () => {
      const param = {
        age_min: filterGirls.value.age[0],
        age_max: filterGirls.value.age[1]
      }
      if (filterGirls.value.webcam !== null) {
        param.camera = filterGirls.value.webcam
      }
      if (filterGirls.value.online !== null) {
        param.online = filterGirls.value.online
      }
      emit('changeFilter', param)
    }

    return {
      filterGirls,
      setParams
    }
  }
})
</script>
