<script>
import { defineComponent } from 'vue'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'

export default defineComponent({
  name: 'WGModal',
  components: { WGIcon },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const onClose = () => {
      emit('update:modelValue', false)
    }

    return {
      onClose
    }
  }
})
</script>

<template>
  <div
    class="wg-modal-bg"
    @click="onClose"
  >
    <div
      v-if="modelValue"
      class="wg-modal"
      @click.stop
    >
      <button
        class="button-modal-close"
        @click="onClose"
      >
        <WGIcon icon="wg-ffi-close"/>
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>

</style>
