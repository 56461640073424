<script>
import { defineComponent } from 'vue'
import WGModal from '@/components/WGModal/WGModal.vue'

export default defineComponent({
  name: 'VictoriaModalPageText',
  components: { WGModal },
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    }
  }
})
</script>

<template>
  <WGModal
    class="wg-modal-info"
  >
    <h1 class="wg-modal-info-title">{{ $props.title }}</h1>
    <div class="wg-modal-info-content" v-html="$props.content"></div>
  </WGModal>
</template>
