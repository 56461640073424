<template>
  <div class="wg-card-actions">
    <WGList
      :list="cardActionsList"
      orientations="horizontal"
    >
      <template #item="{item}">
        <WGTooltip
        :tooltipText="item.tooltip"
        >
          <WGButton
            :icon="item.icon"
            variant="success-revers"
            full
            size="xs"
          ></WGButton>
        </WGTooltip>
      </template>
    </WGList>
  </div>
</template>

<script>

import WGList from '@/components/UI/WGLists/WGList.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import { ref } from 'vue'
import WGTooltip from '@/components/UI/WGTooltip/WGTooltip.vue'

export default {
  name: 'WGCardActions',
  components: {
    WGTooltip,
    WGButton,
    WGList
  },
  setup () {
    const cardActionsList = ref([
      {
        tooltip: 'favorites',
        icon: 'wg-ffi-star-fill'
      },
      {
        tooltip: 'Send message',
        icon: 'wg-ffi-sms-fill'
      },
      {
        tooltip: 'Wink',
        icon: 'wg-ffi-eye'
      },
      {
        tooltip: 'Like',
        icon: 'wg-ffi-like-fill'
      },
      {
        tooltip: 'Gift',
        icon: 'wg-ffi-gift-fill'
      }
    ])

    return {
      cardActionsList
    }
  }
}
</script>
