import { API } from './API'

export class ApiSeo {
  Api;
  endpoint = 'seo/page_text';

  constructor () {
    this.Api = new API()
  }

  getSearchSeoByAge (age) {
    const url = `/un_auth/${this.endpoint}/age/` + age
    return this.Api.getM(url)
  }

  getSearchSeoByCountries (countries) {
    const url = `/un_auth/${this.endpoint}/` + countries
    return this.Api.getM(url)
  }
}
