<template>
  <div class="wg-header-actions">

    <WGButton
      variant="warning"
      :tag="$t('global.free')"
      size="sm"
      class="text-uppercase"
      @click="headerActionRegistration"
    >
      {{ $t('global.createAccount') }}
    </WGButton>

    <WGButton
      variant="success"
      outline
      size="sm"
      @click="headerActionLogin"
    >
      {{ $t('global.singIn') }}
    </WGButton>

  </div>
</template>

<script>

import WGButton from '@/components/UI/WGButtons/WGButton'
import { useStore } from 'vuex'

export default {
  name: 'WGHeaderActions',
  components: {
    WGButton
  },
  setup () {
    const store = useStore()
    const headerActionRegistration = () => {
      window.location.href = '/registration'
    }
    const headerActionLogin = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }

    return {
      headerActionRegistration,
      headerActionLogin
    }
  }
}
</script>
