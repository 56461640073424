<template>
  <header class="wg-header" :class="className">
    <slot></slot>
  </header>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'WGHeader',
  props: {
    isSticky: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        'is-sticky-top': props.isSticky
      }
    })

    return {
      className
    }
  }
}
</script>
