import WGIconAll from './WGIconAll.vue'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGIcon',
  component: WGIconAll,
  tags: ['autodocs']
}

export const IconsAll = {

}
