<template>
  <div class="wg-prompt-info">

    <div class="wg-prompt-info-ico" v-if="$props.icon || $props.icoSrc || $slots.icoSlot">

      <WGIcon :icon="$props.icon" v-if="$props.icon"/>

      <img :src="icoSrc" alt="ico" v-if="$props.icoSrc">

      <slot name="icoSlot"></slot>

    </div>

    <div class="wg-prompt-info-description-wrap" v-if="$props.title || $slots.titleSlot || $props.description || $slots.descriptionSlot">

      <span class="wg-prompt-info-title" v-if="$props.title || $slots.titleSlot">
        {{ $props.title }}
        <slot name="titleSlot"></slot>
      </span>

      <span class="wg-prompt-info-description" v-if="$props.description || $slots.descriptionSlot">

        {{ $props.description }}
        <slot name="descriptionSlot"></slot>
      </span>

    </div>

  </div>
</template>

<script>

import WGIcon from '@/components/UI/WGIcons/WGIcon'

export default {
  name: 'WGPromptInfo',
  components: {
    WGIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    icoSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
