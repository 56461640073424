<template>
  <div>
    <p>To buy contact information you need to have a 299 credit balance and 100% progress of communication.
      <br>
      <br>
      The procedure of getting a lady's contact information is as follows:
      <br>
      <br>
      1. To get the girl's contact information you have to communicate with the girl first, prove your intentions and get to know each other better. That is also for your safety. You can understand, if you like this girl or not. According to our estimates, you need at least two weeks of communication. We display the progress of your communication, so you could know how close you are.
      <br>
      <br>
      2. When you reach 100% progress of communication, you will be able to press "Buy contacts" on the girl's profile page and then "Ok". The price for buying contacts is 299 credits, so you need to have enough credits on your balance when buying contacts.
      <br>
      <br>
      3. If the lady confirms your request, 299 credits are charged from your balance and her contact information will be available for you. Your request will be processed within 5 days.</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
