import axios from 'axios'
import { apiConfig } from '@/config'

export class API {
  basePath = `${apiConfig.basePath}${apiConfig.apiVersion}`

  getM (endpoint, params = {}) {
    const self = this
    return new Promise(function (resolve, reject) {
      axios.get(`${self.basePath}${endpoint}`, {
        params: {
          ...params
        }
      }).then(function (resp) {
        resolve(resp.data)
      }).catch(function (err) {
        self.handleError(err)
        reject(err.response)
      })
    })
  }

  post (endpoint, data) {
    const self = this
    return new Promise((resolve, reject) => {
      axios.post(`${self.basePath}${endpoint}`, data).then((resp) => {
        resolve(resp.data)
      }).catch((err) => {
        reject(err.response)
      })
    })
  }

  put (endpoint, data) {
    const self = this
    return new Promise(function (resolve, reject) {
      axios.put(`${self.basePath}${endpoint}`, data).then(function (resp) {
        resolve(resp.data)
      }).catch(function (err) {
        self.handleError(err)
        reject(err.response)
      })
    })
  }

  delete (endpoint) {
    const self = this
    return new Promise(function (resolve, reject) {
      axios.delete(`${self.basePath}${endpoint}`).then(function (resp) {
        resolve(resp.data)
      }).catch(function (err) {
        self.handleError(err)
        reject(err.response)
      })
    })
  }

  handleError (err) {
    console.error(err)
  }
}
