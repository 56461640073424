
import WGTag from '@/components/UI/WGTags/WGTag'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGTag',
  component: WGTag,
  tags: ['autodocs'],
  argTypes: {
    variant: {
      control: {
        type: 'select'
      },
      options: ['success', 'warning']
    }
  }
}

export const Docs = {
  args: {
    default: 'tag'
  }
}
