
import '@/assets/styles/victoria/main.scss'

import WGLink from '@/components/UI/WGLinks/WGLink.vue'

import { LIST_ICON_TYPE } from './data/listIconType'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGLink',
  component: WGLink,
  tags: ['autodocs'],
  argTypes: {
    variant: {
      control: {
        type: 'select'
      },
      options: [
        'main',
        'success',
        'primary',
        'warning'
      ]
    },
    linkIcon: {
      control: {
        type: 'select'
      },
      options: LIST_ICON_TYPE
    }
  }
}

// More on writing stories with args: https://storybook.js.org/docs/vue/writing-stories/args

export const linkIcon = {
  args: {
    variant: 'warning',
    linkHref: '#0',
    default: 'Lorem ipsum dolor sit amet, consectetur',
    linkIcon: 'wg-ffi-gift'
  }
}

export const Main = {
  args: {
    variant: 'main',
    linkHref: '#0',
    default: 'Lorem ipsum dolor sit amet, consectetur'
  }
}

export const Success = {
  args: {
    variant: 'success',
    linkHref: '#0',
    default: 'Lorem ipsum dolor sit amet, consectetur'
  }
}

export const Primary = {
  args: {
    variant: 'primary',
    linkHref: '#0',
    default: 'Lorem ipsum dolor sit amet, consectetur'
  }
}

export const Warning = {
  args: {
    variant: 'warning',
    linkHref: '#0',
    default: 'Lorem ipsum dolor sit amet, consectetur'
  }
}
