<template>
  <div class="wg-sing-out-faq">
    <p>To log out from your profile:</p>
    <img src="@/assets/images/faq/How_to_log_out_from_my_profile.gif" alt="How_to_log_out_from_my_profile">
    <p>1. Click on your profile tab in the bottom bar menu.</p>
    <div class="wg-sing-out-faq_images">
      <img src="@/assets/images/faq/signout_step_1.png" alt="">
      <img src="@/assets/images/faq/signout_step_1(mobile).png" alt="">
    </div>
    <p>2. Click on “Exit” in the appeared menu.</p>
    <div class="wg-sing-out-faq_images">
      <img src="@/assets/images/faq/signout_step_2.png" alt="">
      <img src="@/assets/images/faq/signout_step_2(mobile).png" alt="">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>
