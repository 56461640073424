<template>
  <div class="wg-container" :class="className">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'WGContainer',
  props: {
    expand: {
      type: String,
      required: false,
      default: 'none',
      validator: (prop) =>
        ['none', 'left', 'right'].includes(
          prop
        )
    },
    isClearOffsets: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const className = computed(() => {
      return {
        ['expand-' + props.expand]: props.expand !== 'none',
        'is-clear-offsets': props.isClearOffsets
      }
    })

    return {
      className
    }
  }
}
</script>
