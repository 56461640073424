<template>
  <div class="wg-radio-box" :class="{ 'is-disabled': $props.disabled }">

    <input
      type="radio"
      class="wg-radio-field"
      hidden
      :name="$props.name"
      :disabled="$props.disabled"
      :id="$props.labelFor"
      :value="$props.value"
      :required="$props.required"
      v-model="model"
    >

    <label class="wg-radio-label" :for="$props.labelFor">
      <span class="wg-radio-check-mark"></span>

      <span class="wg-radio-txt" v-if="($slots.default || $props.label) && $props.isClickableLabel">
        <slot :contentName="$props.label">
          {{ $props.label }}
        </slot>
      </span>
    </label>

    <span class="wg-radio-txt" v-if="($slots.default || $props.label) && !$props.isClickableLabel">
      <slot :contentName="$props.label">
        {{ $props.label }}
      </slot>
    </span>

  </div>
</template>

<script>

import { computed } from 'vue'

export default {
  name: 'WGRadio',
  emits: ['update:modelValue'],
  inheritAttrs: false,
  props: {
    modelValue: {},
    name: {
      type: String,
      required: false
    },
    labelFor: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: {},
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isClickableLabel: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const model = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    return {
      model
    }
  }
}
</script>
