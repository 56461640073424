import { computed, ref } from 'vue'

import { AuthForm } from '@/core/api/api-AuthForm'
import WGButtons from '@/components/UI/WGButtons/WGButton'
import WGField from '@/components/UI/WGForms/WGField'
import WGLabel from '@/components/UI/WGForms/WGLabel'
import WGCheckbox from '@/components/UI/WGForms/WGCheckbox'
import WGLink from '@/components/UI/WGLinks/WGLink'
import WGFieldEmail from '@/components/UI/WGForms/WGFieldEmail.vue'
import WGFieldStandard from '@/components/UI/WGForms/WGFieldStandard.vue'
import { useStore } from 'vuex'

export default {
  name: 'WGLoginForm',
  components: {
    WGFieldStandard,
    WGFieldEmail,
    WGButtons,
    WGField,
    WGLabel,
    WGCheckbox,
    WGLink
  },
  props: {
    settings: {}
  },
  setup () {
    const store = useStore()
    const api = new AuthForm()
    const isSavePassword = ref(false)
    const userEMail = ref('')
    const userPassword = ref('')
    const badPass = false
    const invalidUserEmail = ref('')
    // TODO: this.bad_pass = this.$cookies.get('badpass')
    const invalidData = ref(computed(() => !(userEMail.value && userPassword.value && !invalidUserEmail.value)))

    const login = () => {
      const data = {
        email: userEMail.value,
        pass: userPassword.value,
        mem: isSavePassword.value
      }
      // eslint-disable-next-line no-undef
      function gtag () { dataLayer.push(arguments) }
      api.login(data).then((resp) => {
        if ((typeof resp === 'string' && resp.indexOf('document.location=') >= 0) || resp.location) {
          gtag('event', 'login')
          store.dispatch('toast', { text: 'Successful authorization', type: 'SUCCESS' })
          setTimeout(() => {
            if (resp.location && resp.location !== 'reload') {
              window.location.replace(resp.location)
            } else {
              window.location.reload()
            }
          }, 1000)
        } else {
          let error = ''
          if (resp.error) {
            error = resp.error
          } else {
            const alertData = /alert\('([\s\S]*?)'\)/.exec(resp)
            if (alertData[1]) {
              error = alertData[1]
            }
          }
          if (error !== '') {
            store.dispatch('toast', { text: error, type: 'ERROR' })
          }
        }
      })
    }

    return {
      isSavePassword,
      userEMail,
      userPassword,
      badPass,
      invalidUserEmail,
      invalidData,
      login
    }
  }

}
