
export const getAgeByYears = birth => {
  const parts = birth.split('-')
  const birthYear = parseInt(parts[0])
  const birthMonth = parseInt(parts[1]) - 1
  const birthDay = parseInt(parts[2])

  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()
  const currentDay = today.getDate()

  let age = currentYear - birthYear

  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age--
  }

  return age
}

export default function useDateWorker () {
  return {
    getAgeByYears
  }
}
