<template>
  <div>
    <p>This video guide shows what to do in case you forgot your password on VictoriyaClub:</p>
    <br>
    <iframe style="width: 100%" height="315" src="https://www.youtube.com/embed/xxs4lZLaBWE" frameborder="0" allowfullscreen></iframe>
    <br>
    In case you forgot your password, you can easily reset it. To do so, please make the following steps:
    <br>
    1. Open https://VictoriyaClub.com/ and switch to “Log In” tab.
    <br>
    2. Press “Forgot password?”
    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password(mobile).png" alt="">
    </div>
    <p>3. In a window appeared enter the email address which you used during registration on VictoriyaClub and press “Send” button.</p>
    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password_step_2.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password_step_2(mobile).png" alt="">
    </div>
    <p>
      After that you will receive an email from VictoriyaClub with a subject “Change Password Confirmation on VictoriyaClub.com” (in case you did not receive the mentioned email, please make sure you have checked a spam folder as well).
      4. Open the message and press the following link:
    </p>
    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password_step_3.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password_step_3(mobile).png" alt="">
    </div>
    <p>5. In a window appeared:</p>
    <ul class="wg-forgot-password_list">
      <li class="wg-forgot-password_list-item-dot">
        Enter your new password.
      </li>
      <li class="wg-forgot-password_list-item-dot">
        Confirm your new password.
      </li>
      <li class="wg-forgot-password_list-item-dot">
        Press “Change password” button.
      </li>
    </ul>
    <p>Hide/unhide your password symbols if necessary.</p>

    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password_step_4.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password_step_4(mobile).png" alt="">
    </div>
    <p>6. If everything was correct the following window will appear. Press “OK” button or just close the window.</p>

    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password_step_5.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password_step_5(mobile).png" alt="">
    </div>
    <p>7. After that you will receive a notification to your email with your new password (please make sure your password is securely saved).</p>
    <div class="wg-forgot-password_images">
      <img src="@/assets/images/faq/forgot_my_password_step_6.png" alt="">
      <img src="@/assets/images/faq/forgot_my_password_step_6(mobile).png" alt="">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>
