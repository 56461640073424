<template>
  <div></div>
</template>

<script>
import { inject } from 'vue'

export default {
  setup () {
    const { currentProjectLink } = inject('$projectLinks')
    const setupGoogleTagManager = () => {
      const gtmId = currentProjectLink === 'VictoriaClub.com' ? 'GTM-T2DPV73' : 'GTM-MPBKWJD'
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`

      document.head.appendChild(script)

      script.onload = () => {
        window.dataLayer = window.dataLayer || []
        function gtag () {
          window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', gtmId)
      }
    }
    setupGoogleTagManager()

    return {}
  }
}
</script>
