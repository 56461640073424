
import WGBoxLines from '@/components/UI/WGBoxLines/WGBoxLines'

import '@/assets/styles/victoria/main.scss'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGBoxLines',
  component: WGBoxLines,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    default: 'lorem'
  }
}
