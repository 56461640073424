<template>
  <WGModal class="victoria-modal-auth">

    <aside class="victoria-modal-auth-aside">
      <WGCardPreview class="wg-card-preview-1"></WGCardPreview>
      <WGCardPreview class="wg-card-preview-2"></WGCardPreview>
      <WGCardPreview class="wg-card-preview-3"></WGCardPreview>
    </aside>

    <WGAuthForm></WGAuthForm>

  </WGModal>
</template>

<script>
import { defineComponent } from 'vue'
import WGModal from '@/components/WGModal/WGModal.vue'
import WGAuthForm from '@/components/auth/WGAuthForm.vue'
import WGCardPreview from '@/components/UI/WGAsideBanners/WGCardPreview.vue'

export default defineComponent({
  name: 'VictoriaModalAuth',
  components: {
    WGCardPreview,
    WGAuthForm,
    WGModal
  }
})
</script>
