import { PageText } from '@/core/api/api-PageText'

export default {
  state: {
    pageText: {},
    faqPages: [],
    services: {}
  },
  getters: {
    getPageText (state) {
      return state.pageText
    },
    getFaqPages (state) {
      return state.faqPages
    },
    getModuleFromPageText: (state) => (module, param = null) => {
      if (module && state.pageText[module]) {
        if (param) {
          return state.pageText[module][param] || null
        } else {
          return state.pageText[module]
        }
      }

      return null
    }
  },
  mutations: {
    addModuleToPageText (state, { modalName, modalData }) {
      state.pageText[modalName] = modalData
    },
    setPage (state, data) {
      state.pageText = data
    },
    setFaqPages (state, data) {
      state.faqPages = data
    },
    setServicesData (state, data) {
      state.services = data
    }
  },
  actions: {
    apiGetServicesData ({ commit }) {
      const api = new PageText()
      return api.getServices().then(response => {
        commit('setServicesData', response)
      })
    },
    apiGetFaqPages ({ commit }) {
      const api = new PageText()
      return api.getFaqPages().then(response => {
        commit('setFaqPages', response)
      })
    },
    apiGetPageText ({ commit, getters }, modalName) {
      if (getters.getModuleFromPageText(modalName)) {
        return
      }
      const api = new PageText()
      return api.getModule(modalName).then(resp => {
        commit('addModuleToPageText', { modalName: modalName, modalData: resp })
      })
    },
    apiGetPageTextById ({ commit, getters }, id) {
      const api = new PageText()
      return api.getPage(id).then(response => {
        commit('setPage', response)
      })
    }
  }
}
