<template>
  <div class="wg-accordion" :class="className" >
    <div>
      <input
        class="wg-accordion-input"
        :type="type"
        ref="checkedAccordion"
        :value="accordionValue"
        :modelValue="modelValue"
        :name="accordionGroup"
        @change="updateModelValue"
      />
      <p v-if="$props.isShowTitle" class="accordion-title text-uppercase fw-bold" @click="openAccordion">
        <slot name="title">
          <span>{{ title }}</span>
        </slot>
        <WGIcon v-if="isAccordion" :class="{'active': modelValue && modelValue === accordionValue}" :icon="'wg-ffi-arrow-down'"/>
      </p>
      <transition name="fade" v-if="!isAccordion || (modelValue && modelValue === accordionValue)">
        <slot name="content"></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import WGIcon from '@/components/UI/WGIcons/WGIcon'
import { computed, ref } from 'vue'

export default {
  name: 'WGAccordion',
  components: {
    WGIcon
  },
  props: {
    title: {
      type: String,
      default: null
    },
    isAccordion: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'radio',
      validator: (prop) => ['radio', 'checkbox'].includes(prop)
    },
    modelValue: {
      type: [Boolean, String, Number, Array],
      default: null
    },
    accordionValue: {
      type: [String, Number],
      default: null
    },
    accordionGroup: {
      type: String,
      default: 'accordionGroup'
    },
    isShowTitle: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const className = computed(() => {
      return {
        'is-accordion': props.isAccordion
      }
    })

    const checkedAccordion = ref(null)

    const updateModelValue = (e) => {
      if (props.isAccordion) {
        emit('update:modelValue', e.target.value)
      }
    }

    const openAccordion = () => {
      if (props.isAccordion) {
        if (checkedAccordion.value.checked) {
          checkedAccordion.value.checked = false
          emit('update:modelValue', null)
        } else {
          checkedAccordion.value.click()
        }
      }
    }

    return {
      checkedAccordion,
      className,
      updateModelValue,
      openAccordion
    }
  }
}
</script>
