<template>
  <div>
    <div>
      <h2 class="title-2 wg-support-center_title">I. For registered and logged in users.</h2>
      <p class="wg-support-center_text">This video guide shows how to contact VictoriyaClub Support Centre if you are a registered user and logged in:</p>
      <iframe style="width: 100%" height="315" src="https://www.youtube.com/embed/WvlOMntUBlc" frameborder="0" allowfullscreen></iframe>
      <h2 class="title-2 wg-support-center_title">If you are a registered user please make the following steps:</h2>
      <p class="wg-support-center_text">
        1. Log in to the website. <br>
        2. Press ‘Support’ in the main menu on the top of the page. <br>
        3. Press ‘New ticket’ button. <br>
        4. Name your issue in the ‘Subject’ field. <br>
        5. Describe your issue and give us as much details as possible in the ‘Message’ field. <br>
        6. Attach a file if necessary by pressing ‘Attach file’ button. <br>
        7. Press ‘Send’ button to send the message. <br>
        After sending your ticket on the Support page you can reply to Support Centre or add some extra information to your existing ticket.
      </p>
      <h2 class="title-2 wg-support-center_title">II. For not registered or not logged in users.</h2>
      <p class="wg-support-center_text">This video guide shows how to contact VictoriyaClub Support Centre if you are not a registered user or logged in:</p>
      <iframe style="width: 100%" height="315" src="https://www.youtube.com/embed/KKyFDajr90Q" frameborder="0" allowfullscreen></iframe>
      <p class="wg-support-center_text-footer mt-3 mb-3">If you are not a registered user or not logged in, send email to <a href="mailto:support@VictoriyaClub.com">support@VictoriyaClub.com</a>, giving us as much details of your issue as possible.</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>
