<template>
  <div class="wg-list-become-accordion">
    <WGAccordion
      :title="title"
      :isAccordion="isAccordion"
      v-model="activeAccordion"
      accordionValue="1"
    >
      <template #content>
        <WGList
          :list="list"
        >
          <template #item="{item}">
            <WGLink v-if="item.linkHref" :linkHref="item.linkHref">{{item.linkTitle}}</WGLink>
            <p v-else>{{item}}</p>
          </template>
        </WGList>
      </template>
    </WGAccordion>
    <WGAccordion
      title="Next title"
      :isAccordion="isAccordion"
      v-model="activeAccordion"
      accordionValue="2"
    >
      <template #content>
        <WGList
          :list="list"
        >
          <template #item="{item}">
            <WGLink v-if="item.linkHref" :linkHref="item.linkHref">{{item.linkTitle}}</WGLink>
            <p v-else>{{item}}</p>
          </template>
        </WGList>
      </template>
    </WGAccordion>
  </div>
</template>

<script>
import WGList from '@/components/UI/WGLists/WGList'
import WGLink from '@/components/UI/WGLinks/WGLink'
import WGAccordion from '@/components/UI/WGAccordions/WGAccordion'
import { ref } from 'vue'

export default {
  name: 'WGListBecomeAccordion',
  components: {
    WGList,
    WGLink,
    WGAccordion
  },
  props: {
    title: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    },
    isItemLink: {
      type: Boolean,
      default: false
    },
    isAccordion: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const activeAccordion = ref(null)
    return {
      activeAccordion
    }
  }
}
</script>
