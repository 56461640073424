<template>
  <div class="wg-read-more" v-if="$props.text || $slots.slotText" :class="{'is-less': isReadMore, 'is-more': !isReadMore}">

    <div
      class="wg-read-more-text"
      role="paragraph"
    >
      <div ref="paragraph">
        <slot name="slotText">
          {{ text }}
        </slot>
      </div>
    </div>

    <WGLink type="button" isFontSizeInherit variant="primary" @click="onShowMore" v-if="isShowBtn">
      <template v-if="isReadMore">
        {{ $t('global.showMore') }}
        {{ $t('global.testimonials').toLowerCase() }}
      </template>
      <template v-else>
        {{ $t('global.showLess') }}
      </template>
    </WGLink>

  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'

import WGLink from '@/components/UI/WGLinks/WGLink.vue'
export default {
  name: 'WGReadMore',
  components: {
    WGLink
  },
  emits: ['onClickShowMore'],
  props: {
    text: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    const paragraph = ref(null)

    const sizeScrollHeight = ref(66)

    const isReadMore = ref(true)
    const isShowBtn = ref(false)

    const onShowMore = () => {
      isReadMore.value = !isReadMore.value

      emit('onClickShowMore')
    }

    const isShowMore = () => {
      if (paragraph.value.scrollHeight >= sizeScrollHeight.value) {
        isShowBtn.value = true
      }
    }

    onMounted(() => {
      nextTick(() => {
        isShowMore()
      })
    })

    return {
      isReadMore,
      isShowBtn,
      paragraph,
      sizeScrollHeight,
      onShowMore
    }
  }
}
</script>
