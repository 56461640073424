
import '@/assets/styles/victoria/main.scss'

import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import { LIST_ICON_TYPE } from './data/listIconType'

// More on how to set up stories at: https://storybook.js.org/docs/vue/writing-stories/introduction
export default {
  title: 'Example/WGButton',
  component: WGButton,
  tags: ['autodocs'],
  argTypes: {
    size: {
      control: {
        type: 'select'
      },
      options: ['sm', 'md', 'lg']
    },
    variant: {
      control: {
        type: 'select'
      },
      options: ['success', 'warning']
    },
    tagDirection: {
      control: {
        type: 'select'
      },
      options: ['left', 'right']
    },
    iconDirection: {
      control: {
        type: 'select'
      },
      options: ['left', 'right']
    },
    icon: {
      control: {
        type: 'select'
      },
      options: LIST_ICON_TYPE
    }
  }
}

// More on writing stories with args: https://storybook.js.org/docs/vue/writing-stories/args
export const Success = {
  args: {
    variant: 'success',
    default: 'Button'
  }
}

export const Warning = {
  args: {
    variant: 'warning',
    default: 'Button'
  }
}

export const Icon = {
  args: {
    icon: 'wg-ffi-filter-remove',
    default: 'Button'
  }
}

export const Tag = {
  args: {
    tag: 'Tag',
    variant: 'warning',
    default: 'Button'
  }
}

export const Large = {
  args: {
    size: 'lg',
    default: 'Button'
  }
}

export const Middle = {
  args: {
    size: 'md',
    default: 'Button'
  }
}

export const Small = {
  args: {
    size: 'sm',
    default: 'Button'
  }
}
