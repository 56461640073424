<template>
  <div class="wg-offer-prompt">
    <div class="wg-offer-prompt_wrapper">
      <p class="wg-offer-prompt_question">
        {{ question }}
      </p>
      <span class="wg-offer-prompt_price">
        {{ price }}
      </span>
    </div>
    <WGButton
      variant="warning"
      size="md"
      class="wg-offer-prompt_case-button text-uppercase"
      @click="$emit('onChange')"
    >
      <template #default>
        <div class="wg-offer-prompt_button">
          <img v-if="icon" :src="icon" :alt="title">
          <p>{{ title }}</p>
        </div>
      </template>
    </WGButton>
  </div>
</template>

<script>
import WGButton from '@/components/UI/WGButtons/WGButton'
export default {
  components: { WGButton },
  props: {
    question: String,
    price: String,
    title: String,
    icon: String
  },
  emits: ['onChange'],
  setup () {
    return {}
  }
}
</script>

<style scoped>

</style>
