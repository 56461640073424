<script>
import { defineComponent } from 'vue'
import WGField from '@/components/UI/WGForms/WGField.vue'

const regex = /^[\p{L}\s-]+$/u
export default defineComponent({
  name: 'WGFieldCountry',
  components: {
    WGField
  },
  emits: ['update:modelValue', 'update:invalidText'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    invalidText: {
      type: [String, Number]
    }

  },
  data () {
    return {
      isInvalid: false,
      defaultInvalidText: ''
    }
  },
  methods: {
    updateModelValue (e) {
      this.$emit('update:modelValue', e)
      this.isInvalid = !regex.test(e)
      this.defaultInvalidText = this.isInvalid ? this.$t('invalid.field') : ''
      this.$emit('update:invalidText', this.defaultInvalidText)
    }
  }
})
</script>

<template>
  <WGField
    type="text"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :isInvalid ="isInvalid"
    :invalidText="$props.invalidText || defaultInvalidText"
  />
</template>
