import { AuthForm } from '@/core/api/api-AuthForm'
const passGen = (minLength = 10, maxLength = 0) => {
  if (maxLength < minLength) {
    maxLength += minLength
  }
  const s = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; let i; let res = ''
  for (i = 0; i < Math.floor(Math.random() * (maxLength - minLength)) + minLength; i++) {
    const index = Math.floor(Math.random() * s.length)
    res += s.slice(index, index + 1)
  }
  return res
}
export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    apiRegistration ({ commit, getters, dispatch }, { name, email, signAgree = 'on' }) {
      const password = passGen()
      const api = new AuthForm()
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('pass', password)
      formData.append('pass2', password)
      formData.append('sign_agree', signAgree)
      return api.registration(formData)
        .then(response => {
          window.location.replace(response.location)
          dispatch('toast', { text: 'Successful registration', type: 'SUCCESS' })
        }).catch(error => {
          dispatch('toast', { text: error.data.error ? error.data.error : error.data.message, type: 'ERROR' })
        })
    },
    apiRegistrationGirl ({ commit, getters, dispatch }, { name, email }) {
      const api = new AuthForm()
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)

      api.registrationGirl(formData).then(resp => {
        if (resp.status === 'Success!') {
          dispatch('toast', { text: 'Request sent. Check your email', type: 'SUCCESS' })
        }
      }).catch((err) => {
        if (err.data.errors && err.data.message) {
          dispatch('toast', { text: err.data.message, type: 'ERROR' })
        }
      })
    },
    apiRestorePasswordByEmail ({ commit }, email) {
      const api = new AuthForm()
      const formData = new FormData()
      formData.append('email', email)

      return api.restorePasswordByEmail(formData).then(response => {
        return response
      }).catch(error => {
        return error
      })
    },
    apiChangePassword ({ commit, dispatch }, payload) {
      const api = new AuthForm()
      const formData = new FormData()
      formData.append('pass', payload.pass)
      formData.append('pass2', payload.pass2)
      formData.append('email', payload.email)
      formData.append('key', payload.key)

      return api.changePassword(formData).then(response => {
        const currentUrl = window.location.href
        const urlObject = new URL(currentUrl)
        urlObject.search = ''
        history.replaceState({}, document.title, urlObject.href)

        return response
      }).catch(error => {
        return error
      })
    }
  }
}
