<template>
  <div class="wg-tooltip">
    <span class="wg-tooltip-text">{{ tooltipText }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WGTooltip',
  props: {
    tooltipText: {
      type: String,
      default: null
    }
  }
}
</script>
