<template>
  <Swiper navigation :modules="modules" v-if="swiperList && swiperList.length">

    <SwiperSlide v-for="(item, index) in swiperList" :key="index">
      <slot
        name="item"
        :item="item"
        :index="index"
      >
        {{ item }}
      </slot>
    </SwiperSlide>
  </Swiper>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper'

export default {
  name: 'WGSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    swiperList: {
      type: Array,
      default: null
    }
  },
  setup () {
    return {
      modules: [Autoplay, Pagination, Navigation]
    }
  }
}
</script>
