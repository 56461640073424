import '@/assets/styles/victoria/main.scss'

import WGTooltip from '@/components/UI/WGTooltip/WGTooltip.vue'

export default {
  title: 'Example/WGTooltip',
  component: WGTooltip,
  tags: ['autodocs']
}

export const Docs = {
  args: {
    tooltipText: 'tooltipText',
    default: 'Hover over me'
  }
}
