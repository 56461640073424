<template>

  <div class="wg-love-story-banner-wrp">

    <WGLogo
      :fullLogoSrc="fullLogoSrc"
    />

    <div class="wg-love-story-images">

      <div class="wg-love-story-img-wrp">
        <img class="wg-love-story-img" src="@/assets/images/bannerLoveStory/image-1.png" alt="image" />
      </div>
      <div class="wg-love-story-img-wrp">
        <img class="wg-love-story-img" src="@/assets/images/bannerLoveStory/image-2.png" alt="image" />
      </div>
      <div class="wg-love-story-img-wrp">
        <img class="wg-love-story-img" src="@/assets/images/bannerLoveStory/image-3.png" alt="image" />
      </div>

    </div>

    <div class="wg-love-story-text-wrp">
      <div class="wg-main-stroke-sup">{{$t('bannerLoveStory.the')}}</div>
      <div class="wg-main-cursive-subtext">
        <img class="wg-love-story-heart-icon-sm" src="@/assets/images/bannerLoveStory/heart-sm.svg" alt="image" />
        <img class="wg-love-story-heart-icon-lg" src="@/assets/images/bannerLoveStory/heart-lg.svg" alt="image" />
        {{ $t('bannerLoveStory.subtext') }}
      </div>
      <p class="wg-main-text">
        {{ $t('bannerLoveStory.mainText') }}
      </p>
    </div>

    <div class="wg-love-story-text-bottom">
      <img class="wg-love-story-text-bottom-img" src="@/assets/images/bannerLoveStory/arrow.svg" alt="image" />
      {{ $t('bannerLoveStory.textBottom') }}
    </div>

  </div>

</template>

<script>
import WGLogo from '@/components/UI/WGLogos/WGLogo.vue'
import { ref } from 'vue'

export default {
  name: 'WGLoveStoryBanner',
  components: {
    WGLogo
  },
  setup () {
    const fullLogoSrc = ref(require('@/assets/images/logo-full.svg'))

    return {
      fullLogoSrc
    }
  }
}
</script>
